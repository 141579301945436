import React from 'react';

// material components
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import ErrorIcon from '@material-ui/icons/Error';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import CheckIcon from '@mui/icons-material/Check';
import {Button, TableContainer, TextField, Tooltip, IconButton, Typography, TableBody, TableHead, Table} from "@material-ui/core";

// constants
import {
    EXPECTED_EOD,
    EXPECTED_EOD_HEADER,
    SHIPPING_TEMPLATE,
    SKU,
    STOCK,
    STOCK_HEADER,
    UNSHIPPED,
    UNSHIPPED_HEADER
} from "./constants";

// styling
import "./AlertsCard.css"
import FileChip from "../global/FileChip";
import PropTypes from "prop-types";
import ShowChartIcon from '@material-ui/icons/ShowChart';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import SnoozeIcon from '@material-ui/icons/Snooze';
import WarningIcon from '@material-ui/icons/Warning';
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {ExpandLessRounded, ExpandMoreRounded, LinkOff} from "@material-ui/icons";
import InfoIcon from '@material-ui/icons/Info';


class AlertsCard extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            stock: '',
            unshipped: '',
            expectedEOD: '',
            unshippedCount: '',
            snoozeDuration: 1,
            showSnooze: false
        }
    }

    componentDidMount() {
        // extract info
        let split = []
        if(this.props.alertEntry.alertType !== 'Shipping Template Changed'){
            split = this.props.alertEntry.alertMessage.split(',')
            let values = []
            split.forEach((item) => {
                values.push(item.split(':')[1])
            })
            this.setState({
                stock: values[0],
                unshipped: values[1],
                expectedEOD: values[2],
                unshippedCount: values[3]
            })
        }

    }

    makeTextRed = () => {
        if (this.props.alertEntry.alertType === "Out of Stock" || this.props.alertEntry.alertType === "Insufficient Stock") {
            return {color: "red"};
        }
        return null;
    }

    renderAlertCardIcon = () => {
        if (this.props.alertEntry.alertType === "Out of Stock") {
            return <div className="important-alert-icon"><ErrorIcon style={{fontSize: 40}}/></div>
        }
        if(this.props.alertEntry.alertType === "Shipping Template Changed"){
            return <div className="insufficient-icon"><AutorenewIcon style={{fontSize: 40}}/></div>
        }
        if (this.props.alertEntry.alertType === 'Unshipped') {
            return <div className="important-alert-icon"><LocalShippingIcon style={{fontSize: 40}}/></div>
        }
        if (this.props.alertEntry.alertType === 'Insufficient Stock') {
            return <div className="insufficient-icon"><WarningIcon style={{fontSize: 40}}/></div>
        }
        if (this.props.alertEntry.alertType === 'Unmapped Skus') {
            return <div className="insufficient-icon"><LinkOff style={{fontSize: 40}}/></div>
        }
        return <div className="lowInv-icon"><ArrowDownwardIcon style={{fontSize: 40}}/></div>
    }

    showSnooze = () => {
        this.setState({
                showSnooze: true
            }
        )
    }

    hideSnooze = () => {
        this.setState({
                showSnooze: false
            }
        )
    }

    handleSnoozeDayChange = (e) => {
        this.setState({
            snoozeDuration: e.target.value
        })
    }

    handleReslove = (alertId, index) => {
        this.props.resolveAlert(alertId, index)
    }

    renderAlertsContent = (expandingAlert) => {
        if (this.props.alertEntry.alertType === 'Unshipped') {
            return <div className="">
                <div className='unshipped-cell'>{this.state.unshippedCount}</div>
            </div>
        }
        if (this.props.alertEntry.alertType === 'Out of Stock' && this.props.alertEntry.byBrandName
            && this.props.alertEntry.byBrandName.length !== 0) {
            return <TableContainer className={expandingAlert ? "expanded-table-container": "table-container"}>
                <Table size={"small"} stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell align={'center'} className='cellPadding'>
                                <p className='alertCardCellItems'>

                                </p>
                            </TableCell>
                            <TableCell align={'center'} className='cellPadding'>
                                <p className='alertCardCellItems'>
                                    {STOCK}
                                </p>
                            </TableCell>
                            <TableCell align={'center'} className='cellPadding'>
                                <p className="alertCardCellItems">
                                    {UNSHIPPED}
                                </p>
                            </TableCell>
                            <TableCell align={'center'} className='cellPadding'>
                                <p className="alertCardCellItems">
                                    {EXPECTED_EOD}
                                </p>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.props.alertEntry.byBrandName.map((alert, index) => (
                                <TableRow hover key={index}>
                                    <TableCell align={'center'} className='cellPadding'>
                                        <p className="alertCardCellItems">
                                            {alert.shortcode}
                                        </p>
                                    </TableCell>
                                    <TableCell align={'center'} className='cellPadding'>
                                        <p className="alertCardCellItems">
                                            {alert.stock}
                                        </p>
                                    </TableCell>
                                    <TableCell align={'center'} className='cellPadding'>
                                        <p className="alertCardCellItems">
                                            {alert.unshipped}
                                        </p>
                                    </TableCell>
                                    <TableCell align={'center'}>
                                        <p className="alertCardCellItems">
                                            {alert.expectedEOD}
                                        </p>
                                    </TableCell>
                                </TableRow>
                            )
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        }
        if (this.props.alertEntry.alertType === 'Unmapped Skus') {
            return <TableContainer className={expandingAlert ? "expanded-table-container": "table-container"}>
                <Table size={"small"}>
                    <TableBody>
                        {this.props.alertEntry.unmappedSkus.slice(1).map((sku, index) => (
                                <TableRow hover key={index}>
                                    <TableCell align={'center'} className='cellPadding'>
                                        <p className="alertCardCellItems">
                                            {sku}
                                        </p>
                                    </TableCell>
                                </TableRow>
                            )
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        }
        if (this.props.alertEntry.alertType === 'Low Inventory') {
            return <div className="grid-layout">
                <div className='grid-header' style={this.makeTextRed()}>{STOCK_HEADER}</div>
                <div className='grid-cell' style={this.makeTextRed()}>{this.state.stock}</div>
                <div className='grid-header' style={this.makeTextRed()}>Threshold</div>
                <div className='grid-cell' style={this.makeTextRed()}>{this.props.alertEntry.threshold}</div>
                <div className='grid-header' style={this.makeTextRed()}>{EXPECTED_EOD_HEADER}</div>
                <div className='grid-cell' style={this.makeTextRed()}>{this.state.expectedEOD}</div>
            </div>
        }
        if (this.props.alertEntry.alertType === 'Shipping Template Changed') {
            return <TableContainer className={expandingAlert ? "expanded-table-container": "table-container"}>
                <Table size={"small"} stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell align={'left'} className='cellPadding'>
                                <p className='alertCardCellItems'>
                                    {SKU}
                                </p>
                            </TableCell>
                            <TableCell align={'left'} className='cellPadding'>
                                <p className="alertCardCellItems">
                                    {SHIPPING_TEMPLATE}
                                </p>
                            </TableCell>
                            <TableCell align={'left'} className='cellPadding'>
                                <p className='alertCardCellItems'>

                                </p>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.props.alertEntry.alerts.map((alert, alertIndex) => (
                            alert.shippingTemplateChanged.sort((a, b) => new Date(a.shippingTemplateChangeTime) - new Date(b.shippingTemplateChangeTime))
                                .map((row, rowIndex) => (
                                <TableRow hover key={`${alert.alertId}-${rowIndex}`}>
                                    <TableCell align="left" className="cellPadding">
                                        <p className="alertCardCellItems">
                                            {alert.identifier.sku}
                                        </p>
                                    </TableCell>
                                    <TableCell align="left" className="cellPadding">
                                        <p className="alertCardCellItems">
                                            {row.shippingTemplate}
                                            <Tooltip title= {row.shippingTemplateChangeTime}>
                                                <InfoIcon  style={{ marginLeft:'5px', color: 'gray' }} />
                                            </Tooltip>
                                        </p>
                                    </TableCell>
                                    <TableCell align="left" className="cellPadding">
                                        <IconButton style={{marginRight: '25%', paddingLeft: '0%', paddingRight: '0%', color: 'lightgreen'}}
                                                    onClick={()=>this.handleReslove(alert.alertId, rowIndex)}>
                                            <CheckIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        }
        return <div className="grid-layout">
            <div className='grid-header' style={this.makeTextRed()}>{STOCK_HEADER}</div>
            <div className='grid-cell' style={this.makeTextRed()}>{this.state.stock}</div>
            <div className='grid-header' style={this.makeTextRed()}>{UNSHIPPED_HEADER}</div>
            <div className='grid-cell' style={this.makeTextRed()}>{this.state.unshipped}</div>
            <div className='grid-header' style={this.makeTextRed()}>{EXPECTED_EOD_HEADER}</div>
            <div className='grid-cell' style={this.makeTextRed()}>{this.state.expectedEOD}</div>
        </div>
    }

    snoozeRow = () => {
        return <div className="snooze-row">
            <TextField
                type="number"
                variant="outlined"
                name="quantity"
                value={this.state.snoozeDuration}
                onChange={(e) => {
                    if (e.target.value > 0) {
                        this.handleSnoozeDayChange(e)
                    }
                }
                }
                size="small"
                className="snooze-card"
                inputProps={{
                    pattern: "[0-9]*",
                }}
                label="Day(s)"
            />
            <Button
                className="snooze-cancel-button"
                variant="outline-dark"
                onClick={() => this.hideSnooze()}>Cancel</Button>
            <Button
                className="snooze-ok-button"
                onClick={() => {
                    this.props.snoozeAlert({
                        alertId: this.props.alertEntry.alertId,
                        daysToExpire: this.state.snoozeDuration
                    })
                    this.hideSnooze()
                    this.props.removeSnoozedAlert()
                }}>
                Ok
            </Button>
        </div>
    }

    getAlertTitle = () => {
        if (this.props.alertEntry.alertType === 'Unshipped') {
            return `${this.props.alertEntry.alertType} - Due Today`;
        } else if (this.props.alertEntry.alertType === 'Unmapped Skus') {
            return `${this.props.alertEntry.alertType}`;
        }
        else if (this.props.alertEntry.alertType === 'Shipping Template Changed') {
            return `${this.props.alertEntry.alertType}`;
        }
        return `${this.props.alertEntry.alertType} - ${this.props.alertEntry.identifier.shortcode}`
    }

    getLocactionSubtite = () => {
        if (this.props.alertEntry.alertType === 'Unmapped Skus') {
            return `(${this.props.alertEntry.unmappedSkus[0]})`;
        }
        else if (this.props.alertEntry.alertType === 'Shipping Template Changed') {
            return `${this.props.alertEntry.marketplace}`;
        }
        return `(${this.props.alertEntry.fromLocation})`
    }

    render() {
        return (<Card className="alerts-card">
                <div className="card-header alerts-card-header">
                    <div className="alert-title">
                        {this.renderAlertCardIcon()}
                        <div className="title-no-icon">
                            {this.state.showSnooze ? this.snoozeRow() :

                                <div>
                                    <div className="alert-title-text">
                                        <h6 className="alert-main-title">
                                            {this.getAlertTitle()}
                                        </h6>
                                    </div>
                                    <div>
                                        <Typography variant="h6">
                                            <div className="snooze-icon-row">
                                                <div className="location-cell">
                                                    {this.getLocactionSubtite()}
                                                </div>
                                            </div>
                                        </Typography>
                                    </div>
                                </div>}
                        </div>
                        {this.state.showSnooze ? null :
                            <div>{
                                this.props.alertEntry.alertType !== 'Unshipped' && this.props.alertEntry.alertType !== 'Shipping Template Changed' ?
                                    <SnoozeIcon className="snooze-icon"
                                                onClick={() => {
                                                    if (this.state.showSnooze) {
                                                        return this.hideSnooze();
                                                    }
                                                    return this.showSnooze();
                                                }}>
                                    </SnoozeIcon> : null
                            }
                            </div>
                        }

                        {this.props.expandingAlert ?
                            <ExpandLessRounded
                                style={{marginLeft: "10px"}}
                                className="snooze-icon"
                                onClick={() => {
                                    this.props.viewSpecificAlert(this.props.alertEntry?.alertId)}}>
                            </ExpandLessRounded>
                            :
                            <ExpandMoreRounded
                                style={{marginLeft: "10px"}}
                                className="snooze-icon"
                                onClick={() => {
                                    this.props.viewSpecificAlert(this.props.alertEntry?.alertId)}}>
                            </ExpandMoreRounded>
                        }
                    </div>
                </div>

            {!this.props.expandingAlert &&
                <CardContent>
                    {this.renderAlertsContent(false)}
                </CardContent>
            }
            {this.props.expandingAlert && this.props.expandingAlertId == this.props.alertEntry?.alertId &&
                <CardContent>
                    {this.renderAlertsContent(true)}
                </CardContent>
            }
            </Card>
        );
    }

}

AlertsCard.propTypes = {
    alertEntry: PropTypes.object,
};

AlertsCard.defaultProps = {
    alertEntry: {}
}

export default AlertsCard;