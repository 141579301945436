import {
    put,
    call,
    takeLatest,
    delay, fork, join
} from 'redux-saga/effects';
import axios from 'axios';

// constants
import {
    CREATE_CARRIER,
    LIST_LINKED_ADDRESSES,
    LIST_CARRIERS,
    LIST_SHIPPING_TEMPLATES,
    SET_DEFAULT_CARRIER,
    LIST_ADDRESSES,
    EDIT_CARRIER,
    DELETE_CARRIER,
    LIST_PRODUCTS,
    CREATE_PRODUCT,
    UPDATE_PRODUCT,
    CREATE_SUPPLIER,
    LIST_PRINTERS,
    UPDATE_PRINTERS,
    REFRESH_PRINTERS,
    LIST_TAGS,
    EDIT_TAGS,
    GET_UUID,
    GET_UUIDS,
    UPDATE_SUPPLIER,
    LIST_SUPPLIERS,
    LIST_LOCATIONS,
    CREATE_LOCATION,
    UPDATE_LOCATION,
    UPLOAD_LOGO,
    FETCH_LOGO,
    DELETE_LOGO,
    FETCH_SUPPLIER_TEMPLATES,
    FETCH_SUPPLIERS_WITHOUT_TEMPLATE,
    CREATE_SAVE_INVOICE_TEMPLATE,
    GET_PDF_TEMPLATE,
    DELETE_INVOICE_TEMPLATE,
    CREATE_PACKAGE,
    UPDATE_PACKAGE,
    LIST_PACKAGES,
    DELETE_PRODUCT,
    FETCH_LISTINGS,
    FETCH_PRICING_LISTINGS,
    Add_RELATED_PRODUCT,
    EDIT_RELATED_PRODUCT,
    UPDATE_RELATED_PRODUCTS,
    GET_SALES_RANK,
    GET_BUY_BOX_PRICES,
    GET_RELATED_PRODUCTS,
    FETCH_AMAZON_LISTINGS_PRICE,
    UPDATE_BARCODES,
    UPDATE_LISTING,
    UPDATE_PRICING_LISTING,
    UPDATE_REPRICING,
    GET_TOP_3_BUY_BOX_OFFERS,
    GET_UNMAPPED_PRODUCTS,
    FETCH_AMAZON_SKUS,
    DELETE_BARCODE,
    SCRUB_DATA,
    SAVE_PRODUCT_CATEGORY_CHANGES,
    GET_ALL_CATEGORY,
    REMOVE_PRODUCT_CATEGORY,
    ADDRESS_VALIDATION_CHANGE,
    LIST_EMAIL_TEMPLATES,
    EMAIL_TEMPLATE_UPDATE,
    CREATE_NEW_EMAIL_TEMPLATE,
    DELETE_NEW_EMAIL_TEMPLATE,
    GET_EMAIL_TEMPLATE_TYPES,
    PO_EMAIL_SEND,
    CREATE_MARKETPLACE,
    LIST_MARKETPLACES,
    UPDATE_INVENTORY_ALLOCATION,
    EDIT_MARKETPLACE,
    DELETE_MARKETPLACE,
    VALIDATE_MARKETPLACE,
    GET_COUNTRY_CODES,
    GET_AMAZON_FEEDS,
    RESTART_FEED_FETCH,
    LIST_AVAILABLE_LOCATIONS,
    EMAIL_TEMPLATE_TYPE_UPDATE,
    CREATE_NEW_EMAIL_TEMPLATE_TYPE,
    DELETE_NEW_EMAIL_TEMPLATE_TYPE,
    FETCH_SENDGRID_TEMPLATES,
    FETCH_PURCHASE_ORDER_EMAIL_TEMPLATE,
    SEND_EMAIL_PURCHASE_ORDER,
    PROCESS_SUPPLIER_INVOICES,
    ARCHIVE_LABELS,
    CHANGE_PRIMARY,
    ADD_NEW_CHILD,
    DELETE_CHILD,
    GET_PRINT_CONFIG,
    GET_INACTIVE_PRODUCTS,
    GET_DELETED_PRODUCTS,
    DELETE_LISTING,
    GET_ACTIVE_PRODUCTS,
    FETCH_PRODUCTS,
    FETCH_BARCODES,
    LIST_CARRIER_NAMES,
    LIST_ALL_BANK_ACCOUNTS,
    DELETE_BANK_ACCOUNT,
    SAVE_BANK_ACCOUNT,
    IMPORT_HISTORICAL_DATA,
    LIST_SHEETS,
    PROCESS_CARRIER_INVOICE,
    MANUAL_EXECUTE_AUTOMATION_SETTINGS,
    SET_SKU_LOCATION,
    PREVIEW_BANK_FILE,
    INVOICE_EMAIL_SEND,
    CUSTOMER_PO_EMAIL_SEND,
    VALIDATE_FEDEX_REST_CREDENTIALS,
    SET_SHIPPING_TEMPLATE,
    SET_HANDLING_TIME,
} from "../../constants/settings";
import {
    CARRIERS,
    LOCATIONS,
    PRODUCTS,
    SUPPLIERS,
    PRINTERS,
    TAGS,
    UUID,
    PACKAGES,
    LISTINGS,
    MARKETPLACES,
    DATA_SCRUBBING,
    PRODUCT_CATEGORY,
    AMAZON,
    EMAIL_TEMPLATES,
    SEND_GRID,
    ORDERS,
    BANK_ACCOUNTS,
    HISTORICAL, FULL_TRUCKLOAD_CARRIERS, RETURNS,
} from "../../../components/global/Server/endpoints";

// secondary actions
import {
    storeCarriers,
    storeAddresses,
    updateCarriers,
    updateAddresses,
    updateCarriersEdit,
    storeTags,
    storeLinkedAddresses,
    updateCarriersDelete,
    storeProducts,
    storeSuppliers,
    storeLocations,
    storePrinters,
    storeLogo,
    storeSupplierTemplates,
    storeSuppliersWithoutTemplate,
    storePDFTemplate,
    storePackages,
    storeUpdatedPackage,
    storeUpdatedProduct,
    storeListings,
    storeUpdatedListings,
    storeUnmappedProducts,
    getUnmappedProducts,
    fetchListings,
    updateMarketplaces,
    storeMarketplaces,
    updateMarketplacesEdit,
    updateMarketplacesDelete,
    storeAllCategory,
    getAllCategory,
    storeCountryCodes,
    storeAmzFeeds,
    storeEmailTemplates,
    createNewEmailTemplate,
    getEmailTemplateTypes,
    storeEmailTemplateTypes,
    storeAvailableLocations,
    storePurchaseOrderEmailTemplate,
    storeArchivedLabels,
    storeArchivedLabelsReturned,
    storePrintConfig,
    storeInactiveProducts,
    storeDeletedProducts,
    getInactiveProducts,
    getDeletedProducts,
    storeActiveProducts,
    storeBarcodes,
    storeUpdatedBarcode,
    storeRemovedBarcode,
    storeDeleteListing,
    storeCarrierNames,
    storeAllBankAccounts,
    storeHistoricalData,
    storeShiprushFileNames,
    storeResponseMessage,
    storePricingListings,
    storeUpdatedPricingListings,
    fetchPricingListings, storeSalesrank, getBuyBoxPrices, storeBuyBoxPrices, getSalesRank, storeRelatedProducts,
    storeCarrierOperationResponse,
    storeInventoryAllocation,
    storeShippingTemplates, storeInventoryAutoAllocation,
    storeUpdatedBank,
    storeUpdatedBankWithPreview, getTop3BuyBoxOffers, storeTop3BuyBoxOffers
} from "../../actions/settings";

import {
    setLoadingStatus,
    showErrorMessage, hideError, requestStatus, setPricingLoadingStatus,
    clearStatus
} from "../../actions/global";
import { SERVER_FAILED_ERROR_MESSAGE } from '../../../pages/Dashboard/DashboardFinal/constants';
import {HTTP_SUCCESS} from "../../constants/global";
import {CLAIMS, FTL, SHIPRUSH} from "../../../components/settings/ManageHistoricalData/constants";
function* postRequest(endpoint, data) {
    return yield axios.post(endpoint, data, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })
        .then(response => response);
}

const getRequest = (endpoint) => {
    return axios.get(endpoint, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })
        .then(response => response);
}

// carriers sagas
function* createCarrier(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(CARRIERS.CREATE_CARRIER, action.carrier))
        if (response.data.success) {
            yield put(updateCarriers(response.data.carrier))
            yield put(storeCarrierOperationResponse(response.data.message))
            yield action.reset()
        } else {
            yield put(showErrorMessage(response.data.message))
        }
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* manualExecuteCarrierAutoSetting(action){
    yield put(hideError())
    try {
        let response
        if (!action.data.isFTLCarrier)
            response = yield call(() => postRequest(CARRIERS.MANUAL_EXECUTE_AUTOMATION_SETTINGS, action.data))
        else
            response = yield call(() => postRequest(FULL_TRUCKLOAD_CARRIERS.MANUAL_EXECUTE_AUTOMATION_SETTINGS, action.data))
        if (response?.data?.message) {
            // if failed to execute automation settings
            if (!response.data?.success) {
                yield put(showErrorMessage(response?.data?.message))
            }
            // if success to execute automation settings, add response message for carrier settings
            if (response.data?.success && !action.data.isFTLCarrier) {
                yield put(storeCarrierOperationResponse(response.data.message))
            }
        }
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* listSheets(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(HISTORICAL.LIST_SHEETS, {
            folderInfo: action.folderInfo
        }))
        if (response.data.success) {
            yield put(storeShiprushFileNames(response.data.spreadsheets))
        } else {
            yield put(showErrorMessage(response.data.message))
        }
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

// function* fetchShiprushImportStatus(action, importStartTime) {
//     let response = yield call(() => postRequest(HISTORICAL.FETCH_SHIPRUSH_IMPORT_STATUS, {
//         selectedSheets: action.selectedFiles,
//         importStartTime: importStartTime
//     }));
//     return response.data;  // Ensure you return the data so it can be used in the calling function.
// }
//
// function* initiateImport(action) {
//     return yield call(() => postRequest(HISTORICAL.IMPORT_HISTORICAL_DATA, {
//         source: action.selectedFiles,
//         sourceType: SHIPRUSH
//     }));
// }

function* importHistoricalData(action) {
    yield put(hideError())
    try{
        let response
        switch (action.sourceType) {
            case SHIPRUSH:
                // // Fork the import task so it can run concurrently
                // let importStartTime = new Date().toISOString();
                // const importTask = yield fork(initiateImport, action);
                //
                // // Continuously check the status while the import task is running
                // let statusResponse
                // while (!statusResponse?.processLog?.includes("all files processed*")) {
                //     yield delay(120000);
                //     statusResponse = yield call(fetchShiprushImportStatus, action, importStartTime);
                //     yield put(storeHistoricalData(statusResponse.processLog,SHIPRUSH))
                //     if (statusResponse.processLog.includes("all files processed*")) {
                //         return
                //     }
                // }
                //
                // // Wait for the import task to finish (in case it hasn't already)
                // response = yield join(importTask);

                response = yield call(() => postRequest(HISTORICAL.IMPORT_HISTORICAL_DATA, {
                    source: action.selectedFiles,
                    sourceType: SHIPRUSH
                }));
                break;
            case CLAIMS:
                response = yield call(() => postRequest(HISTORICAL.IMPORT_HISTORICAL_DATA, {
                    source:action.selectedFiles.source,
                    folderName: action.selectedFiles.folderName,
                    sourceType: CLAIMS
                }));
                break;
            case FTL:
                response = yield call(() => postRequest(HISTORICAL.IMPORT_FTL_DATA, {
                    folderId: action.selectedFiles.folderId,
                    sourceType: FTL
                }));
                break;
        }
        if (response.data.success) {
            yield put(storeHistoricalData(response.data.message))
        } else {
            yield put(storeHistoricalData(response.data.message))
            yield put(showErrorMessage(response.data.message))
        }
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* listAddresses(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(CARRIERS.LIST_ADDRESS, action.id));
        if (response.data.success) {
            yield put(storeAddresses(response.data.addresses))
        } else {
            yield put(showErrorMessage(response.data.message))
        }
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* listCarriers(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(CARRIERS.LIST_CARRIERS, action.id));
        if (response.data.success) {
            yield put(storeCarriers(response.data.carriers))
        } else {
            yield put(showErrorMessage(response.data.message))
        }

    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* listShippingTemplates(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(MARKETPLACES.LIST_SHIPPING_TEMPLATES, action.id));
        if (response.data.success) {
            yield put(storeShippingTemplates(response.data.shippingTemplates))
        } else {
            yield put(showErrorMessage(response.data.message))
        }

    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* listCarrierNames() {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(CARRIERS.LIST_CARRIER_NAMES));
        if (response.data.success) {
            yield put(storeCarrierNames(response.data.carrierNames))
        } else {
            yield put(showErrorMessage(response.data.message))
        }

    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* listUniqueCarriers(action) {
    const response = yield call(() => postRequest(CARRIERS.LIST_UNIQUE_CARRIERS, action.id));
    if (response.status === 200) {
        yield put(storeCarriers(response.data))
    }
}

function* listLinkedAddresses(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(CARRIERS.LINKED_ADDRESSES, action.id))
        if (response.data.success) {
            yield put(storeLinkedAddresses(response.data.addresses))
        } else {
            yield put(showErrorMessage(response.data.message))
        }
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* editCarrier(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(CARRIERS.EDIT_CARRIER, action.carrier));
        if (response.data.success) {
            yield put(updateCarriersEdit(response.data.carrier))
            yield put(storeCarrierOperationResponse(response.data.message))
        } else {
            yield put(showErrorMessage(response.data.message))
        }
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* setDefaultCarrier(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(CARRIERS.SET_DEFAULT_CARRIER, {
            companyId: action.id,
            carrierId: action.carrierId
        }));
        if (response.data.success) {
            yield put(storeCarriers(response.data.carriers))
            yield put(storeCarrierOperationResponse(response.data.message))
        } else {
            yield put(showErrorMessage(response.data.message))
        }
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* AddressValidationChange(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(CARRIERS.SET_ADDRESS_VALIDATION, {
            companyId: action.id,
            carrierId: action.carrierId
        }))
        // if (response.data.success) {
        //     yield put (storeCarriers(response.data.carriers))
        // } else {
        //     yield put(showErrorMessage(response.data.message))
        // }
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* validateFedExRESTCredentials(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(CARRIERS.VALIDATE_FEDEX_REST_CREDENTIALS, action.data))
        if (response.data.success) {

        } else {
            yield put(showErrorMessage(response.data.message))
        }
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* deleteCarrier(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(CARRIERS.DELETE_CARRIER, action.toDelete));
        if (response.data.success) {
            yield put(updateCarriersDelete(action.toDelete.carrierId, response.data.deleted))
            yield put(storeCarrierOperationResponse(response.data.message))
        } else {
            yield put(showErrorMessage(response.data.message))
        }
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* listAllBankAccountsSaga(action) {
    yield put(hideError())
    try {
        const response = yield call (() => getRequest(BANK_ACCOUNTS.LIST_ALL_BANK_ACCOUNTS))
        if (response.data.success) {
            yield put(storeAllBankAccounts(response.data))
        } else {
            yield put(showErrorMessage(response.data.message))
        }
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* saveBankAccountSaga(action) {
    yield put(hideError())

    try {
        const response1 = yield call(() =>
            postRequest(BANK_ACCOUNTS.SAVE_BANK_ACCOUNT, action.data)
        )

        if (!response1.data.success) {
            yield put(showErrorMessage(response1.data.message))
            return
        }

        let bankAccountItem


        if (action.data.newFiles.length > 0) {
            const response2 = yield call(() =>
                postRequest(BANK_ACCOUNTS.ADD_FILES, {
                    files: action.data.newFiles,
                    accountNumber: action.data.accountNumber,
                })
            )

            if (!response2.data.success) {
                yield put(showErrorMessage(response2.data.message));
                return
            }

            bankAccountItem = response2.data.bankAccountItem
        }


        if (action.data.deletedFiles.length > 0) {
            const response3 = yield call(() =>
                postRequest(BANK_ACCOUNTS.DELETE_FILES, {
                    files: action.data.deletedFiles,
                    accountNumber: action.data.accountNumber,
                })
            )

            if (!response3.data.success) {
                yield put(showErrorMessage(response3.data.message))
                return
            }


            bankAccountItem = response3.data.bankAccountItem || bankAccountItem
        }

        if (bankAccountItem) {
            yield put(storeUpdatedBank(bankAccountItem))
        } else {
            yield put(storeAllBankAccounts(response1.data))
        }

        yield action.reset()

    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}


// function* saveBankAccountSaga(action) {
//     yield put(hideError());
//     try {
//         const response1 = yield call(() =>
//             postRequest(BANK_ACCOUNTS.SAVE_BANK_ACCOUNT, action.data)
//         );
//
//         if (!response1.data.success) {
//             yield put(showErrorMessage(response1.data.message));
//         } else {
//             let bankAccountItem;
//
//             // Handle new files
//             if (action.data.newFiles.length !== 0) {
//                 const response2 = yield call(() =>
//                     postRequest(BANK_ACCOUNTS.ADD_FILES, {
//                         files: action.data.newFiles,
//                         accountNumber: action.data.accountNumber,
//                     })
//                 );
//
//                 if (!response2.data.success) {
//                     yield put(showErrorMessage(response2.data.message));
//                 } else {
//                     bankAccountItem = response2.data.bankAccountItem;
//                 }
//             }
//
//             // Handle deleted files
//             if (action.data.deletedFiles.length !== 0) {
//                 const response3 = yield call(() =>
//                     postRequest(BANK_ACCOUNTS.DELETE_FILES, {
//                         files: action.data.deletedFiles,
//                         accountNumber: action.data.accountNumber,
//                     })
//                 );
//
//                 if (!response3.data.success) {
//                     yield put(showErrorMessage(response3.data.message));
//                 } else {
//                     bankAccountItem = response3.data.bankAccountItem || bankAccountItem;
//                 }
//             }
//
//             // If either new files or deleted files were processed, update the bank account
//             if (bankAccountItem) {
//                 yield put(storeUpdatedBank(bankAccountItem));
//             } else {
//                 // If no new or deleted files, store all bank accounts from response1
//                 yield put(storeAllBankAccounts(response1.data));
//             }
//
//             yield action.reset();
//         }
//     } catch (error) {
//         yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE));
//     }
// }


function* previewBankFileSaga(action) {
    yield call(clearStatus)
    yield put(hideError());
    try {
        const response = yield call(() => postRequest(BANK_ACCOUNTS.PREVIEW_FILE, action.previewRequest))
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === HTTP_SUCCESS,
        };

        if (!response.data.success) {
            yield put(showErrorMessage(response.data.message));
        } else {
            yield put(storeUpdatedBankWithPreview(response.data.bankAccountItem))
        }
        yield put(requestStatus(status))
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE));
    }
}

function* deleteBankAccountSaga(action) {
    yield put(hideError())
    try {
        const response = yield call (() => postRequest(BANK_ACCOUNTS.DELETE_BANK_ACCOUNT, action.data))
        if (response.data.success) {
            yield put(storeAllBankAccounts(response.data))
        } else {
            yield put(showErrorMessage(response.data.message))
        }
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}


// products sagas
function* listProductsSaga() {
    yield put(hideError())
    try {
        const response = yield call(() => getRequest(PRODUCTS.LIST_PRODUCTS))
        yield put(storeProducts(response.data))
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* createProductSaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(PRODUCTS.CREATE_PRODUCT, action.product))
        yield put(storeUpdatedProduct(response.data, false, true))
        action.reset()
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* updateProductSaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(PRODUCTS.UPDATE_PRODUCT, action.updatedProduct))
        if (response.data.success) {
            yield put(storeUpdatedProduct(response.data.product, false, false))
        } else {
            yield put(showErrorMessage(response.data.message))
        }
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* deleteProductSaga(action) {
    try {
        const response = yield call(() => postRequest(PRODUCTS.DELETE_PRODUCT, action.product))
        if (response.data.success) {
            yield put(storeUpdatedProduct(response.data.product, true, false))
        } else {
            yield put(showErrorMessage(response.data.message))
        }
    } catch (err) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

// suppliers sagas
function* createSupplierSaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(SUPPLIERS.CREATE_SUPPLIER, action.supplier))
        if (response.data.success) {
            yield put(storeSuppliers(response.data.suppliers))
            action.refresh()
        } else {
            yield put(showErrorMessage(response.data.message))
        }
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* updateSupplierSaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(SUPPLIERS.UPDATE_SUPPLIER, action.updatedSupplier))
        if (response.data.success) {
            yield put(storeSuppliers(response.data.suppliers))
        } else {
            yield put(showErrorMessage(response.data.message))
        }
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* processSupplierInvoicesSaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(SUPPLIERS.PROCESS_SUPPLIER_INVOICES, action.supplier))
        if (response.data.success) {

        } else {
            yield put(showErrorMessage(response.data.message))
        }
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* listSuppliersSaga() {
    yield put(hideError())
    try {
        const response = yield call(() => getRequest(SUPPLIERS.LIST_SUPPLIERS))
        yield put(storeSuppliers(response.data))
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

// locations sagas
function* listLocationsSaga() {
    yield put(hideError())
    try {
        const response = yield call(() => getRequest(LOCATIONS.LIST_LOCATIONS))
        yield put(storeLocations(response.data))
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}
function* listAvailableLocationsSaga() {
    yield put(hideError())
    try {
        const response = yield call(() => getRequest(LOCATIONS.LIST_AVAILABLE_LOCATIONS));
        const status = {
            status: response.status,
            statusText: response.statusText,
            success: response.status === HTTP_SUCCESS,
        };
        yield put(storeAvailableLocations(response.data));
        yield put(requestStatus(status));
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* createLocationSaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(LOCATIONS.CREATE_LOCATION, action.location))
        if (response.data.success) {
            yield put(storeLocations(response.data.locations))
            action.refresh()
        } else {
            yield put(showErrorMessage(response.data.message))
        }
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* updateLocationSaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(LOCATIONS.UPDATE_LOCATION, action.updatedLocation))
        if (response.data.success) {
            yield put(storeLocations(response.data.locations))
        } else {
            yield put(showErrorMessage(response.data.message))
        }

    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

// printers sagas
function* listPrintersSaga() {
    yield put(hideError())
    try {
        const response = yield call(() => getRequest(PRINTERS.LIST_PRINTERS))
        yield put(storePrinters(response.data))
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* refreshPrintersSaga(action) {
    yield put(hideError())
    yield put(setLoadingStatus(true))
    try {
        const response = yield call(() => postRequest(PRINTERS.REFRESH_PRINTERS, { refresh: action.refresh }))
        yield put(storePrinters(response.data))
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
    yield put(setLoadingStatus(false))
}

function* updatePrintersSaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(PRINTERS.UPDATE_PRINTERS, { printers: action.printers }))
        yield put(storePrinters(response.data))
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* uploadLogoSaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(PRINTERS.UPLOAD_LOGO, action.file))
        yield put(storeLogo(action.file.byteArray))
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* fetchLogoSaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(PRINTERS.FETCH_LOGO))
        yield put(storeLogo(response.data))
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* deleteLogoSaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(PRINTERS.DELETE_LOGO))
        yield put(storeLogo([]))
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

// tags sagas
function* listTags(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(TAGS.LIST_TAGS, { companyId: action.companyId }))
        var tags = []
        response.data.tags.forEach(tag => {
            tags.push({
                companyId: tag.companyId,
                tagName: tag.tagName,
                isLocationTag: tag.isLocationTag,
                tagId: tag.tagId,
                isPrintable: tag.isPrintable,
                key: tag.tagId
            })
        });
        yield put(storeTags(tags))
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* editTags(action) {
    yield put(hideError())
    try {
        const request = {
            companyId: action.companyId,
            newTags: action.newTags,
            editedTags: action.editedTags,
            deletedTags: action.deletedTags,
        }
        const response = yield call(() => postRequest(TAGS.EDIT_TAGS, request))
        if (response.status === 200 && response.data.success) {
            action.effect(response.data.success)
        } else {
            action.effect(false)
        }
    } catch (error) {
        action.effect(false)
    }
}

function* getUUID(action) {
    yield put(hideError())
    //field: "tag", "condition", "filter", "action", "rule"
    try {
        const response = yield call(() => postRequest(UUID.GET_UUID, { field: action.field }))
        if (response.data.uuid) {
            action.setUUID(response.data.uuid)
        } else {
            action.setUUID(null)
        }
    } catch (error) {
        action.setUUID(null)
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* getUUIDs(action) {
    yield put(hideError())
    //field: "tag", "condition", "filter", "action", "rule"
    try {
        const response = yield call(() => postRequest(UUID.GET_UUIDS, { field: action.field, number: action.number }))
        if (response.data.uuids) {
            action.setUUIDs(response.data.uuids)
        } else {
            action.setUUIDs([])
        }
    } catch (error) {
        action.setUUIDs([])
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* listPackagesSaga() {
    yield put(hideError())
    try {
        const response = yield call(() => getRequest(PACKAGES.LIST_PACKGES))
        yield put(storePackages(response.data))
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* createPackageSaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(PACKAGES.CREATE_PACKAGE, action.pkg))
        if (response.status === 200 && response.data.success) {
            yield put(storeUpdatedPackage(response.data.box))
            action.reset()
        } else {
            yield put(showErrorMessage(response.data.message))
        }
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }

}

function* updatePackageSaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(PACKAGES.UPDATE_PACKAGE, action.updatedPackage))
        if (response.status === 200 && response.data.success) {
            yield put(storeUpdatedPackage(response.data.box))
        } else {
            yield put(showErrorMessage(response.data.message))
        }
    } catch (error) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* fetchListingsSaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => getRequest(LISTINGS.GET_LISTINGS))
        if (response.status === 200) {
            yield put(storeListings(response.data))
        }
    } catch (err) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* fetchPricingListingsSaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => getRequest(LISTINGS.GET_PRICING_LISTINGS))
        if (response.status === 200) {
            yield put(storePricingListings(response.data))
        }
    } catch (err) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* addRelatedProductSaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(LISTINGS.ADD_RELATED_PRODUCT, {relatedProduct: action.relatedProduct, webstoreProductId: action.webstoreProductId }))
        if (response.data.success) {
            yield put(storeResponseMessage(response.data.message))
            yield put(storeRelatedProducts(response.data.relatedProducts))
            const currentDate = new Date();
            const thirtyDaysBefore = new Date(currentDate);
            thirtyDaysBefore.setDate(currentDate.getDate() - 30);
            // Convert both dates to ISO strings in UTC ("YYYY-MM-DDTHH:MM:SSZ")
            const startDate = thirtyDaysBefore.toISOString().replace(/\.\d+Z$/, 'Z'); // Replaces '.sssZ' with 'Z'
            const endDate = currentDate.toISOString().replace(/\.\d+Z$/, 'Z'); // Replaces '.sssZ' with 'Z'
            yield put(getSalesRank(action.webstoreProductId, startDate, endDate))
            yield put(getBuyBoxPrices(action.webstoreProductId, startDate, endDate))
            yield put(fetchPricingListings())
        }
        else{
            yield put(showErrorMessage(response.data.message))
        }
    } catch (err) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* editRelatedProductSaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(LISTINGS.EDIT_RELATED_PRODUCT, {relatedProduct: action.relatedProduct, webstoreProductId: action.webstoreProductId }))
        if (response.data.success) {
            const currentDate = new Date();
            const thirtyDaysBefore = new Date(currentDate);
            thirtyDaysBefore.setDate(currentDate.getDate() - 30);
            // Convert both dates to ISO strings in UTC ("YYYY-MM-DDTHH:MM:SSZ")
            const startDate = thirtyDaysBefore.toISOString().replace(/\.\d+Z$/, 'Z'); // Replaces '.sssZ' with 'Z'
            const endDate = currentDate.toISOString().replace(/\.\d+Z$/, 'Z'); // Replaces '.sssZ' with 'Z'
            yield put(getSalesRank(action.webstoreProductId, startDate, endDate))
        }
        else{
            yield put(showErrorMessage(response.data.message))
        }
    } catch (err) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* updateRelatedProductSaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(LISTINGS.UPDATE_RELATED_PRODUCTS, {relatedProducts: action.relatedProducts, notes: action.notes, webstoreProductId: action.webstoreProductId }))
        if (response.data.success) {
            yield put(storeResponseMessage(response.data.message))
            yield put(storeRelatedProducts(response.data.relatedProducts))
            yield put(storeUpdatedPricingListings(response.data.productToReturn))
            const currentDate = new Date();
            const thirtyDaysBefore = new Date(currentDate);
            thirtyDaysBefore.setDate(currentDate.getDate() - 30);
            // Convert both dates to ISO strings in UTC ("YYYY-MM-DDTHH:MM:SSZ")
            const startDate = thirtyDaysBefore.toISOString().replace(/\.\d+Z$/, 'Z'); // Replaces '.sssZ' with 'Z'
            const endDate = currentDate.toISOString().replace(/\.\d+Z$/, 'Z'); // Replaces '.sssZ' with 'Z'
            yield put(getSalesRank(action.webstoreProductId, startDate, endDate))
            yield put(getBuyBoxPrices(action.webstoreProductId, startDate, endDate))

        }
        else{
            yield put(showErrorMessage(response.data.message))
        }
    } catch (err) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* getSalesRankSaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(LISTINGS.GET_SALES_RANK, { webstoreProductId: action.webstoreProductId, fromDate: action.fromDate, toDate: action.toDate }))
        if (response.status === 200) {
            yield put(storeSalesrank(response.data.salesRank))
        }
        else{
            yield put(showErrorMessage(response.data.message))
        }
    } catch (err) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* getBuyBoxPricesSaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(LISTINGS.GET_BUY_BOX_PRICES, { webstoreProductId: action.webstoreProductId, fromDate: action.fromDate, toDate: action.toDate }))
        if (response.status === 200) {
            yield put(storeBuyBoxPrices(response.data.buyBoxPrices.mainBuyBox, response.data.buyBoxPrices.relatedProductsBuyBox))
        }
        else{
            yield put(showErrorMessage(response.data.message))
        }
    } catch (err) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}
function* getRelatedProductsSaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(LISTINGS.GET_RELATED_PRODUCTS, { webstoreProductId: action.webstoreProductId}))
        if (response.status === 200) {
            yield put(storeRelatedProducts(response.data.relatedProducts))
        }
        else{
            yield put(showErrorMessage(response.data.message))
        }
    } catch (err) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* fetchAmazonListingsPriceSaga(action) {
    yield put(setPricingLoadingStatus(true))
    try {
        const response = yield call(() => getRequest(LISTINGS.FETCH_AMAZON_LISTINGS_PRICE))
        if (response.status === 200) {
            yield put(fetchPricingListings())
        }
    } catch (err) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }finally {
        yield put(setPricingLoadingStatus(false))
    }
}

function* fetchBarcodesSaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => getRequest(LISTINGS.GET_BARCODES))
        if (response.status === 200) {
            yield put(storeBarcodes(response.data))
        }
    } catch (err) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* changePrimarySaga(action){
    yield put(hideError())
    try {
        const ob = {
            sku: action.update.sku,
            quantity: action.update.quantity,
            bool: action.bool
        }
        const response = yield call(() => postRequest(LISTINGS.UPDATE_PRIMARY, ob))
    } catch {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* fetchUnmappedListings(action) {
    yield put(hideError())
    try {
        const response = yield call(() => getRequest(LISTINGS.GET_UNMAPPED_LISTINGS))
        if (response.status === 200) {
            yield put(storeUnmappedProducts(response.data));
        }
    } catch (err) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* fetchActiveListings(action) {
    yield put(hideError())
    try {
        const response = yield call(() => getRequest(LISTINGS.GET_ACTIVE_LISTINGS))
        if (response.status === 200) {
            yield put(storeActiveProducts(response.data));
        }
    } catch (err) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* fetchInactiveListings(action) {
    yield put(hideError())
    try {
        const response = yield call(() => getRequest(LISTINGS.GET_INACTIVE_LISTINGS))
        if (response.status === 200) {
            yield put(storeInactiveProducts(response.data));
        }
    } catch (err) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* fetchDeletedListings(action) {
    yield put(hideError())
    try {
        const response = yield call(() => getRequest(LISTINGS.GET_DELETED_LISTINGS))
        if (response.status === 200) {
            yield put(storeDeletedProducts(response.data));
        }
    } catch (err) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* updateBarcodes(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(LISTINGS.UPDATE_BARCODES, action.data))
        if (response.data.success) {
            if (response.data.newBarcodeRow) {
                yield put(storeUpdatedBarcode(response.data.newBarcodeRow))
            }
        } else {
            yield put(showErrorMessage(response.data.message))
        }
    } catch (err) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }

}

function* updateListing(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(LISTINGS.UPDATE_LISTING, action.data))
        if (response.data.success) {
            yield put(storeUpdatedListings(response.data.item))
            yield put(storeResponseMessage(response.data.message))
        } else {
            yield put(showErrorMessage(response.data.message))
        }
    } catch (err) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* updatePricingListingSaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(LISTINGS.UPDATE_PRICING_LISTING, action.data))
        if (response.data.success) {
            yield put(fetchPricingListings())
        } else {
            yield put(showErrorMessage(response.data.message))
        }
    } catch (err) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* updateRepricingSaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(LISTINGS.UPDATE_RRICING, action.data))
        if (response.data.success) {
      yield put(fetchPricingListings())

        } else {
            yield put(showErrorMessage(response.data.message))
        }
    } catch (err) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* getTop3BuyBoxOffersSaga(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(LISTINGS.GET_TOP_3_BUY_BOX_OFFERS, { webstoreProductId: action.webstoreProductId}))
        if (response.status === 200) {
            yield put(storeTop3BuyBoxOffers(response.data.top3BuyBoxOffers))

        } else {
            yield put(showErrorMessage(response.data.message))
        }
    } catch (err) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* deleteListing(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(LISTINGS.DELETE_LISTING, action.data))
        if (response.data.success) {
            yield put(storeDeleteListing(action.data.id))
        } else {
            yield put(showErrorMessage(response.data.message))
        }
    } catch (err) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* addNewChild(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(LISTINGS.ADD_NEW_CHILD, action.update))
    } catch (err) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* deleteChild(action) {
    yield put(hideError())
    try {
        const response = yield call(() => postRequest(LISTINGS.DELETE_CHILD, action.update))
    } catch (err) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* fetchAmazonSkus(action) {
    yield put(setLoadingStatus(true))
    try {
        const response = yield call(() => getRequest(LISTINGS.FETCH_AMAZON_SKUS))
        if (response.status === 200) {
            yield put(fetchListings())
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    } finally {
        yield put(setLoadingStatus(false))
    }
}

function* updateInventoryAllocation(action) {
    try {
        const response = yield call(() => postRequest(MARKETPLACES.UPDATE_INVENTORY_ALLOCATION, action))
        if (response?.data?.success){
            yield put(storeInventoryAllocation(action))
        }
        if (response.status !== 200) {
            yield put(showErrorMessage(response.message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* setSkuLocation(action) {
    try {
        const response = yield call(() => postRequest(LISTINGS.SET_SKU_LOCATION, action.data))
        if (response.status === 200) {
            yield put(fetchListings())
        }
        else{
            yield put(showErrorMessage(response.message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* setShippingTemplate(action) {
    try {
        const response = yield call(() => postRequest(LISTINGS.SET_SHIPPING_TEMPLATE, action.data))
        if (response.status === 200) {
            // check for completion and retrieve logs every 3 seconds, will stop fetching when the requests are completed
            for (let a = 0; a < 20; a++) {
                yield put(fetchListings())

                const fetchResponse = yield call(() => postRequest(LISTINGS.FETCH_REQUESTS_STATUSES, response.data?.listingQueueIds));
                yield put(storeResponseMessage(fetchResponse.data?.message))
                // if all requests are completed, then stop checking for completion
                if (fetchResponse.data?.completed) {
                    break
                }
                // Wait 3 seconds
                yield delay(3000);
            }
        }
        else{
            yield put(showErrorMessage(response.data?.message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* setHandlingTIme(action) {
    try {
        const response = yield call(() => postRequest(LISTINGS.SET_HANDLING_TIME, action.data))
        if (response.status === 200) {

            // check for completion and retrieve logs every 3 seconds, will stop fetching when the requests are completed
            for (let a = 0; a < 20; a++) {
                yield put(fetchListings())

                const fetchResponse = yield call(() => postRequest(LISTINGS.FETCH_REQUESTS_STATUSES, response.data?.listingQueueIds));
                yield put(storeResponseMessage(fetchResponse.data?.message))
                // if all requests are completed, then stop checking for completion
                if (fetchResponse.data?.completed) {
                    break
                }
                // Wait 3 seconds
                yield delay(3000);
            }
        }
        else{
            yield put(showErrorMessage(response.data?.message))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* createMarketplace(action) {
    const response = yield call(() => postRequest(MARKETPLACES.CREATE_MARKETPLACE, action.marketplace))
    if (response.status === 200) {
        yield put(updateMarketplaces(response.data))
        yield action.reset()
    }
}

function* listMarketplaces(action) {
    const response = yield call(() => postRequest(MARKETPLACES.LIST_MARKETPLACES, action.id));
    if (response.status === 200) {
        yield put(storeMarketplaces(response.data))
    }
}

function* editMarketplace(action) {
    const response = yield call(() => postRequest(MARKETPLACES.EDIT_MARKETPLACE, action.marketplace));
    if (response.status === 200) {
        yield put(updateMarketplacesEdit(response.data));
        yield action.reset()
    }
}

function* deleteMarketplace(action) {
    const response = yield call(() => postRequest(MARKETPLACES.DELETE_MARKETPLACE, action.toDelete));
    if (response.status === 200) {
        yield put(updateMarketplacesDelete(action.toDelete.marketplaceId, response.data.deleted))
        yield put(storeResponseMessage(response.data.message))
    }
}

function* validateMarketplace(action) {
    const response = yield call(() => postRequest(MARKETPLACES.VALIDATE_MARKETPLACE, action.marketplace));
    if (response.status === 200) {
        if (!response.data.success)
            yield put(showErrorMessage(response.data.message ? response.data.message : "Failed to validate credentials"))
        action.setIsValid(response.data.success)
        yield put(storeResponseMessage(response.data.message))
    }
}

function* scrubDataSaga(action) {
    yield put(setLoadingStatus(true))
    try {
        const data = {
            fromDate: action.fromDate,
            toDate: action.toDate,
        }
        const response = yield call(() => postRequest(DATA_SCRUBBING.SCRUB, data))
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    } finally {
        yield put(setLoadingStatus(false))
    }
}

function* saveProductCategoryChanges(action) {
    try {
        const response = yield postRequest(PRODUCT_CATEGORY.SAVE_PRODUCT_CATEGORY_CHANGES, action.data)
        if (response.data.success) {
            yield put(getAllCategory())
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* getAllCategorySaga() {
    try {
        const response = yield call(() => getRequest(PRODUCT_CATEGORY.GET_ALL_CATEGORY))
        yield put(storeAllCategory(response.data))
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }

}

function* removeProductCategory(action) {
    try {
        const response = yield postRequest(PRODUCT_CATEGORY.REMOVE_PRODUCT_CATEGORY, {id: action.id})
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* getCountryCodes() {
    const response = yield call(() => getRequest(PRODUCTS.GET_ALL_COUNTRIES))
    yield put(storeCountryCodes(response.data))
}

function* getAmazonFeed(){
    try{
        const response = yield getRequest(AMAZON.GET_AMAZON_FEEDS)
        yield put(storeAmzFeeds(response.data))
    }catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* restartFeedsFetch(action){
    try{
        const response = yield postRequest(AMAZON.RESTART_FEED_FETCH, {feedId: action.data})
        const res = yield getRequest(AMAZON.GET_AMAZON_FEEDS)
        yield put(storeAmzFeeds(res.data))
    }catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* getPrintConfig() {
    try{
        const response = yield postRequest(PRINTERS.FETCH_CONFIG)

        if (response.data.success)
            yield put(storePrintConfig(response?.data))

    } catch (e) {
        // In case of error, ignore and use default values
        // yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }

}

function* archiveLabelsSaga(action){
    try {
        const response = yield postRequest(ORDERS.ARCHIVE_LABELS, {fromDate: action.fromDate, toDate: action.toDate, OrderIds: action.orderIds})

        if (response.data.success) {
            yield put(storeArchivedLabels(response.data.archivedLabelTrackingNumbers))
        }
    } catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    } finally {
        yield put(storeArchivedLabelsReturned(true))
    }
}

function* getEmailTemplates(){
    try{
        const response = yield getRequest(EMAIL_TEMPLATES.GET_EMAIL_TEMPLATE)
        yield put(storeEmailTemplates(response.data))
    }catch(e){
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* updateEmailTemplate(action){
    try{
        yield postRequest(EMAIL_TEMPLATES.UPDATE_EMAIL_TEMPLATE, action.data)
        const response = yield getRequest(EMAIL_TEMPLATES.GET_EMAIL_TEMPLATE)
        yield put(storeEmailTemplates(response.data))
    }catch(e){
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* createEmailTemplate(action){
    try{
        yield postRequest(EMAIL_TEMPLATES.CREATE_NEW_EMAIL_TEMPLATE, action.data)
        const response = yield getRequest(EMAIL_TEMPLATES.GET_EMAIL_TEMPLATE)
        yield put(storeEmailTemplates(response.data))
    }catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* deleteEmailTemplate(action){
    try{
        yield postRequest(EMAIL_TEMPLATES.DELETE_NEW_EMAIL_TEMPLATE, action.data)
        const response = yield getRequest(EMAIL_TEMPLATES.GET_EMAIL_TEMPLATE)
        yield put(storeEmailTemplates(response.data))
    }catch(e){
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* getEmailTemplateType(action){
    try{
        const res = yield getRequest(EMAIL_TEMPLATES.GET_EMAIL_TEMPLATE_TYPES)
        yield put(storeEmailTemplateTypes(res.data))
    }catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* emailTemplateTypeChangeSaga(action) {
    try{
        yield postRequest(EMAIL_TEMPLATES.UPDATE_EMAIL_TEMPLATE_TYPE, action.data)
        const res = yield getRequest(EMAIL_TEMPLATES.GET_EMAIL_TEMPLATE_TYPES)
        yield put(storeEmailTemplateTypes(res.data))
    }catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* createNewEmailTemplateTypeSaga(action) {
    try{
        yield postRequest(EMAIL_TEMPLATES.CREATE_NEW_EMAIL_TEMPLATE_TYPE, action.data)
        const res = yield getRequest(EMAIL_TEMPLATES.GET_EMAIL_TEMPLATE_TYPES)
        yield put(storeEmailTemplateTypes(res.data))
    }catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* emailTemplateTypeDeleteSaga(action) {
    try{
        yield postRequest(EMAIL_TEMPLATES.DELETE_NEW_EMAIL_TEMPLATE_TYPE, action.data)
        const res = yield getRequest(EMAIL_TEMPLATES.GET_EMAIL_TEMPLATE_TYPES)
        yield put(storeEmailTemplateTypes(res.data))
    }catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* fetchSendgridTemplatesSaga() {
    try{
        yield getRequest(EMAIL_TEMPLATES.FETCH_SENDGRID_TEMPLATES)
        const res = yield getRequest(EMAIL_TEMPLATES.GET_EMAIL_TEMPLATE_TYPES)
        yield put(storeEmailTemplateTypes(res.data))
    }catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* fetchPurchaseOrderEmailTemplateSaga(action) {
    try{
        const res = yield postRequest(SEND_GRID.FETCH_PURCHASE_ORDER_EMAIL_TEMPLATE, action.data)
        if (res.data.success) {
            yield put(storePurchaseOrderEmailTemplate(true))
        } else {
            yield put(storePurchaseOrderEmailTemplate(false))
        }
    }catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* sendEmail_purchaseOrderSaga(action) {
    try{
        const response = yield postRequest(SEND_GRID.SEND_EMAIL_PURCHASE_ORDER, action.data)
        // yield put((res.data))
        if (response.data.success) {
            yield action.reset()
        } else {
            yield put(showErrorMessage(response.data.message))
        }
    }catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* sendInvoiceEmail(action){
    try{
        const response = yield postRequest(SEND_GRID.SEND_INVOICE_EMAIL, {customerId: action.customerId, data: action.data})
        if (response.data.success) {
            yield action.reset()
        } else {
            yield put(showErrorMessage(response.data.message))
        }
    }catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}
function* sendPOEmail(action){
    try{
        const response = yield postRequest(SEND_GRID.SEND_PO_EMAIL, {supplierId: action.supplierId, data: action.data})
        if (response.data.success) {
            yield action.reset()
        } else {
            yield put(showErrorMessage(response.data.message))
        }
    }catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

function* sendCustomerPOEmail(action){
    try{
        const response = yield postRequest(SEND_GRID.SEND_CUSTOMER_PO_EMAIL, {customerId: action.customerId, data: action.data})
        if (response.data.success) {
            yield action.reset()
        } else {
            yield put(showErrorMessage(response.data.message))
        }
    }catch (e) {
        yield put(showErrorMessage(SERVER_FAILED_ERROR_MESSAGE))
    }
}

export default function* settingsSagas() {
    yield takeLatest(CREATE_CARRIER, createCarrier);
    yield takeLatest(IMPORT_HISTORICAL_DATA, importHistoricalData);
    yield takeLatest(LIST_SHEETS, listSheets);
    yield takeLatest(LIST_ADDRESSES, listAddresses);
    yield takeLatest(LIST_CARRIERS, listCarriers);
    yield takeLatest(LIST_SHIPPING_TEMPLATES, listShippingTemplates);
    yield takeLatest(LIST_CARRIER_NAMES, listCarrierNames);
    yield takeLatest(LIST_LINKED_ADDRESSES, listLinkedAddresses);
    yield takeLatest(EDIT_CARRIER, editCarrier);
    yield takeLatest(DELETE_CARRIER, deleteCarrier);
    yield takeLatest(SET_DEFAULT_CARRIER, setDefaultCarrier);
    yield takeLatest(ADDRESS_VALIDATION_CHANGE, AddressValidationChange);
    yield takeLatest(VALIDATE_FEDEX_REST_CREDENTIALS, validateFedExRESTCredentials)

    yield takeLatest(LIST_ALL_BANK_ACCOUNTS, listAllBankAccountsSaga)
    yield takeLatest(SAVE_BANK_ACCOUNT, saveBankAccountSaga)
    yield takeLatest(DELETE_BANK_ACCOUNT, deleteBankAccountSaga)

    yield takeLatest(LIST_PRODUCTS, listProductsSaga)
    yield takeLatest(CREATE_PRODUCT, createProductSaga)
    yield takeLatest(UPDATE_PRODUCT, updateProductSaga)
    yield takeLatest(DELETE_PRODUCT, deleteProductSaga);

    yield takeLatest(CREATE_SUPPLIER, createSupplierSaga)
    yield takeLatest(UPDATE_SUPPLIER, updateSupplierSaga)
    yield takeLatest(PROCESS_SUPPLIER_INVOICES, processSupplierInvoicesSaga)
    yield takeLatest(LIST_SUPPLIERS, listSuppliersSaga)

    yield takeLatest(LIST_LOCATIONS, listLocationsSaga)
    yield takeLatest(LIST_AVAILABLE_LOCATIONS, listAvailableLocationsSaga)
    yield takeLatest(CREATE_LOCATION, createLocationSaga)
    yield takeLatest(UPDATE_LOCATION, updateLocationSaga)

    yield takeLatest(LIST_PRINTERS, listPrintersSaga)
    yield takeLatest(UPDATE_PRINTERS, updatePrintersSaga)
    yield takeLatest(REFRESH_PRINTERS, refreshPrintersSaga)
    yield takeLatest(UPLOAD_LOGO, uploadLogoSaga)
    yield takeLatest(FETCH_LOGO, fetchLogoSaga)
    yield takeLatest(DELETE_LOGO, deleteLogoSaga)

    yield takeLatest(LIST_TAGS, listTags)
    yield takeLatest(EDIT_TAGS, editTags)

    yield takeLatest(GET_UUID, getUUID)
    yield takeLatest(GET_UUIDS, getUUIDs)

    yield takeLatest(LIST_PACKAGES, listPackagesSaga)
    yield takeLatest(CREATE_PACKAGE, createPackageSaga)
    yield takeLatest(UPDATE_PACKAGE, updatePackageSaga)

    yield takeLatest(LIST_EMAIL_TEMPLATES, getEmailTemplates)
    yield takeLatest(EMAIL_TEMPLATE_UPDATE, updateEmailTemplate)
    yield takeLatest(CREATE_NEW_EMAIL_TEMPLATE, createEmailTemplate)
    yield takeLatest(DELETE_NEW_EMAIL_TEMPLATE, deleteEmailTemplate)
    yield takeLatest(GET_EMAIL_TEMPLATE_TYPES, getEmailTemplateType)
    yield takeLatest(EMAIL_TEMPLATE_TYPE_UPDATE, emailTemplateTypeChangeSaga)
    yield takeLatest(CREATE_NEW_EMAIL_TEMPLATE_TYPE, createNewEmailTemplateTypeSaga)
    yield takeLatest(DELETE_NEW_EMAIL_TEMPLATE_TYPE, emailTemplateTypeDeleteSaga)
    yield takeLatest(FETCH_SENDGRID_TEMPLATES, fetchSendgridTemplatesSaga)
    yield takeLatest(FETCH_PURCHASE_ORDER_EMAIL_TEMPLATE, fetchPurchaseOrderEmailTemplateSaga)
    yield takeLatest(SEND_EMAIL_PURCHASE_ORDER, sendEmail_purchaseOrderSaga)
    yield takeLatest(PO_EMAIL_SEND, sendPOEmail)
    yield takeLatest(CUSTOMER_PO_EMAIL_SEND, sendCustomerPOEmail)

    yield takeLatest(FETCH_LISTINGS, fetchListingsSaga)
    yield takeLatest(FETCH_PRICING_LISTINGS, fetchPricingListingsSaga)
    yield takeLatest(Add_RELATED_PRODUCT, addRelatedProductSaga)
    yield takeLatest(EDIT_RELATED_PRODUCT, editRelatedProductSaga)
    yield takeLatest(UPDATE_RELATED_PRODUCTS, updateRelatedProductSaga)
    yield takeLatest(GET_SALES_RANK, getSalesRankSaga)
    yield takeLatest(GET_BUY_BOX_PRICES, getBuyBoxPricesSaga)
    yield takeLatest(GET_RELATED_PRODUCTS, getRelatedProductsSaga)
    yield takeLatest(FETCH_AMAZON_LISTINGS_PRICE, fetchAmazonListingsPriceSaga)
    yield takeLatest(SET_SKU_LOCATION, setSkuLocation)
    yield takeLatest(SET_SHIPPING_TEMPLATE, setShippingTemplate)
    yield takeLatest(SET_HANDLING_TIME, setHandlingTIme)
    yield takeLatest(FETCH_BARCODES, fetchBarcodesSaga)
    yield takeLatest(CHANGE_PRIMARY, changePrimarySaga)
    yield takeLatest(ADD_NEW_CHILD, addNewChild)
    yield takeLatest(DELETE_CHILD, deleteChild)
    yield takeLatest(UPDATE_BARCODES, updateBarcodes)
    yield takeLatest(UPDATE_LISTING, updateListing)
    yield takeLatest(UPDATE_PRICING_LISTING, updatePricingListingSaga)
    yield takeLatest(UPDATE_REPRICING, updateRepricingSaga)
    yield takeLatest(GET_TOP_3_BUY_BOX_OFFERS, getTop3BuyBoxOffersSaga)
    yield takeLatest(DELETE_LISTING, deleteListing)
    yield takeLatest(GET_UNMAPPED_PRODUCTS, fetchUnmappedListings)
    yield takeLatest(GET_ACTIVE_PRODUCTS, fetchActiveListings)
    yield takeLatest(GET_INACTIVE_PRODUCTS, fetchInactiveListings)
    yield takeLatest(GET_DELETED_PRODUCTS, fetchDeletedListings)
    yield takeLatest(FETCH_AMAZON_SKUS, fetchAmazonSkus)

    yield takeLatest(SCRUB_DATA, scrubDataSaga)

    yield takeLatest(CREATE_MARKETPLACE, createMarketplace)
    yield takeLatest(LIST_MARKETPLACES, listMarketplaces)
    yield takeLatest(UPDATE_INVENTORY_ALLOCATION, updateInventoryAllocation)
    yield takeLatest(MANUAL_EXECUTE_AUTOMATION_SETTINGS, manualExecuteCarrierAutoSetting)
    yield takeLatest(EDIT_MARKETPLACE, editMarketplace)
    yield takeLatest(DELETE_MARKETPLACE, deleteMarketplace)
    yield takeLatest(VALIDATE_MARKETPLACE, validateMarketplace)

    yield takeLatest(SAVE_PRODUCT_CATEGORY_CHANGES, saveProductCategoryChanges)
    yield takeLatest(GET_ALL_CATEGORY, getAllCategorySaga)
    yield takeLatest(REMOVE_PRODUCT_CATEGORY, removeProductCategory)

    yield takeLatest(GET_COUNTRY_CODES, getCountryCodes)

    yield takeLatest(GET_AMAZON_FEEDS, getAmazonFeed)
    yield takeLatest(RESTART_FEED_FETCH, restartFeedsFetch)

    yield takeLatest(ARCHIVE_LABELS, archiveLabelsSaga)
    yield takeLatest(PREVIEW_BANK_FILE, previewBankFileSaga)

    yield takeLatest(GET_PRINT_CONFIG, getPrintConfig)
    yield takeLatest(INVOICE_EMAIL_SEND, sendInvoiceEmail)
};