export const LABELS_PRINTED_TITLE = "Labels Printed"
export const SUMMARY_TITLE = "Summary"
export const SHIPMENTS_FULLFILLED_TITLE = "Shipments Completed"
export const SHIPMENTS_BREAKDOWN = "Shipments Breakdown"
export const LABELS_SCANNED_TITLE = "Printed Labels Scanned"
export const SCAN_LABEL_ACTION_TITLE = "Scanned Printed Labels"
export const SHIPMENTS_PACKED_TITLE = "Shipments Packed"
export const SCAN_SHIPMENTS_TITLE = "Scanned Packed Shipments"
export const SCAN_CARRIER_BARCODE_TITLE = "Scan Carrier Barcode"
export const SCAN_PRODUCT_TITLE = "Scan Product Barcode"
export const SCAN_SECOND_PRODUCT_TITLE = "Scan 2nd Product Barcode"
export const MISSING_SCANNED_TITLE = "Labels Not Scanned"
export const MISSING_PACKED_TITLE = "Shipments Not Packed"
export const PENDING_TITLE = "Pending"
export const TODAY_PENDING_TITLE = "Today's Labels"
export const TOTAL_TITLE = "Total"
export const RESOLVE_ERROR_TEXT = "Resolve"
export const FILTER_BY_CARRIER = "carrier"
export const NEXT_CANCEL_BUTTON_TEXT = "Next"
export const CONFIRM_CANCEL_BUTTON_TEXT = "Confirm"
export const REASON_FOR_CANCEL = "Reason for void"
export const COMMENT_FOR_CANCEL = "Comment for void"
export const DASHBOARD_TOOLBAR_TITLE = "Dashboard"
export const REFRESH_TITLE = "Refresh Dashboard"
export const DASHBOARD_SUMMARY_TITLE = "Dashboard - Summary"
export const DASHBOARD_LIMBO_SHIPMENTS_TITLE = "Dashboard - Tracking Summary"
export const FEDEX = "FedEx"
export const CANPAR = "Canpar"
export const PENDING_TOOLTIP_INFO = "Pending are scanned/printed shipments leftover from previous days. Total count includes pending."
export const RETRIEVE_USER_TABLE_FILTER_FAILED ="retrieve user table filter information failed"
export const SERVER_FAILED_ERROR_MESSAGE = "Server Failed";
export const ACCESS_DENIED = "Access Denied";
export const DAY_FILTER_GAP_LIMIT_ERROR_PARTA = "The date range cannot exceed "
export const DAY_FILTER_GAP_LIMIT_ERROR_PARTB = " day(s) "
export const FIND_INVOICE_BY_INVOICE_NUMBER_ERROR_MESSAGE = "Find invoice by invoice number failed";
export const DIFFERENCE= "Missing Labels";
export const REPORT_TITLE = "Report";
export const EXPAND_ALL = "Expand All"
export const RMA_NOT_MAPPED= "RMA is not mapped to any shipments"
export const ORDER_INFO_NOT_MAPPED= "Order info is not mapped to any shipments"
export const SEARCH= "Search"

export const MANUAL_VOID_STEPS = {
    NOT_VOIDING: "not voiding",
    REASON: "reason",
    COMMENT: "comment"
}

export const MANUAL_VOID_REASONS = [
    "Duplicate Label",
    "Never Shipped",
    "Order Cancelled",
    "Other",
    "Reassigned to Different Carrier",
    "Shipped from Another Location",
    "Upgraded",
]

export const PAGE_STATE = {
    SUMMARY:    "SUMMARY",
    DASHBOARD:  "DASHBOARD",
    LIMBO_SHIPMENTS: "LIMBO_SHIPMENTS",
}