import React from "react";
import '../../pricingDashboard/pricingDashboardPages/PricingDashboardTableRow.css';
import Tooltip from "@material-ui/core/Tooltip";
import {CANCEL_EDIT_ENTRY} from "../../pricingDashboard/constants";
import EditIcon from "@material-ui/icons/Edit";
import { FormHelperText, IconButton, TableCell, TextField, Button, Checkbox } from '@material-ui/core';
import ClearIcon from '@mui/icons-material/Clear';
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { CARRY_FORWARD_OPTIONS, EDIT_LEDGER, LEDGERS_PAGES, SOURCE_OPTIONS, SUM_RULE_OPTIONS } from './constants';
import Autocomplete from "@material-ui/lab/Autocomplete";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import TwoStepDelete from "../../global/subcomponents/twoStepDelete/TwoStepDelete";
import { Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import {CANCEL, SAVE} from "../../global/constants";
import SummarizeIcon from '@mui/icons-material/Summarize';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';



class LedgerTableRow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            deleteId: "",
            isDeleted: false,
            isDialogOpen: false,
            deleteLedgerId: "",
            open: false
        };
    }

    componentDidMount() {
        let newState = {
            deleteListing: false,
            name: '',
            source: '',
            type: '',
            sumRule: '',
            carryForwardBalance: (this.props.ledgerType === "Liabilities" || this.props.ledgerType=== "Equity" || this.props.ledgerType === "Assets" ? 'Yes' : 'No'),
            category: '',
        }
        this.props.setRowState(newState)

    }

    handleOpen = () => {
        this.setState({ isDialogOpen: true });
    };

    handleClose = () => {
        this.setState({ isDialogOpen: false });
    };

    getBalanceForSelectedYear = (row) => {

        const yearRate =
            this.props.settings?.yearRates?.find(
                (setting) => setting.year === this.props.year.toString()
            )?.rate || 0

        const averageRate =
          this.props.settings?.averageRates?.find(
            (setting) => setting.year === this.props.year.toString()
          )?.rate || 0

        const applicableRate = ['Equity', 'Assets', 'Liabilities'].includes(this.props.ledgerType)
          ? yearRate
          : ['Expenses', 'Income'].includes(this.props.ledgerType)
            ? averageRate
            : 1

        if (row?.name === 'Accounts Payable' || row?.name === "Cost of Sales" || row?.name === 'Accounts Receivables') {

            let credits = 0

            if (this.props.currency === "CAD") {
                // Calculate only CAD credits
                credits = row?.ledgerEntries
                    ?.filter((entry) => entry?.year === this.props.year) // Filter by year
                    ?.flatMap((entry) => entry?.entries) // Access nested entries
                    ?.filter((nestedEntry) => nestedEntry?.currency === "CAD") // Filter by CAD currency
                    ?.reduce((total, nestedEntry) => total + (nestedEntry?.credit || 0), 0)
            } else if (this.props.currency === "USD") {
                // Calculate only USD credits
                credits = row?.ledgerEntries
                    ?.filter((entry) => entry?.year === this.props.year) // Filter by year
                    ?.flatMap((entry) => entry?.entries) // Access nested entries
                    ?.filter((nestedEntry) => nestedEntry?.currency === "USD") // Filter by USD currency
                    ?.reduce((total, nestedEntry) => total + (nestedEntry?.credit || 0), 0)
            } else if (this.props.currency === "Consolidated CAD") {
                credits = row?.ledgerEntries
                  ?.filter((entry) => entry?.year === String(this.props.year)) // Match year
                  ?.flatMap((entry) => entry?.entries || []) // Flatten entries, default to an empty array
                  ?.reduce((total, nestedEntry) => {
                      if (nestedEntry?.currency === 'CAD') {
                          // Add CAD credits directly
                          return total + (nestedEntry?.credit || 0);
                      } else if (nestedEntry?.currency === 'USD') {
                          // Convert USD credits to CAD
                          const conversionRate = applicableRate === yearRate ? yearRate : averageRate;
                          const convertedCredit = (nestedEntry?.credit || 0) * conversionRate;
                          return total + convertedCredit;
                      }
                      return total; // Skip if currency is neither CAD nor USD
                  }, 0);

            }

            credits = credits ?? 0
            return credits
        }

        const balanceForSelectedYear = (() => {
            if (this.props.currency === 'Consolidated CAD') {
                // Sum both CAD and USD balance
                const cadEntry = row?.ledgerEntries?.find(
                    (entry) => entry?.year === this.props.year && entry?.currency === 'CAD'
                );
                const usdEntry = row?.ledgerEntries?.find(
                    (entry) => entry?.year === this.props.year && entry?.currency === 'USD'
                );

                const cadAmount = cadEntry?.balance || 0; // Default to 0 if not found
                const usdAmount = usdEntry?.balance || 0;

                return { ...cadEntry, balance: cadAmount + usdAmount }; // Return consolidated result
            } else {
                return row?.ledgerEntries?.find(
                    (entry) => entry?.year === this.props.year && entry?.currency === this.props.currency
                );
            }
        })();

        return balanceForSelectedYear?.balance ?? 0;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.listingIdBeingEdited !== this.props.listingIdBeingEdited) {
            if (this.props.listingIdBeingEdited === this.props.row.ledgerId) {
                const balance = this.getBalanceForSelectedYear(this.props.row)
                this.props.setRowState({
                    name: this.props.row.name,
                    source: this.props.row.source,
                    category: this.props.row.category,
                    type: this.props.row.type,
                    sumRule: this.props.row.sumRule,
                    carryForwardBalance: this.props.row.carryForwardBalance,
                    balance: balance,
                })
            }
        }
    }


    handleCancel = (row) => {
        if (row.ledgerId === '') {
            // Filter out ledgers without a ledgerId (empty string)
            const newLedgers = this.props.allLedgers.filter(ledger => ledger.ledgerId !== '');

            // Update the ledger state with the filtered list
            this.setState(prevState => {
                this.props.setLedgers(newLedgers);
            });
        }

        this.props.setListingIdBeingEdited("");
        this.props.setRowState({
            name: '',
            source: '',
            type: '',
            sumRule: '',
            carryForwardBalance: '',
            category: '',
        });

        this.props.setHideFilterBox(false)
    }


    handleConfirm = (row) => {
        let state = this.props.getRowState()
        if (!state.name) {
            return;
        }

        this.props.setListingIdBeingEdited("")
        const request = {
            ledgerId: row.ledgerId,
            name: state.name,
            type: this.props.ledgerType,
            source: state.source,
            sumRule: state.sumRule,
            carryForwardBalance: state.carryForwardBalance,
            category: state.category,
            year: this.props.year,
            currency: this.props.currency,
        };
        this.props.setRowState({
            name: '',
            source: '',
            type: '',
            sumRule: '',
            carryForwardBalance: '',
            category: '',
        })

       this.props.updateLedger(request);
    }

    handleDeleteLedger = () => {
        const data = {
            ledgerId: this.state.deleteLedgerId,
            year: this.props.year,
            currency: this.props.currency
        }
        this.props.deleteLedger(data)
        this.setState({ isDialogOpen: false, deleteLedgerId: "" });
    }

    getEditingCancelConfirm = (row) => {
        return <>
            <div style={{display:"flex", justifyContent:"left"}}>
                <Tooltip title="Delete Ledger">
                    <IconButton onClick={() => {
                        this.setState({deleteLedgerId: row.ledgerId})
                        this.handleOpen()
                    }}>
                        <RemoveCircleIcon
                            fontSize="small"
                            color="error"
                        />
                    </IconButton>
                </Tooltip>
                <Tooltip title={CANCEL_EDIT_ENTRY}>
                    <IconButton onClick={() => this.handleCancel(row)}>
                        <ClearIcon
                            fontSize='small'
                            color='error'
                        />
                    </IconButton>
                </Tooltip>
            </div>
        </>
    }


    displayNameEditingTextField = () => {
        let state = this.props.getRowState()
        return <TextField
            style={{marginLeft:"-8px",padding:"0"}}
            className="full-width-ledger"
            variant="outlined"
            name="name"
            label="Name"
            value={state.name}
            onChange={(e) => this.props.setRowState({name: e.target.value})}
            error={state.name === '' || state.name === null || state.name === undefined}
        />
    }

    displayCategoryEditingDropdown = () => {
        let state = this.props.getRowState();

        if (state.source === 'Transactions') {
            return (
              <Autocomplete

                className="full-width-ledger-category"
                multiple
                open={this.state.open}
                value={
                    Array.isArray(state.category)
                      ? state.category.map((cat) =>
                        this.props.categories.find((category) => category.name === cat) // Find category objects from names
                      ).filter(Boolean) // Ensure no undefined values
                      : [] // Fallback to an empty array if state.category is not an array
                }
                options={(this.props.categories || []).sort((a, b) => a.displayOrder - b.displayOrder)} // Sorting categories
                getOptionLabel={(option) => option.name || ''}
                autoHighlight={true}
                renderTags={(value) => {
                    if (value.length > 1) {
                        return (
                          <div style={{ width:'60px', display: 'inline-flex', flexWrap: 'nowrap', alignItems: 'center' }}>

                              <Chip
                                variant="outlined"
                                size="small"
                                style={{ borderColor: "#acd685", margin: "1px 6px 1px 0", fontSize: "12px" }}
                                label={value[0].name} // Display the first category
                              />
                              <Chip
                                variant="outlined"
                                size="small"
                                style={{ borderColor: "#acd685", margin: "1px 6px 1px 0", fontSize: "12px" }}
                                label="..." // Display "..." for additional categories
                              />

                          </div>
                        );
                    }
                    return value.map((option) => (
                      <Chip
                        variant="outlined"
                        size="small"
                        style={{ borderColor: "#acd685", margin: "1px 6px 1px 0", fontSize: "12px" }}
                        label={option.name}
                      />
                    ));
                }}
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                      <Checkbox
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.name}
                  </React.Fragment>
                )}
                onOpen={() => { this.setState({ open: true }) }}
                onClose={(event, reason) => {
                    reason === 'blur' ? this.setState({ open: false }) : this.setState({ open: true })
                }}
                name="category"
                onChange={(event, value) => {
                    this.props.setRowState({
                        category: value.map((cat) => cat?.name), // Store just the category names (strings) in the state
                    })
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Category"
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    name="category"
                  />
                )}
              />

            );
        }

        return null;
    };

    displaySourceEditingDropdown = () => {
        let state = this.props.getRowState();
        return (
            <Autocomplete
                className="full-width-ledger"
                options={SOURCE_OPTIONS}
                getOptionLabel={(option) => option}
                value={state.source || null}
                onChange={(e,value) => this.props.setRowState({ source: value })}
                renderInput={(params) =>
                    <TextField
                        {...params}
                        name="source"
                        label="Source"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                    />
                }
            />
        );
    }

    displaySumRuleEditingDropdown = () => {
        let state = this.props.getRowState();

        // Only render the dropdown if state.source is 'transactions'
        if (state.source === 'Transactions') {
            return (
                <Autocomplete
                    className="full-width-ledger"
                    options={SUM_RULE_OPTIONS}
                    getOptionLabel={(option) => option}
                    value={state.sumRule || null}
                    onChange={(e, value) => this.props.setRowState({ sumRule: value })}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            name="sumRule"
                            label="Sum Rule"
                            variant="outlined"
                            required={true}
                            error={state?.sumRule === '' || state?.sumRule === null || state?.sumRule === undefined}
                            InputLabelProps={{ shrink: true }}
                        />
                    }
                />
            );
        }

        // Return null or an empty fragment if the condition is not met
        return null;
    }

    displayCarryForwardBalanceEditingDropdown = () => {

        let state = this.props.getRowState();
            return (
              <Autocomplete
                className="full-width-ledger"
                options={CARRY_FORWARD_OPTIONS}
                getOptionLabel={(option) => option}
                value={state.carryForwardBalance || null}

                onChange={(e, value) => this.props.setRowState({ carryForwardBalance: value })}
                renderInput={(params) =>
                  <TextField
                    {...params}
                    name="carryForwardBalance"
                    label="Carry Forward Balance"
                    variant="outlined"
                    required={true}
                    error={state.carryForwardBalance === '' || state.carryForwardBalance === null || state.carryForwardBalance === undefined}
                    InputLabelProps={{ shrink: true }}
                  />
                }
              />
            );
    }


    formatBalance = (balance) => {
        return parseFloat(balance)
            .toFixed(2)
            .toString()
            .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    }

    displayEditingRow = (row) => {
        const { anchorEl } = this.state;
        const balance = this.getBalanceForSelectedYear(this.props.row)

        return <React.Fragment>


            <TableCell style={{ width: '120px', height: '90px',  paddingLeft:"16px"
            }}>
                {this.getEditingCancelConfirm(row)}
            </TableCell>

            <TableCell>
                <div className="rowSpecLedger full-width-name">
                    {this.displayNameEditingTextField()}
                    {this.displaySourceEditingDropdown()}
                    {this.displayCategoryEditingDropdown()}
                    {this.displaySumRuleEditingDropdown()}
                    {this.displayCarryForwardBalanceEditingDropdown()}
                </div>
            </TableCell>


            <TableCell
                style={{
                    textAlign: "right",
                    padding: "5px",
                    paddingRight: "50px",
                    width: "auto"
                }}
            >
                <div style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center'
                }}>
                    <span style={{flexShrink: 0}}>$</span>
                    <span style={{
                        marginLeft: '55px',
                        minWidth: '80px',
                        textAlign: 'right'
                    }}>
                           {
                               this.formatBalance(balance)
                           }
                         </span>
                </div>
            </TableCell>


            <TableCell>
                <Tooltip title="Save Ledger" style={{marginLeft:'-8px'}}>
                    <IconButton onClick={() => this.handleConfirm(row)}>
                        <CheckCircleIcon
                            fontSize="small"
                            color='secondary'
                        />
                    </IconButton>
                </Tooltip>
            </TableCell>


        </React.Fragment>
    }

    displayNonEditingRow = (row) => {
        const { anchorEl } = this.state;
        const balance = this.getBalanceForSelectedYear(this.props.row)
        return (
          <React.Fragment>
            {this.props.isEdit ? (
              <TableCell style={{ width: '120px', height: '90px',  paddingLeft:"16px"
              }}>
                {!(
                  row.name === 'Accounts Payable' && row.type === 'Liabilities'
                  || row.name === 'Accounts Receivables' && row.type === 'Assets'
                  || row.name === 'Cost of Sales' && row.type === 'Expenses'
                ) ? (
                  <Tooltip title={EDIT_LEDGER}>
                    <IconButton
                      onClick={() => {
                        this.props.setHideFilterBox(true);
                        this.props.setListingIdBeingEdited(row.ledgerId);
                      }}
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                ):
                  <Tooltip title={"Pre-defined"}>
                      <IconButton
                        onClick={() => null}
                      >
                          <SummarizeIcon fontSize="small" />
                      </IconButton>
                  </Tooltip>

                }

              </TableCell>
            ) : (
              <TableCell style={{ width: '120px', height: '90px',    paddingLeft:"16px"
              }} >
                  { (row.name === 'Accounts Payable' && row.type === 'Liabilities'
                      || row.name === 'Accounts Receivables' && row.type === 'Assets'
                      || row.name === 'Cost of Sales' && row.type === 'Expenses') &&
                    <Tooltip title={"Pre-defined"}>
                        <IconButton
                          onClick={() => null}
                        >
                            <SummarizeIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                  }

              </TableCell>

            )}




            <TableCell style={{ margin:"0", padding:"0"}}
              onClick={() => {
                  if (!this.props.isEdit) {
                      this.props.setCurrentPage(LEDGERS_PAGES.EDIT, row);
                  }
              }}
            >

                <div
                className={`full-width-name ${
                  !this.props.isEdit ? 'ledger-name' : ''
                }`}
              >
                {row.name}
              </div>
            </TableCell>

            <TableCell
              style={{
                textAlign: 'right',
                padding: '5px',
                paddingRight: '50px',
                width: '100px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                <span style={{ flexShrink: 0 }}>$</span>
                <span
                  style={{
                    marginLeft: '55px',
                    minWidth: '80px',
                    textAlign: 'right',
                  }}
                >
                  {this.formatBalance(balance)}
                </span>
              </div>
            </TableCell>

            {this.props.isEdit ? (
              <TwoStepDelete
                rowId={row.ledgerId}
                handleDelete={(rowIdToDelete) => {
                  const data = {
                    ledgerId: row.ledgerId,
                    year: this.props.year,
                    currency: this.props.currency,
                  };

                  this.props.deleteLedgerByYear(data);

                  this.setState({
                    isDeleted: true,
                    deleteId: '',
                  });
                }}
                type={this.state.isDeleted}
                deleteId={this.state.deleteId}
                setDeleteId={(newDeleteId) =>
                  this.setState({ deleteId: newDeleteId })
                }
              />
            ) : (
              <TableCell style={{ width: '200px' }}></TableCell>
            )}
          </React.Fragment>
        );
    }


    displayListingRow = (listingIdBeingEdited) => {
        if (listingIdBeingEdited === this.props.row.ledgerId) {
            return this.displayEditingRow(this.props.row)
        } else {
            return <>
                {this.displayNonEditingRow(this.props.row)}
            </>
        }
    }

    render() {
        return (
            <React.Fragment>
                <Dialog open={this.state.isDialogOpen} onClose={this.handleClose}>
                    <DialogTitle>Confirmation</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to delete this ledger?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>

                        <div className='save-cancel-buttons' style={{marginRight:'16px'}}>
                            <Button
                                variant='contained'
                                onClick={this.handleClose}
                            >
                                {CANCEL}
                            </Button>

                            <div className='save-spacing'/>
                                <Button
                                    variant='contained'
                                    color='primary'
                                    onClick={this.handleDeleteLedger}
                                >
                                    Delete
                                </Button>
                        </div>

                    </DialogActions>
                </Dialog>
                {this.displayListingRow(this.props.listingIdBeingEdited)}
            </React.Fragment>
        )
    }
}


export default LedgerTableRow;