import React from 'react';

// Table Components
import SearchableTable from "../../../global/Search/SearchableTable";
import { ASC, DESC } from "../../../global/constants";
import CustomTableHeader from "../../../global/Table/CustomTableHeader";
import Table from "@material-ui/core/Table";
import GlobalTableHead from "../../../global/Table/GlobalTableHead";
import TableContainer from "@material-ui/core/TableContainer";
import {TableCell, TablePagination} from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";

// Icons
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";

// Constants
import {
    CARRIER_INVOICE_TEMPLATE_HEAD_CELLS,
    DEFAULT_SORT_BY_HEAD_CELL,
    DEFAULT_ROWS_PER_PAGE,
    ROWS_PER_PAGE_LABEL
} from "./constants";

// Custom Components
import EditCarrierInvoiceTemplates from "./EditCarrierInvoiceTemplates";
import { PDFDocument } from 'pdf-lib';

class CarrierInvoiceTemplateTable extends SearchableTable {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      searchText: '',
      rowsPerPage: DEFAULT_ROWS_PER_PAGE,
      order: ASC,
      orderBy: DEFAULT_SORT_BY_HEAD_CELL,
      searchValue: '', // the value in the search field (updates on change)
      isSearch: false, // true if search value is not empty, false otherwise
      searchRows: 0, // number of rows displayed while search criteria is met
      pdf: null,
      pdfWidth: null,
      pdfHeight: null,
    };
    this.onSearchValueChange = this.onSearchValueChange.bind(this);
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.pdf !== this.props.pdf && this.props.pdf) {

      try {
        let pdfData = new Uint8Array(this.props.pdf);
        const pdfDoc = await PDFDocument.load(pdfData);
        const firstPage = pdfDoc.getPage(0);
        const { width, height } = firstPage.getSize();
        const pdfBytes = await pdfDoc.save();

        this.setState({
          pdf: pdfBytes,
          pdfWidth: width,
          pdfHeight: height,
        });
      } catch (error) {
        this.setState({
          pdf: this.props.pdf,
        });
        console.error('Failed to load PDF:', error);
      }
    }
  }


  handleRequestSort = (event, property) => {
    const isAsc = this.state.orderBy === property && this.state.order === ASC;
    this.setState({ order: isAsc ? DESC : ASC, orderBy: property });
  };
  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };
  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };
  onSearchValueChange = (e) => {
    this.setState({
      isSearch: e.target.value.length > 0,
      searchValue: e.target.value,
      page: 0,
      searchText: e.target.value,
    });
  };
  getDisplayed = (rows) => {
    if (!rows) return [];
    return rows.slice(
      this.state.page * this.state.rowsPerPage,
      (this.state.page + 1) * this.state.rowsPerPage
    );
  };

  render() {
    return (
      <>
        {(this.props.editing && (
          <EditCarrierInvoiceTemplates
            updateParentFields={this.props.updateParentFields}
            pdf={{ contents: this.state.pdf }}
            fieldDefined={this.props.fieldDefined}
            widenBox={this.props.widenBox}
            showFields={this.props.showFields}
            fieldError={this.props.fieldError}
            fieldColumnError={this.props.fieldColumnError}
            updateField={this.props.updateField}
            separatorFields={this.props.separatorFields}
            fieldColumns={this.props.fieldColumns}
            separatorError={this.props.separatorError}
            fields={this.props.fields}
            entrySepStart={this.props.entrySepStart}
            entryMultiSepStart={this.props.entryMultiSepStart}
            entryTransportChargeStart={this.props.entryTransportChargeStart}
            summaryStartPage={this.props.summaryStartPage}
            summaryEndPage={this.props.summaryEndPage}
            validationType={this.props.validationType}
            validationStartText={this.props.validationStartText}
            validationEndText={this.props.validationEndText}
            entrySepText={this.props.entrySepText}
            entryMultiSepText={this.props.entryMultiSepText}
            entryTransportChargeText={this.props.entryTransportChargeText}
            scale={this.props.scale}
            checked={this.props.checked}
            summaryCheck={this.props.summaryCheck}
            includeSender={this.props.includeSender}
            changedCheck={this.props.changedCheck}
            changedSummaryCheck={this.props.changedSummaryCheck}
            changedSender={this.props.changedSender}
            dateFormats={this.props.dateFormats}
            setDateFormat={this.props.setDateFormat}
            selectedFormat={this.props.selectedFormat}
            pdfWidth={this.state.pdfWidth}
            pdfHeight={this.state.pdfHeight}
          />
        )) || (
          <>
            <CustomTableHeader
              searchText={this.state.searchText}
              onSearchValueChange={this.onSearchValueChange}
              pagination={
                <TablePagination
                  className="table-pagination"
                  labelRowsPerPage={ROWS_PER_PAGE_LABEL}
                  rowsPerPageOptions={[5, 25, 50, 100]}
                  count={
                    !this.props.data
                      ? 0
                      : this.filterBySearch(this.props.data).length
                  }
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.page}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
              }
            />
            <TableContainer>
              <Table size="medium">
                <GlobalTableHead
                  isCreatePurchaseOrderTable={false}
                  isShipmentTable={false}
                  headCells={CARRIER_INVOICE_TEMPLATE_HEAD_CELLS}
                  order={this.state.order}
                  orderBy={this.state.orderBy}
                  onRequestSort={this.handleRequestSort}
                  rowCount={!this.props.data ? 0 : this.props.data.length}
                />
                <TableBody>
                  {this.getDisplayed(
                    this.stableSort(
                      this.filterBySearch(this.props.data),
                      this.getComparator(this.state.order, this.state.orderBy)
                    )
                  ).map((template, index) => (
                    <TableRow
                      hover
                      onDoubleClick={() => {
                        this.props.updateField('carrierIndex', index);
                        this.props.handleEditTemplate(template);
                      }}
                      key={index}
                    >
                      <TableCell>
                        <IconButton
                          onClick={() => {
                            this.props.updateField('carrierIndex', index);
                            this.props.handleEditTemplate(template);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                      </TableCell>
                      <TableCell className="cellPadding">
                        {template.carrierName}
                      </TableCell>
                      <TableCell className="cellPadding">
                        {template.accountNumber}
                      </TableCell>
                      <TableCell>
                        <IconButton
                          onClick={() =>
                            this.props.deleteCarrierInvoiceTemplate(
                              template.carrierId,
                              template.carrierName
                            )
                          }
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </>
    );
  }
}

export default CarrierInvoiceTemplateTable