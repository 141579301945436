import React from "react";
import withShipment from "../../../../withShipment";
import {TextField, Paper, ClickAwayListener, Button} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import PropTypes from "prop-types";
import {setHandlingTime} from "../../../../redux/actions/settings";


class SetHandlingTimePopper extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            handlingTime: "",
        }
    }

    handleClose = () => {
        this.props.handleClosePopper()
    }

    handleSave = () => {
        const data = {
            skus: this.props.skus,
            handlingTime: this.state.handlingTime,
        }
        this.props.setHandlingTime(data)
        this.props.handlePopperClose()
    }

    render() {
        return (
            <>
                <ClickAwayListener onClickAway={this.handleClose}>
                    <Paper className="import-popper-container">
                       <div className="import-popper-item">
                           <TextField
                               variant="outlined"
                               name="handlingTime"
                               value={this.state.handlingTime}
                               onChange={(e)=>{
                                   this.setState({
                                       handlingTime: e.target.value
                                   })
                               }}
                               label="Handling Time"
                               style={{ width: "inherit" }}
                           />
                       </div>
                       <div className="supplier-payment-popper-buttons">
                           <Button variant='contained' onClick={this.handleClose} style={{width: '100px'}}>Close</Button>
                           <Button variant='contained' onClick={this.handleSave} color="primary" style={{width: '100px'}}>Save</Button>
                       </div>
                    </Paper>
                </ClickAwayListener>
            </>
        )
    }
}

const actionCreators = {
    setHandlingTime
}

SetHandlingTimePopper.propTypes = {

}

SetHandlingTimePopper.defaultProps = {

}

export default withShipment({
    actionCreators
}, SetHandlingTimePopper);