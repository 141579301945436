import React from "react";

// material components
import { Button, TableRow, TextField } from "@material-ui/core";
import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Collapse from "@material-ui/core/Collapse";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { KeyboardDatePicker } from "@material-ui/pickers";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";


// styling
import './ExpandedCustomerPurchaseOrder.css';
import "react-datepicker/dist/react-datepicker.css";
import PropTypes from "prop-types";
import CreateCustomerPurchaseOrderTable from "./CreateCustomerPurchaseOrderTable";
import { START_INDEX } from "../../global/constants";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import DescriptionIcon from '@material-ui/icons/Description';
import MoveToInboxIcon from "@material-ui/icons/MoveToInbox";
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import SpeakerNotesIcon from "@material-ui/icons/SpeakerNotes";
import DeleteIcon from "@material-ui/icons/Delete";

class ExpandedCustomerPurchaseOrder extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showDropdown: props.showDropdown,
            poNumber: props.purchaseOrder.poNumber,
            orderItems: props.purchaseOrder.orderItems,
            localQtyReceived: new Array(props.purchaseOrder.orderItems.length).fill("0"),
            openDelete: false,
            entryToDelete: '',
            dateChanged: false
        };
        this.emptyArray = new Array(this.props.purchaseOrder.orderItems.length).fill("0");
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.purchaseOrder.orderItems !== this.state.orderItems) {
            this.setState({
                orderItems: this.props.purchaseOrder.orderItems
            });
        }
        if (prevProps.showDropdown !== this.props.showDropdown) {
            this.setState({
                showDropdown: this.props.showDropdown
            });
        }
    }

    handleDateChange = (date, index) => {
        let entries = this.state.orderItems;
        entries[index].expectedDate = date;
        this.setState({
            //...state,
            orderItems: entries,
            dateChanged: true
        });
    }


    handleAmountChange = (e, index) => {
        let newVal = e.target.value;

        if (newVal === '')
            newVal = "0"

        let localNewReceived = this.state.localQtyReceived
        localNewReceived[index] = newVal
        this.setState({
            localQtyReceived: localNewReceived,
        });
    }

    dummyChange = (e, index) => {
        let local = this.state.localQtyReceived;
        local[index] = e.target.value;
        this.setState({
            localQtyReceived: local
        });
    }

    addStringInts = (one, two) => {
        return (parseInt(one) + parseInt(two)).toString();
    }

    handlePreview = () => {
        this.props.fetchCreatedPurchaseOrderPreview(this.props.purchaseOrder.poNumber)
    }
    formatDate = (date) => {
        return date.substring(5, 7) + "/" + date.substring(8, 10) + "/" + date.substring(0, 4)
    }

    render() {
        return (
            <>
                <TableRow hover onDoubleClick={() => {
                    this.props.handleEditPo(this.props.purchaseOrder, this.props.fetchCreatedPurchaseOrderPreview);
                }}>
                    <TableCell>
                        <IconButton onClick={() => {
                            this.props.handleEditPo(this.props.purchaseOrder, this.props.fetchCreatedPurchaseOrderPreview);
                        }}>
                            <ImportContactsIcon />
                        </IconButton>
                    </TableCell>


                    <TableCell className={this.state.showDropdown ? "cellPadding" : "cellPadding"}>
                        {this.props.purchaseOrder.poNumber}
                    </TableCell>
                    <TableCell className={this.state.showDropdown ? "cellPadding" : "cellPadding"}>
                        {this.props.purchaseOrder.customer?.[0]?.name}
                    </TableCell>
                    <TableCell className={this.state.showDropdown ? "cellPadding" : "cellPadding"}>
                        {this.props.purchaseOrder.status.charAt(0).toUpperCase() + this.props.purchaseOrder.status.slice(1)}
                    </TableCell>
                    <TableCell className={this.state.showDropdown ? "cellPadding" : "cellPadding"}>
                        {this.formatDate(this.props.purchaseOrder.createDate)}
                    </TableCell>
                    <TableCell className={this.state.showDropdown ? "cellPadding" : "cellPadding"}>
                        {this.formatDate(this.props.purchaseOrder.expectedDate)}
                    </TableCell>
                    {this.props.purchaseOrder.receiverNotes !== "" && this.props.purchaseOrder.receiverNotes !== null && this.props.purchaseOrder.receiverNotes !== undefined ?
                        <TableCell>
                            <IconButton>
                                <SpeakerNotesIcon />
                            </IconButton>
                        </TableCell>
                        :
                        <TableCell style={{ padding: "50px" }}>
                        </TableCell>
                    }
                    <TableCell className={this.state.showDropdown ? "cellPadding" : "cellPadding"}>
                        <IconButton
                            aria-label='previous'
                            onClick={this.handlePreview}
                        >
                            <DescriptionIcon variant='outlined' fontSize='large' />
                        </IconButton>
                    </TableCell>
                    <TableCell style={{ padding: "5px", width: "205px" }}>
                        {this.props.deleteId !== this.props.purchaseOrder.poNumber ?
                            <IconButton onClick={() => this.props.updateDeleteId(this.props.purchaseOrder.poNumber)}>
                                <DeleteIcon />
                            </IconButton> : <div>
                                <Button onClick={() => this.props.updateDeleteId("")}>
                                    Cancel
                                </Button>
                                <Button onClick={this.props.handleDelete} variant="contained" startIcon={<DeleteIcon />}>
                                    Delete
                                </Button>
                            </div>
                        }
                    </TableCell>
                </TableRow>
            </>
        );
    }
}

ExpandedCustomerPurchaseOrder.propTypes = {
    purchaseOrder: PropTypes.object,
    onReceivedChange: PropTypes.func,
    updatePurchaseOrder: PropTypes.func,
    deleteInventoryReceived: PropTypes.func,
    showDropdown: PropTypes.bool
}

ExpandedCustomerPurchaseOrder.defaultProps = {
    purchaseOrder: {},
    onReceivedChange: () => {
    },
    updatePurchaseOrder: () => {
    },
    deleteInventoryReceived: () => {
    },
    showDropdown: false
}

export default ExpandedCustomerPurchaseOrder;
