import React from 'react';

import { DESC } from '../constants';

class SearchableTable extends React.Component {

    constructor(props) {
        super(props);
    }

    descendingComparator = (a, b, orderBy) => {
        let aValue = a[orderBy]
        let bValue = b[orderBy]
        const aType = typeof aValue
        const bType = typeof bValue
        if (aValue !== undefined && aType === "string") {
            if ((orderBy=="price" || orderBy=="minimumPrice"|| orderBy=="maximumPrice") && !isNaN(parseFloat(aValue))){
                aValue = parseFloat(aValue);
            }
            else{
                aValue = a[orderBy].toLowerCase()
            }
        }
        if (bValue !== undefined && bType === "string") {
            if ((orderBy=="price" || orderBy=="minimumPrice"|| orderBy=="maximumPrice") && !isNaN(parseFloat(bValue))){
                bValue = parseFloat(bValue);
            }
            else{
                bValue = b[orderBy].toLowerCase()
            }
        }
        if (aType === "boolean" && bType === "boolean") {
            if (!bValue && aValue) {
                return 1;
            } else if (bValue && !aValue) {
                return -1;
            } else {
                return 0;
            }
        }
        if ((bValue < aValue) || (!bValue && aValue)) {
            return -1;
        } else if ((bValue > aValue) || (bValue && !aValue)) {
            return 1;
        } else {
            return 0;
        }

    }

    getComparator = (order, orderBy) => {
        return order === DESC
            ? (a, b) => this.descendingComparator(a, b, orderBy)
            : (a, b) => -this.descendingComparator(a, b, orderBy);
    }

    stableSort = (array, comparator) => {
        if (!array)
            return []
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    stableSort2 = (array, comparator) => {
        if (!array)
            return []
        const stabilizedThis = array.map((el, index) => [el, index]);

    }

    /**
     * Applies filter to the table using the search field input. This method searches ALL the keys of the data
     * 
     * @param rows the data against which filters are to be applied  
     */
    filterBySearch(rows) {
        if (!rows)
            return [];
        const searchText = this.state.searchValue.toLowerCase();

        if (searchText === '') {
            return rows
        }
        // for each row in rows
        const result = rows.filter(row => {
            // traverse every key of the row as an array and match the search text against the value corresponding key
            // note: here are only searching the "filter" and not other hidden properties
            return Object.keys(row).findIndex(key => {
                if (key === "customer") return false; // Exclude the "customer" field from filtering
                return row && row[key] && (JSON.stringify(row[key]).toLowerCase().includes(searchText)||JSON.stringify(row[key]).toLowerCase().includes(searchText.replace(',','')))
            }) !== -1
        });
        return result
    }

    /**
     * Applies filter to the table using the search field input. This method searches ONLY the specified keys
     *
     * @param rows the data against which filters are to be applied
     * @param searchKeys the specific keys on which the search is to be applied
     */
    filterBySearchWithSpecificKeys(rows, searchKeys) {
        if (!rows)
            return [];
        const searchText = this.state.searchValue.toLowerCase();

        if (searchText === '') {
            return rows
        }
        // if the search keys are null/empty, we will just use the standard search instead
        if (!searchKeys || searchKeys.length === 0) {
            return this.filterBySearch(rows)
        }
        // for each row in rows
        const result = rows.filter(row => {
            // traverse every specified key of the row as an array and match the search text against the value corresponding key
            // note: here are only searching the "filter" and not other hidden properties
            return searchKeys.findIndex(key => {
                return row && row[key] && JSON.stringify(row[key]).toLowerCase().includes(searchText)
            }) !== -1
        });
        return result
    }

    // getValueIndex(row, searchText) {
    //     return Object.keys(row).findIndex(key=> {
    //         if(typeof row[key] == "object"){
    //             return this.getValueIndex(row[key], searchText)
    //         }
    //         return row[key] && row[key].toString().toLowerCase().includes(searchText)
    //     }) !== -1
    // }

    /**
     * Applies filter to the table using the search field input. This method searches ONLY the specified key
     * 
     * @param rows the data against which filters are to be applied  
     * @param searchKey the specific type of search key on which the search is to be applied (not all keys)
     */
    filterBySearchWithSpecificKey(rows, searchKey) {
        const searchText = this.state.searchValue.toLowerCase();

        if (!rows)
            return [];



        // for each row in rows
        return rows.filter(row => {
            // traverse every key of the row as an array and match the search text against the value corresponding key
            // note: here are only searching the "filter" and not other hidden properties
            return Object.keys(row).some(key => row[searchKey].toLowerCase().includes(searchText));
        });
    }

    /**
     * Applies filter to the table using the search field input. This is a custom search for rules
     *
     * @param rows the data against which filters are to be applied
     */
    filterRules(rows) {
        if (!rows)
            return [];
        const searchText = this.state.searchValue.toLowerCase();

        if (searchText === '') {
            return rows
        }
        // for each row in rows
        const result = rows.filter(row => {
            return Object.values(row).findIndex(value => {
                return this.checkValueObject(value, searchText)
            }) !== -1
        });
        return result
    }

    checkValueObject(value, searchText) {
        if (typeof (value) === "object" && value !== null && value !== undefined) {
            return Object.values(value).findIndex(nextValue => {
                return this.checkValueObject(nextValue, searchText)
            }) !== -1
        } else {
            return JSON.stringify(value).toLowerCase().includes(searchText)

        }
    }

}

export default SearchableTable