import React from 'react';

// Material UI Components
import FormControl from "@material-ui/core/FormControl";
import {InputLabel, MenuItem, Select} from "@material-ui/core";
import Button from "@material-ui/core/Button";

// Redux
import { listCarriersWithoutTemplate } from "../../../../redux/actions/invoices";
import { carriersWithoutTemplateSelector } from "../../../../redux/selectors/invoices";
import PropTypes from "prop-types";

// Custom Components
import withShipment from "../../../../withShipment";
import EditCarrierInvoiceTemplates from "./EditCarrierInvoiceTemplates";
import { PDFDocument } from 'pdf-lib';


class CreateCarrierInvoiceTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pdfWidth: this.props.pdfWidth || null,
            pdfHeight: this.props.pdfHeight ||null,
        }

    }

    componentDidMount() {
        this.props.listCarriersWithoutTemplate()
    }


    changeTemplate = async (e) => {
        if (e.target.files.length === 0) return;
        let file = e.target.files[0];

        const fileReader = new FileReader();
        fileReader.onload = async (e) => {
            const arrayBuffer = e.target.result;

            // Load the existing PDF
            const pdfDoc = await PDFDocument.load(arrayBuffer);


            // Get the first page and its dimensions
            const firstPage = pdfDoc.getPage(0);
            const { width, height } = firstPage.getSize();
            this.setState({ pdfWidth: width, pdfHeight: height });

            // Serialize the PDF document without resizing
            const pdfBytes = await pdfDoc.save();

            // Update the template with the original PDF
            this.props.updateField("template", {
                name: file.name,
                contents: new Uint8Array(pdfBytes),
            });
            this.props.updateField("templateCopy", "none");
        };

        fileReader.readAsArrayBuffer(file);
    };


    copyTemplate = (e) => {
        document.getElementById("btn-upload").value = null
        this.props.updateField("template", { name: "", contents: [] })
        this.props.updateField("templateCopy", e.target.value)
    }

    render() {
        return (<>
            {this.props.editing &&
            <EditCarrierInvoiceTemplates
                updateParentFields={this.props.updateParentFields}
                pdf={this.props.template}
                fieldDefined={this.props.fieldDefined}
                widenBox={this.props.widenBox}
                showFields={this.props.showFields}
                fieldError={this.props.fieldError}
                fieldColumnError={this.props.fieldColumnError}
                updateField={this.props.updateField}
                separatorFields={this.props.separatorFields}
                fieldColumns={this.props.fieldColumns}
                separatorError={this.props.separatorError}
                entrySepStart={this.props.entrySepStart}
                entryMultiSepStart={this.props.entryMultiSepStart}
                entryTransportChargeStart={this.props.entryTransportChargeStart}
                summaryStartPage={this.props.summaryStartPage}
                summaryEndPage={this.props.summaryEndPage}
                validationType={this.props.validationType}
                validationStartText={this.props.validationStartText}
                validationEndText={this.props.validationEndText}
                scale={this.props.scale}
                checked={this.props.checked}
                summaryCheck={this.props.summaryCheck}
                includeSender={this.props.includeSender}
                changedCheck={this.props.changedCheck}
                changedSummaryCheck={this.props.changedSummaryCheck}
                changedSender={this.props.changedSender}
                dateFormats={this.props.dateFormats}
                setDateFormat={this.props.setDateFormat}
                selectedFormat={this.props.selectedFormat}
                pdfWidth={this.state.pdfWidth}
                pdfHeight={this.state.pdfHeight}
            /> ||
                <div style={{padding: "100px", display: "flex", flexDirection: "column", height: "30vh", justifyContent: "space-between", textAlign: "center"}}>
                <FormControl variant="outlined" style={{width: "100%", textAlign: "left"}}>
                    <InputLabel id="CarrierSelect">Carrier</InputLabel>
                    <Select
                        label="Carrier"
                        labelId="CarrierSelect"
                        value={this.props.selectedCarrier}
                        style={{width: "100%"}}
                        onChange={(e) => this.props.updateField("selectedCarrier", e.target.value)}
                    >
                        <MenuItem style={{maxWidth: 1500, overflow: "auto"}} value="none">&nbsp;</MenuItem>
                        {this.props.carriersWithoutTemplate.map((carrier, index) => (
                            <MenuItem style={{maxWidth: 1450, overflow: "auto"}} key={index} value={carrier.carrierId}>{carrier.name + ", " + carrier.accountNumber}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <div>
                    <label htmlFor={"btn-upload"}>
                        <input
                            id="btn-upload"
                            name="btn-upload"
                            style={{ display: "none" }}
                            type="file"
                            accept="application/pdf"
                            onChange={this.changeTemplate}
                        />
                        <Button className="btn-choose" variant="contained" color="primary" component="span">
                            Choose Template File
                        </Button>
                        <div style={{paddingLeft: "5px", display: "inline-block"}}>
                            {this.props.template.contents.length > 0 && this.props.template.name}
                        </div>
                    </label>
                </div>
            </div>}
        </>)
    }
}

const mapStateToProps = (state) => ({
    carriersWithoutTemplate: carriersWithoutTemplateSelector(state)
})

const actionCreators = {
    listCarriersWithoutTemplate
}

CreateCarrierInvoiceTemplate.propTypes = {
    carriersWithoutTemplate: PropTypes.array
}

CreateCarrierInvoiceTemplate.defaultProps = {
    carriersWithoutTemplate: []
}

export default withShipment({
    mapStateToProps,
    actionCreators
}, CreateCarrierInvoiceTemplate);