export const STATEMENT_TAXES = "Statement of Taxes"
export const SETTINGS = "Statement of Taxes Template"
export const SAVE = "Save"

export const STATEMENT_TAXES_SUMMARY_FIELDS = [
    {id: 'category', label: 'Description', align: "center"},
    {id: 'totalSubtotal', label: 'Subtotal', align: "center"},
    {id: 'total', label: 'Total', align: "center"},
    {id: 'totalTax', label: 'Tax ', align: "center"},
]

export const STATEMENT_TAXES_PAGES = {
    TABLE: "table",
    SETTINGS: "settings",
}

export const SUM_CATEGORY_OPTIONS = [
    "Sum of Subtotal",
    "Sum of Total",
    "Sum of Taxes",
    "Sum of Net Tax Received"
];
export const TYPE_OPTIONS = [
    "Paid",
    "Received"
];

export const SOURCE_OPTIONS_TAXES = [
    "Transactions",
    "Marketplace Summary",
    "Shipping Summary",
    "Purchase Summary",
];

// tooltip names
export const BACK = "Back to Menu"

export const SHIPPING_SUMMARY_OPTIONS = ["Standard Carrier", "LTL/FTL Carrier"];
export const PURCHASE_SUMMARY_OPTIONS = ["Supplier"];

export const MARKETPLACE_OPTIONS = ["Amazon.ca","Amazon.com", "Walmart.ca", "Walmart.com", "Ebay.ca", "Exponet.ca", "Exponet.us", "Customers"];