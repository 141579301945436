import React, {useMemo} from "react";
import TopToolbar from "../../global/subcomponents/topToolbar/TopToolbar";
import {
  BACK, MARKETPLACE_OPTIONS, PURCHASE_SUMMARY_OPTIONS,
  SAVE,
  SETTINGS, SHIPPING_OPTIONS, SHIPPING_SUMMARY_OPTIONS,
  SOURCE_OPTIONS_TAXES,
  STATEMENT_TAXES_PAGES,
  SUM_CATEGORY_OPTIONS,
  TYPE_OPTIONS,
} from './constants';
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import SaveIcon from "@material-ui/icons/Save";
import { Chip, IconButton, Menu, MenuItem } from '@mui/material';
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Checkbox, TextField } from '@material-ui/core';
import AddCircleIcon from "@material-ui/icons/AddCircle";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MenuIcon from '@mui/icons-material/Menu';
import RefreshSharpIcon from '@mui/icons-material/RefreshSharp';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { SOURCE_OPTIONS, SUM_RULE_OPTIONS } from '../ledger/constants';

class StatementTemplates extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            templates: this.props.templates || [],
            anchorEl: null,
            activeIndex: null,
            draggedIndex: null,

        };
    }

    componentDidMount() {

        if (this.props.templates && this.props.templates.length > 0) {
            this.setState({ templates: [...this.props.templates] });
        }

    }

    shouldComponentUpdate(nextProps, nextState) {
        return (
            nextProps.templates !== this.props.templates ||
            nextState.templates !== this.state.templates ||
            nextState.anchorEl !== this.state.anchorEl ||
            nextState.activeIndex !== this.state.activeIndex ||
            nextState.draggedIndex !== this.state.draggedIndex ||
            nextState.menuItems !== this.state.menuItems
        );
    }


    onDragStart = (index) => {
        this.setState({ draggedIndex: index });
    };

    onDragOver = (index) => {
        const { draggedIndex, templates } = this.state;
        if (draggedIndex === index) return;

        const updatedTemplates = [...templates];
        const [draggedItem] = updatedTemplates.splice(draggedIndex, 1);
        updatedTemplates.splice(index, 0, draggedItem);

        this.setState({ templates: updatedTemplates, draggedIndex: index });
    };

    onDragEnd = () => {
        this.setState({ draggedIndex: null });
    };


    getContextualMenuItems = () => [
        {
            title: BACK,
            icon: <KeyboardBackspaceIcon />,
            onClick: () => { this.props.setCurrentPage(STATEMENT_TAXES_PAGES.TABLE, {}); },
        },
        {
            title: SAVE,
            icon: <SaveIcon />,
            onClick: this.handleSave,
            disabled: this.isSaveDisabled(),
        }
    ];

    handleSave = () => {
        this.props.handleSave(this.state.templates);
    };

    isSaveDisabled = () => {
        return this.state.templates.some(template =>
            !template.description || !template.source || !template.sumRule
        );
    };

    handleTemplateChange = (e, index) => {
        const { name, value } = e.target;
        this.setState(prevState => {
            const updatedTemplates = [...prevState.templates];
            updatedTemplates[index] = {
                ...updatedTemplates[index],
                [name]: value,
            };

            if (name === 'sumRule' && !value) {
                updatedTemplates[index].category = '';
            }

            return { templates: updatedTemplates };
        });
    };

    removeTemplateRow = (index) => {
        this.setState(prevState => {
            const updatedTemplates = prevState.templates.filter((_, curIndex) => curIndex !== index);
            return { templates: updatedTemplates };
        });
    };

    refreshCategory = () => {
        const { transactions } = this.props;
        const { templates } = this.state;

        // Extract unique categories from transactions
        const categories = [...new Set(transactions.map(transaction => transaction.category))];

        // Create new templates for the unique categories
        const newTemplates = categories
            .filter(category => !templates.some(template => template.category === category || template.description === category))
            .map(category => ({
                description: category,
                type: "",
                sumRule: "",
                category: [],
                source: "",
                marketplace: [],
                shippingSummary: [],
                purchaseSummary: []
            }));

        // Update the state with the new templates
        const updatedTemplates = [...templates, ...newTemplates];

        this.setState({ templates: updatedTemplates });
    };


    addTemplateRow = () => {
        this.setState(prevState => {
            const updatedTemplates = [...prevState.templates, {
                description: "",
                source: "",
                category: [],
                sumRule: "",
                type: "",
                marketplace: [],
                shippingSummary: [],
                purchaseSummary: []
            }];
            return { templates: updatedTemplates };
        });
    };



    render() {
        const { templates, anchorEl, draggedIndex  } = this.state;
        const { categories } = this.props;

        return (
            <React.Fragment>
                <TopToolbar
                    pageName={SETTINGS}
                    menuItems={this.getContextualMenuItems()}
                />

                <div style={{ display: "flex", justifyContent: "left", marginTop: "16px",marginLeft: "24px" ,marginRight: "24px"  }}>
                    <div className="transaction-form-section">
                        {templates.map((template, index) => (
                            <TemplateRow
                                key={index}
                                index={index}
                                template={template}
                                draggedIndex={draggedIndex}
                                categories={categories}
                                onDragStart={this.onDragStart}
                                onDragOver={this.onDragOver}
                                onDragEnd={this.onDragEnd}
                                handleTemplateChange={this.handleTemplateChange}
                                removeTemplateRow={this.removeTemplateRow}
                                anchorEl={anchorEl}
                            />
                        ))}
                        <div style={{ marginTop: '30px' }}>
                            {/*<IconButton onClick={this.refreshCategory}>*/}
                            {/*    <RefreshSharpIcon fontSize="small" color='black' />*/}
                            {/*</IconButton>*/}
                            <IconButton onClick={this.addTemplateRow}>
                                <AddCircleIcon fontSize="small" color='secondary' />
                            </IconButton>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const TemplateRow = React.memo(({
                                    index, template, draggedIndex, categories, onDragStart, onDragOver, onDragEnd,
                                    handleTemplateChange, removeTemplateRow,
                                }) => {


    const displayNameEditingTextField = () => {
        return <TextField
          style={{marginLeft:"-8px",padding:"0"}}
          className="full-width-source-statement"
          variant="outlined"
          name="description"
          label="Description"
          value={template.description || ""}
          onChange={(e) => handleTemplateChange({ target: { name: "description", value: e.target.value } }, index)}
          required
          error={!template.description}
        />
    }

    const displayCategoryEditingDropdown = () => {
        if (template.source === 'Transactions') {
            return (
              <Autocomplete
                className="full-width-source-statement"
                multiple
                value={
                    Array.isArray(template.category)
                      ? template.category.map((cat) =>
                        categories.find((category) => category.name === cat) // Find category objects from names
                      ).filter(Boolean) // Ensure no undefined values
                      : [] // Fallback to an empty array if state.category is not an array
                }
                options={(categories || []).sort((a, b) => a.displayOrder - b.displayOrder)} // Sorting categories
                getOptionLabel={(option) => option.name || ''}
                autoHighlight={true}
                renderTags={(value) => {
                    if (value.length > 1) {
                        return (
                          <div style={{ width:'100%', display: 'inline-flex', flexWrap: 'nowrap', alignItems: 'center' }}>

                              {/*<Chip*/}
                              {/*  variant="outlined"*/}
                              {/*  size="small"*/}
                              {/*  style={{ width:'0px',borderColor: "#acd685", margin: "1px 6px 1px 0", fontSize: "12px" }}*/}
                              {/*  label={value[0].name} // Display the first category*/}
                              {/*/>*/}
                            <Chip
                              variant="outlined"
                              size="small"
                              style={{
                                width: 'auto',
                                maxWidth: '100px', // Set a maximum width for the Chip
                                borderColor: '#acd685',
                                margin: '1px 6px 1px 0',
                                fontSize: '12px',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis', // Ensure text is trimmed if it overflows
                              }}
                              label={
                                value[0] && value[0].name // Check if value[0] and name exist
                                  ? value[0].name.length > 22
                                    ? `${value[0].name.slice(0, 19)}...` // Truncate text if it's longer than 10 characters
                                    : value[0].name
                                  : '' // Fallback to empty string if value[0].name is undefined
                              }
                            />
                              <Chip
                                variant="outlined"
                                size="small"
                                style={{ borderColor: "#acd685", margin: "1px 6px 1px 0", fontSize: "12px" }}
                                label="..." // Display "..." for additional categories
                              />

                          </div>
                        );
                    }
                    return value.map((option) => (
                      <Chip
                        variant="outlined"
                        size="small"
                        style={{ borderColor: "#acd685", margin: "1px 6px 1px 0", fontSize: "12px" }}
                        label={
                          option && option.name // Check if value[0] and name exist
                            ? option.name.length > 22
                              ? `${option.name.slice(0, 19)}...`
                              : option.name
                            : ''
                        }
                      />
                    ));
                }}
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                      <Checkbox
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.name}
                  </React.Fragment>
                )}
                name="category"

                onChange={(e, value) =>
                  handleTemplateChange({
                    target: { name: "category", value: value.map((cat) => cat?.name) },
                }, index)}


                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Category"
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    name="category"
                  />
                )}
              />

            );
        }
        return null;
    };

  const displayShippingSummaryEditingDropdown = () => {
    if (template.source === 'Shipping Summary') {
      return (
        <Autocomplete

          className="full-width-source-statement"
          multiple

          value={template.shippingSummary || []}
          options={SHIPPING_SUMMARY_OPTIONS}
          getOptionLabel={(option) => option || ''}
          autoHighlight={true}
          renderTags={(value) => {
            if (value.length > 1) {
              return (
                <div style={{ width:'130px', display: 'inline-flex', flexWrap: 'nowrap', alignItems: 'center' }}>

                  <Chip
                    variant="outlined"
                    size="small"
                    style={{ borderColor: "#acd685", margin: "1px 6px 1px 0", fontSize: "12px" }}
                    label={value[0]} // Display the first category
                  />
                  <Chip
                    variant="outlined"
                    size="small"
                    style={{ borderColor: "#acd685", margin: "1px 6px 1px 0", fontSize: "12px" }}
                    label="..." // Display "..." for additional categories
                  />

                </div>
              );
            }
            return value.map((option) => (
              <Chip
                variant="outlined"
                size="small"
                style={{ borderColor: "#acd685", margin: "1px 6px 1px 0", fontSize: "12px" }}
                label={option}
              />
            ));
          }}
          renderOption={(option, { selected }) => (
            <React.Fragment>
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option}
            </React.Fragment>
          )}
          name="shippingSummary"

          onChange={(e, value) =>
            handleTemplateChange({
              target: { name: "shippingSummary", value },
            }, index)}


          renderInput={(params) => (
            <TextField
              {...params}
              label="Shipping Summary"
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              name="shippingSummary"
            />
          )}
        />

      );
    }

    return null;
  };

  const displayPurchaseSummaryEditingDropdown = () => {
    if (template.source === 'Purchase Summary') {
      return (
        <Autocomplete

          className="full-width-source-statement"
          multiple

          value={template.purchaseSummary || []}
          options={PURCHASE_SUMMARY_OPTIONS}
          getOptionLabel={(option) => option || ''}
          autoHighlight={true}
          renderTags={(value) => {
            if (value.length > 1) {
              return (
                <div style={{ width:'130px', display: 'inline-flex', flexWrap: 'nowrap', alignItems: 'center' }}>

                  <Chip
                    variant="outlined"
                    size="small"
                    style={{ borderColor: "#acd685", margin: "1px 6px 1px 0", fontSize: "12px" }}
                    label={value[0]} // Display the first category
                  />
                  <Chip
                    variant="outlined"
                    size="small"
                    style={{ borderColor: "#acd685", margin: "1px 6px 1px 0", fontSize: "12px" }}
                    label="..." // Display "..." for additional categories
                  />

                </div>
              );
            }
            return value.map((option) => (
              <Chip
                variant="outlined"
                size="small"
                style={{ borderColor: "#acd685", margin: "1px 6px 1px 0", fontSize: "12px" }}
                label={option}
              />
            ));
          }}
          renderOption={(option, { selected }) => (
            <React.Fragment>
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option}
            </React.Fragment>
          )}
          name="purchaseSummary"

          onChange={(e, value) =>
            handleTemplateChange({
              target: { name: "purchaseSummary", value },
            }, index)}


          renderInput={(params) => (
            <TextField
              {...params}
              label="Purchase Summary"
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              name="purchaseSummary"
            />
          )}
        />

      );
    }

    return null;
  };

    const displayMarketplaceEditingDropdown = () => {
        if (template.source === 'Marketplace Summary') {
            return (
              <Autocomplete

                className="full-width-source-statement"
                multiple

                value={template.marketplace || []}
                options={MARKETPLACE_OPTIONS}
                getOptionLabel={(option) => option || ''}
                autoHighlight={true}
                renderTags={(value) => {
                    if (value.length > 1) {
                        return (
                          <div style={{ width:'130px', display: 'inline-flex', flexWrap: 'nowrap', alignItems: 'center' }}>

                              <Chip
                                variant="outlined"
                                size="small"
                                style={{ borderColor: "#acd685", margin: "1px 6px 1px 0", fontSize: "12px" }}
                                label={value[0]} // Display the first category
                              />
                              <Chip
                                variant="outlined"
                                size="small"
                                style={{ borderColor: "#acd685", margin: "1px 6px 1px 0", fontSize: "12px" }}
                                label="..." // Display "..." for additional categories
                              />

                          </div>
                        );
                    }
                    return value.map((option) => (
                      <Chip
                        variant="outlined"
                        size="small"
                        style={{ borderColor: "#acd685", margin: "1px 6px 1px 0", fontSize: "12px" }}
                        label={option}
                      />
                    ));
                }}
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                      <Checkbox
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option}
                  </React.Fragment>
                )}
                name="marketplace"

                onChange={(e, value) =>
                  handleTemplateChange({
                      target: { name: "marketplace", value },
                  }, index)}


                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Marketplace"
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    name="marketplace"
                  />
                )}
              />

            );
        }

        return null;
    };

    const displaySourceEditingDropdown = () => {

        return (
          <Autocomplete
            className="full-width-source-statement"
            options={SOURCE_OPTIONS_TAXES}
            getOptionLabel={(option) => option}
            value={template.source || null}
            onChange={(e, value) => handleTemplateChange({
                target: { name: "source", value: value } }, index)
          }
            renderInput={(params) =>
              <TextField
                {...params}
                name="source"
                label="Source"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
            }
          />
        );
    }

    const displaySumRuleEditingDropdown = () => {
        // Filter options based on template.source
        let filteredOptions = SUM_CATEGORY_OPTIONS;

        if (template.source === 'Transactions' || template.source === 'Shipping Summary' || template.source === 'Purchase Summary') {
            filteredOptions = [
              "Sum of Subtotal",
              "Sum of Total",
              "Sum of Taxes",
            ];
        } else if (template.source === 'Marketplace Summary') {
            filteredOptions = [
                "Sum of Net Tax Received"
            ];
        }

        return (
          <Autocomplete
            className="full-width-source-statement"
            options={filteredOptions}  // Use the filtered options
            getOptionLabel={(option) => option}
            value={template.sumRule || null}
            onChange={(e, value) =>
              handleTemplateChange({ target: { name: "sumRule", value: value } }, index)
            }
            renderInput={(params) =>
              <TextField
                {...params}
                name="sumRule"
                label="Sum Rule"
                variant="outlined"
                required={true}
                error={template?.sumRule === '' || template?.sumRule === null || template?.sumRule === undefined}
                InputLabelProps={{ shrink: true }}
              />
            }
          />
        );
    };

    const displayTypeEditingDropdown = () => {


        // if (template.source === 'Transactions') {
        return (
          <Autocomplete
            className="full-width-source-statement"
            options={TYPE_OPTIONS}
            getOptionLabel={(option) => option}
            value={template.type || null}
            onChange={(e, value) =>
              handleTemplateChange({ target: { name: "type", value: value } }, index)
            }

            renderInput={(params) =>
              <TextField
                {...params}
                name="type"
                label="Type"
                variant="outlined"
                required={true}
                error={template?.type === '' || template?.type === null || template?.type === undefined}
                InputLabelProps={{ shrink: true }}
              />
            }
          />
        );
        //   }

        // Return null or an empty fragment if the condition is not met
        // return null;
    }


    return (
      <div
        className="transaction-form-details"
        draggable
        onDragStart={() => onDragStart(index)}
        onDragOver={() => onDragOver(index)}
        onDragEnd={onDragEnd}
        style={{ background: draggedIndex === index ? "#f0f0f0" : "white" }}
      >
          <div style={{ width: '4.5%', display: "flex" }}>
              <IconButton onClick={() => removeTemplateRow(index)}>
                  <RemoveCircleIcon fontSize="small" color="error" />
              </IconButton>
          </div>


          <div className="rowSpecLedger full-width-name">
              {displayNameEditingTextField()}
              {displayTypeEditingDropdown()}
              {displaySourceEditingDropdown()}
              {displayCategoryEditingDropdown()}
              {displayMarketplaceEditingDropdown()}
              {displayShippingSummaryEditingDropdown()}
              {displayPurchaseSummaryEditingDropdown()}
              {displaySumRuleEditingDropdown()}
          </div>


      </div>
    );
});


export default StatementTemplates;



