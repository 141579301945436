import React from 'react';
import {findRecentLimboShipments, manualResolveLimboShipment} from "../../redux/actions/dashboard";
import withShipment from "../../withShipment";
import {
    limboShipmentSelector,
    resolveLimboShipmentresponseMessageSelector,
} from "../../redux/selectors/dashboard";
import {Layout} from "../../components/global/Table/TablePageHelpers/LayoutObject";
import {HEADER_CELLS, MANUAL_RESOLVE} from "./constant";
import TablePage from "../../components/global/Table/TablePage";
import {TableCell} from "@mui/material";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import PrimeIcon from "../../media/images/Amazon-Prime.png";
import CheckIcon from "@material-ui/icons/Check";
import FeedbackBar from "../../components/feedbackBar/FeedbackBar";
import SpeakerNotesIcon from "@material-ui/icons/SpeakerNotes";
import {Archive} from "@material-ui/icons";
import Label_printedIcon from "../../media/images/Label_printed.png";
import PickedIcon from "../../media/images/Picked-up.png";
import InTransitIcon from "../../media/images/In-Transit.png";
import DeliveredIcon from "../../media/images/Delivered.png";
import ExceptionIcon from "../../media/images/Exception.png";
import UnshippedIcon from "../../media/images/Unshipped.png";
import "./DashboardLimboShipments.css"


class DashboardLimboShipments extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            feedBackBarOpen: false
        }
    }

    componentDidMount() {
        this.props.findRecentLimboShipments(this.props.selectedLocationId)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ( this.props.responseMessage && prevProps.responseMessage !== this.props.responseMessage) {
            this.setState({
                feedBackBarOpen: true
            })
        }
    }

    getTableHeaders = () => {
        let headers = []
        headers.push(Layout.columnHead(null,null,null))
        HEADER_CELLS.forEach((headCell) => {
            headers.push(Layout.columnHead(headCell.label, headCell.id, "center"))
        })
        headers.push(Layout.columnHead(null,null,null))
        return headers
    }

    tableRow = (row, index) => {
        return <React.Fragment>
            <TableCell className="manualResolve">
                <Tooltip title={MANUAL_RESOLVE}>
                    <IconButton onClick={() => {this.props.manualResolveLimboShipment(row?.shipmentId)}}>
                        <CheckIcon
                            fontSize='medium'
                        />
                    </IconButton>
                </Tooltip>
            </TableCell>
            <TableCell className="col-cell-center"> {row.tags ? this.displayTags(row) : ''} </TableCell>
            <TableCell className="col-cell-center-orderId"> {row.orders ? row.orders[0].orderId : row.packages[0].reference1?.length > 0 ? row.packages[0].reference1 : "" } </TableCell>
            <TableCell className="col-cell-center"> {this.getDeliveryStatus(row)} </TableCell>
            <TableCell className="col-cell-center">
                {row.notes ?
                    <IconButton>
                        <Tooltip
                            title={row.notes}
                        >
                            <SpeakerNotesIcon/>
                        </Tooltip>
                    </IconButton>
                    : ""
                }
            </TableCell>
            <TableCell className="col-cell-center-name"> {row?.shippingAddressName} </TableCell>
            <TableCell className="col-cell-center-orderId"> {row?.shippingAddressAddressLine1} </TableCell>
            <TableCell className="col-cell-center-name"> {row?.shippingAddressCity} </TableCell>
            <TableCell className="col-cell-center"> {row?.shippingAddressStateOrRegion} </TableCell>
            <TableCell className="col-cell-center-orderId"> {this.displaySkus(row, 21)} </TableCell>
            <TableCell className="col-cell-center-name"> {row?.fromAddressCity} </TableCell>
            <TableCell className={"status"}>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'left'}}>
                    {row.rate ? <div style={{marginTop: "3", marginLeft: "2", minWidth: "85px"}}>{"$"}{parseFloat(row.rate).toFixed(2)}{" "}{row.rateCurrency} </div> : null}
                </div>
            </TableCell>
            <TableCell className="col-cell-center"> {row?.carrierName} </TableCell>
            <TableCell className="col-cell-center-primeImage"> {row?.isPrime ? <img src={PrimeIcon} style={{height: 17, width: '60px'}}/> : null} </TableCell>
            <TableCell className="col-cell-center-orderDate"> {this.formatDate(row?.orderDate)} </TableCell>
            <TableCell className="col-cell-center-name"> {this.getShipByDate(row)} </TableCell>
            <TableCell className="col-cell-center-name"> {this.getDeliverByDate(row)} </TableCell>
            <TableCell className="col-cell-center"> {row?.marketplace} </TableCell>
            <TableCell className="col-cell-center-shipMethod"> {row?.shipmentServiceLevel} </TableCell>

        </React.Fragment>
    }

    formatDate = (date) => {
        if (date != null) {
            return date.includes("T") ? date.split("T")[0]: date
        }
        return ""
    }

    getDeliveryStatus = (row) => {
        return (
            <div style={{ marginTop: 1, textAlign: 'center', justifyContent: 'center', width: '150px' }}>
                {this.getDeliveryIcon(row.shipmentStatus)}
                {!row.shipmentStatus ?
                    <div style={{ textAlign: 'center', justifyContent: 'center', color: '#005E73' }}>{"Pending"}</div>
                    : <div style={{
                        textAlign: 'center',
                        justifyContent: 'center',
                        color: '#005E73'
                    }}>{row.shipmentStatus}</div>
                }

                <div style={{
                    textAlign: 'center',
                    justifyContent: 'center',
                    color: '#005E73'
                }}>
                    {row.trackingNumber} {row.labelArchived ? <Archive /> : null}
                </div>
            </div>
        )
    }

    getDeliveryIcon = (status) => {
        switch (status) {
            case 'Scanned':
            case 'Packed':
            //Scanned and packed have the same icon has printed
            case 'Printed':
                return <img src={Label_printedIcon} className={"shipmentStatus"} />
            case 'Picked Up':
                return <img src={PickedIcon} className={"shipmentStatus"} />
            case 'In Transit':
                return <img src={InTransitIcon} className={"shipmentStatus"} />
            case 'Delivered':
                return <img src={DeliveredIcon} className={"shipmentStatus"} />
            case 'Exception':
                return <img src={ExceptionIcon} className={"shipmentStatus"} />
            default:
                return <img src={UnshippedIcon} className={"shipmentStatus"} />
        }
    }

    getShipByDate = (row) => {
        return row.orders ? row.orders.length > 0 ? this.formatDate(row.orders[0]?.latestShipDate) : '' : ''
    }

    getDeliverByDate = (row) => {
        return row.orders ? row.orders.length > 0 ? this.formatDate(row.orders[0]?.latestDeliveryDate) : '' : ''
    }

    displaySkus = (row, n) => {
        if (row.packages.length > 0) {
            if (row.packages[0].skus?.length > 1) {
                return 'Multiple'
            } else {
                if (row.packages[0].skus) {
                    if (row.packages[0].skus[0] && row.packages[0].skus[0].length > n) {
                        return row.packages[0].skus[0].substr(0, n - 1) + '...'
                    } else {
                        return row.packages[0].skus[0]
                    }
                }
            }
        }
        return ''
    }

    displayTags = (row) => {
        const tags = [...row.tags];
        if (tags?.length > 0 && tags[0].tagName) { //at least one tag
            return tags[0].tagName?.length > 10 ?
                tags[0].tagName.substr(0, 9) + '...'
                :
                tags.length > 1 ?
                    tags[0].tagName + '...'
                    :
                    tags[0].tagName
        }
        return ''
    }

    searchStrings = (row) => {

        let searchStrings = [
            row?.orders[0]?.orderId,
            row?.shippingAddressName,
            row?.shippingAddressAddressLine1,
            row?.shippingAddressCity,
            row?.shippingAddressStateOrRegion,
            row?.fromAddressCity,
            row?.carrierName,
            this.displayTags(row),
            row?.marketplace,
            row?.shipmentServiceLevel
        ]
        if (row.skus) {
            searchStrings.push(this.displaySkus(row, 21))
        }
        if (row.tags) {
            searchStrings.push(this.displayTags(row, 21))
        }
        return searchStrings
    }

    updateDashboardLocation = (location) => {
        this.props.findRecentLimboShipments(location.shipAddressId)
        this.props.updateLocationId(location)
    }

    render () {
        return (
            <>
                <FeedbackBar
                    open={this.state.feedbackBarOpen}
                    handleClose={this.closeFeedbackBar}
                    severity={"success"}
                    message={this.props.responseMessage}
                />
                <TablePage
                    tableName="Limbo Shipment Table"
                    tableRows={this.props.limboShipments}
                    tableColumnHeads={this.getTableHeaders()}
                    tableRow={this.tableRow}
                    hasSearchField
                    paginationText="Shipments per page"
                    searchStrings={this.searchStrings}
                    hasLocationField
                    updateDashboardLocation={this.updateDashboardLocation}
                />
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    limboShipments: limboShipmentSelector(state),
    responseMessage: resolveLimboShipmentresponseMessageSelector(state)
})

const actionCreators = {
    findRecentLimboShipments,
    manualResolveLimboShipment
}

export default withShipment({
    mapStateToProps,
    actionCreators
}, DashboardLimboShipments);