import {
    CARRIER_OPTIONS, CARRIER_SERVICE_OPTIONS,
    CONTAINS,
    DOES_NOT_CONTAIN,
    DOES_NOT_EQUAL, ENDS_WITH,
    EQUALS, FILTER_TYPE_BOOLEAN_1, FILTER_TYPE_BOOLEAN_2, FILTER_TYPE_NUMERIC, FILTER_TYPE_TEXT,
    IS_EMPTY, REGULAR_EXPRESSION, SHIPPING_ACCOUNT_OPTIONS, STARTS_WITH, STORE_OPTIONS
} from "../../automationrules/ConditionFilters/constants";

export const SUPPLIERS_TOOLBAR_TITLE = 'Suppliers';
export const CREATE_SUPPLIER_TITLE = 'Add a supplier';
export const BACK_TO_MENU = 'Back to Menu';

export const MANAGE_SUPPLIERS_HEAD_CELLS = [
    { id: 'name', center: true, disablePadding: false, label: 'Name'},
    { id: 'addressLine1', center: false, disablePadding: false, label: 'Address' },
    { id: 'phone', center: false, disablePadding: false, label: 'Phone Number' },
]

export const DEFAULT_ROWS_PER_PAGE = 50;
export const DEFAULT_SORT_BY_HEAD_CELL = "name"

export const ROWS_PER_PAGE_LABEL = "Suppliers per page"


export const STEPPER_HEADINGS = ["Supplier Info", "Supplier Products", "Automation Settings"]
export const STEPPER_HEADING_DESCRIPTIONS = ["Add supplier information", "Add products to this supplier", "Set up automatic invoice download"]

export const STEP_SUPPLIER_INFO_INDEX = 0
export const STEP_SUPPLIER_PRODUCTS_INDEX = 1
export const STEP_SUPPLIER_INVOICE_INDEX = 2

export const DELETE_FILTER = "Delete Filter"
export const COPY_FILTER = "Copy Filter"
export const DRAG = "Drag";
export const DELETE_ACTION = "Delete Action"
export const FILTER_TYPE_TEXT_OPTIONS = [
    EQUALS,
    CONTAINS,
];
export const HEAD_FILTER_CELLS = [
    {
        id: "filter",
        numeric: false,
        disablePadding: true,
        label: "Filter",
    },
];
export const HEAD_ACTION_CELLS = [
    {
        id: "action",
        numeric: false,
        disablePadding: true,
        label: "Action",
    },
];
export const CONDITION_FILTERS = "Condition Filters";
export const CONDITION_ACTIONS = "Condition Actions";
export const FILTERS = [
    { name: "Subject", type: FILTER_TYPE_TEXT },
    { name: "Sent By", type: FILTER_TYPE_TEXT },
    { name: "Label", type: FILTER_TYPE_TEXT },
    { name: "Contents", type: FILTER_TYPE_TEXT },
    { name: "Has Attachment", type: FILTER_TYPE_BOOLEAN_1 },
    { name: "Do Not Include", type: FILTER_TYPE_TEXT},
    { name: "From Date", type: FILTER_TYPE_TEXT},
    { name: "To Date", type: FILTER_TYPE_TEXT}
];

export const ACTION_TYPE_BOOLEAN = 1;
export const ACTION_TYPE_TEXT = 2;

export const ACTIONS = [
    { name: "Label", type: ACTION_TYPE_TEXT },
    { name: "Archive", type: ACTION_TYPE_BOOLEAN },
    { name: "Mark Read", type: ACTION_TYPE_BOOLEAN },
];

export const EXECUTE = "Manually Execute"

export const INFO_ON_CONTENT_FILTER = "This phrase will be searched within the subject and body of email."
export const INFO_ON_CUSTOM_QUERY = "Query must be in the same format that Gmail's search feature allows. For example, the query: 'subject:invoice from:companyxyz has:attachment' will check for invoices in all emails that contain 'invoice' in the subject line, are sent by a user who's name/email address contains 'companyxyz', and have an attachment."
export const INFO_ON_MANUAL_EXECUTE = "To test or manually fetch and process invoices."

export const AMOUNT = "Amount"
export const PERCENTAGE = "Percentage"
export const PRICE_ADJUSTMENT_OPTIONS = [
    AMOUNT,
    PERCENTAGE,
];
export const INCREMENT = "increase by"
export const DECREMENT = "decrease by"
export const PRICE_ADJUSTMENT_ACTION = [
    INCREMENT,
    DECREMENT
]