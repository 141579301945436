import React from "react";
import TopToolbar from "../../global/subcomponents/topToolbar/TopToolbar";
import {
    SHIPPING_SUMMARY,
    SHIPPING_SUMMARY_FIELDS,
    SHIPPING_SUMMARY_FTL_FIELDS,
    SHIPPING_SUMMARY_PAGES
} from "./constants";
import { Layout } from "../../global/Table/TablePageHelpers/LayoutObject";
import {TableCell, Chip, Select, MenuItem, FormControl, InputLabel, Box, FormControlLabel} from "@mui/material";
import TablePage from "../../global/Table/TablePage";
import './ShippingSummary.css';
import {SETTINGS} from "../transactions/constants";
import SettingsIcon from "@material-ui/icons/Settings";
import {Checkbox} from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import {HourglassEmptyTwoTone} from "@material-ui/icons";
import RefreshIcon from '@material-ui/icons/Refresh';
import Utils from '../../Utils/Utils';

class ShippingSummaryTable extends React.Component {
    constructor(props) {
        super(props)
    }


    getTableHeaders = () => {
        let headers = []
        SHIPPING_SUMMARY_FIELDS.forEach((headCell) => {
            headers.push(Layout.columnHead(headCell.label, headCell.id, "center"))
        })
        return headers
    }

    getTableHeadersFTL = () => {
        let headers = []
        SHIPPING_SUMMARY_FTL_FIELDS.forEach((headCell) => {
            headers.push(Layout.columnHead(headCell.label, headCell.id, "center"))
        })
        return headers
    }

     formatDollar = (amount) => {
        if (amount === null || amount === undefined) {
            amount = 0;
        }

        const amountStr = parseFloat(amount).toFixed(2).toString();

        // Check if amountStr is NaN after parsing
        if (isNaN(amountStr)) {
            return '';
        } else {
            return (
                <div style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    gap: '3px',
                    marginRight: '15px'
                }}>
                    <span style={{ flexShrink: 0, marginLeft: '16px', marginRight: '16px' }}>$</span>
                    <span style={{ marginLeft: 'auto' }}>
                    {amountStr.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </span>
                </div>
            );
        }
    };


    tableRow = (carriers) => {
        const isLastItem = this.props.shippingSummary[this.props.shippingSummary.length - 1] === carriers
        const boldStyle = isLastItem ? { fontWeight: 'bold' } : {}

        if (this.props.selectedCurrency === "Consolidated CAD" &&
            this.props.settings.averageRates &&
            this.props.settings.averageRates.length > 0) {
            const currentYearRate = this.props.settings.averageRates.find(setting => setting.year === this.props.selectedYear.toString()) || { rate: 0 };

            return (
                <React.Fragment>
                    <TableCell style={{ ...boldStyle, width: '20%',marginLeft:'16px', paddingLeft:'16px' }}>

                        {isLastItem === true ? (
                            carriers?.carrierName
                        ) : (
                            this.props.consolidateByCarrier ? (
                                carriers?.carrierName
                            ) : (
                                <>
                                    {carriers?.carrierName}
                                    <br/>
                                    {carriers?.accountNumber}
                                    <br/>
                                    {Array.isArray(carriers?.location)
                                        ? carriers.location.join(', ')
                                        : carriers?.location}
                                </>
                            )
                        )}


                    </TableCell>
                    <TableCell className="custom-cell" style={boldStyle}>
                        {carriers.currencyCode[0] === "USD"
                            ? this.formatDollar(parseFloat(carriers?.PY_SUBTOTAL_PAYABLE) * parseFloat(currentYearRate.rate))
                            : this.formatDollar(parseFloat(carriers?.PY_SUBTOTAL_PAYABLE))}
                    </TableCell>
                    <TableCell className="custom-cell" style={boldStyle}>
                        {carriers.currencyCode[0] === "USD"
                            ? this.formatDollar(parseFloat(carriers?.PY_TAX_PAYABLE) * parseFloat(currentYearRate.rate))
                            : this.formatDollar(parseFloat(carriers?.PY_TAX_PAYABLE))}
                    </TableCell>
                    <TableCell className="custom-cell" style={boldStyle}>
                        {carriers.currencyCode[0] === "USD"
                            ? this.formatDollar(parseFloat(carriers?.CY_SUBTOTAL) * parseFloat(currentYearRate.rate))
                            : this.formatDollar(parseFloat(carriers?.CY_SUBTOTAL))}
                    </TableCell>
                    <TableCell className="custom-cell" style={boldStyle}>
                        {carriers.currencyCode[0] === "USD"
                            ? this.formatDollar(parseFloat(carriers?.CY_TAX) * parseFloat(currentYearRate.rate))
                            : this.formatDollar(parseFloat(carriers?.CY_TAX))}
                    </TableCell>
                  <TableCell className="custom-cell" style={boldStyle}>
                    {carriers.currencyCode[0] === "USD"
                      ? this.formatDollar(parseFloat(carriers?.CY_TOTAL) * parseFloat(currentYearRate.rate))
                      : this.formatDollar(parseFloat(carriers?.CY_TOTAL))}
                  </TableCell>
                    <TableCell className="custom-cell" style={boldStyle}>
                        {carriers.currencyCode[0] === "USD"
                            ? this.formatDollar(parseFloat(carriers?.CY_SUBTOTAL_PAYABLE) * parseFloat(currentYearRate.rate))
                            : this.formatDollar(parseFloat(carriers?.CY_SUBTOTAL_PAYABLE))}
                    </TableCell>
                    <TableCell className="custom-cell" style={boldStyle}>
                        {carriers.currencyCode[0] === "USD"
                            ? this.formatDollar(parseFloat(carriers?.CY_TAX_PAYABLE) * parseFloat(currentYearRate.rate))
                            : this.formatDollar(parseFloat(carriers?.CY_TAX_PAYABLE))}
                    </TableCell>
                    <TableCell className="custom-cell" style={boldStyle}>
                        {carriers.currencyCode[0] === "USD"
                            ? this.formatDollar(parseFloat(carriers?.CY_TOTAL_PAYABLE) * parseFloat(currentYearRate.rate))
                            : this.formatDollar(parseFloat(carriers?.CY_TOTAL_PAYABLE))}
                    </TableCell>
                </React.Fragment>
            );

        } else {
            return (
                <React.Fragment>
                    <TableCell style={{ ...boldStyle, width: '20%',marginLeft:'16px', paddingLeft:'16px' }}>

                        {isLastItem === true ? (
                            carriers?.carrierName
                        ) : (
                            this.props.consolidateByCarrier ? (
                                carriers?.carrierName
                            ) : (
                                <>
                                    {carriers?.carrierName}
                                    <br/>
                                    {carriers?.accountNumber}
                                    <br/>
                                    {Array.isArray(carriers?.location)
                                        ? carriers.location.join(', ')
                                        : carriers?.location}
                                </>
                            )
                        )}

                    </TableCell>
                    <TableCell className="custom-cell" style={boldStyle}>{this.formatDollar(carriers?.PY_SUBTOTAL_PAYABLE)}</TableCell>
                    <TableCell className="custom-cell" style={boldStyle}>{this.formatDollar(carriers?.PY_TAX_PAYABLE)}</TableCell>
                    <TableCell className="custom-cell" style={boldStyle}>{this.formatDollar(carriers?.CY_SUBTOTAL)}</TableCell>
                    <TableCell className="custom-cell" style={boldStyle}>{this.formatDollar(carriers?.CY_TAX)}</TableCell>
                    <TableCell className="custom-cell" style={boldStyle}>{this.formatDollar(carriers?.CY_TOTAL)}</TableCell>
                    <TableCell className="custom-cell" style={boldStyle}>{this.formatDollar(carriers?.CY_SUBTOTAL_PAYABLE)}</TableCell>
                    <TableCell className="custom-cell" style={boldStyle}>{this.formatDollar(carriers?.CY_TAX_PAYABLE)}</TableCell>
                    <TableCell className="custom-cell" style={boldStyle}>{this.formatDollar(carriers?.CY_TOTAL_PAYABLE)}</TableCell>
                </React.Fragment>
            );
        }
    }


    searchStrings = (carriers) => {
        return [carriers.carrierName, carriers.accountNumber, carriers.location]
    }

    searchStringsFTL = (ftl) => {
        return [ftl.carrierName, ftl.accountNumber, ftl.location]
    }


    tableRowFTL = (ftl) => {
        const isLastItem = this.props.shippingSummary[this.props.shippingSummary.length - 1] === ftl;
        const boldStyle = isLastItem ? { fontWeight: 'bold' } : {}; // Apply bold style if it's the last item


      if (this.props.selectedCurrency === "Consolidated CAD" &&
        this.props.settings.averageRates &&
        this.props.settings.averageRates.length > 0) {
        const currentYearRate = this.props.settings.averageRates.find(setting => setting.year === this.props.selectedYear.toString()) || { rate: 0 };

        return (
          <React.Fragment>
            <TableCell style={{ ...boldStyle, width: '20%',marginLeft:'16px', paddingLeft:'16px' }}>
              {ftl?.carrierName}
            </TableCell>
            <TableCell className="custom-cell" style={boldStyle}>
              {ftl.currencyCode[0] === "USD"
                ? this.formatDollar(parseFloat(ftl?.PY_SUBTOTAL_PAYABLE) * parseFloat(currentYearRate.rate))
                : this.formatDollar(parseFloat(ftl?.PY_SUBTOTAL_PAYABLE))}
            </TableCell>
            <TableCell className="custom-cell" style={boldStyle}>
              {ftl.currencyCode[0] === "USD"
                ? this.formatDollar(parseFloat(ftl?.PY_TAX_PAYABLE) * parseFloat(currentYearRate.rate))
                : this.formatDollar(parseFloat(ftl?.PY_TAX_PAYABLE))}
            </TableCell>
            <TableCell className="custom-cell" style={boldStyle}>
              {ftl.currencyCode[0] === "USD"
                ? this.formatDollar(parseFloat(ftl?.CY_SUBTOTAL) * parseFloat(currentYearRate.rate))
                : this.formatDollar(parseFloat(ftl?.CY_SUBTOTAL))}
            </TableCell>
            <TableCell className="custom-cell" style={boldStyle}>
              {ftl.currencyCode[0] === "USD"
                ? this.formatDollar(parseFloat(ftl?.CY_TAX) * parseFloat(currentYearRate.rate))
                : this.formatDollar(parseFloat(ftl?.CY_TAX))}
            </TableCell>
            <TableCell className="custom-cell" style={boldStyle}>
              {ftl.currencyCode[0] === "USD"
                ? this.formatDollar(parseFloat(ftl?.CY_TOTAL) * parseFloat(currentYearRate.rate))
                : this.formatDollar(parseFloat(ftl?.CY_TOTAL))}
            </TableCell>
            <TableCell className="custom-cell" style={boldStyle}>
              {ftl.currencyCode[0] === "USD"
                ? this.formatDollar(parseFloat(ftl?.CY_SUBTOTAL_PAYABLE) * parseFloat(currentYearRate.rate))
                : this.formatDollar(parseFloat(ftl?.CY_SUBTOTAL_PAYABLE))}
            </TableCell>
            <TableCell className="custom-cell" style={boldStyle}>
              {ftl.currencyCode[0] === "USD"
                ? this.formatDollar(parseFloat(ftl?.CY_TAX_PAYABLE) * parseFloat(currentYearRate.rate))
                : this.formatDollar(parseFloat(ftl?.CY_TAX_PAYABLE))}
            </TableCell>
            <TableCell className="custom-cell" style={boldStyle}>
              {ftl.currencyCode[0] === "USD"
                ? this.formatDollar(parseFloat(ftl?.CY_TOTAL_PAYABLE) * parseFloat(currentYearRate.rate))
                : this.formatDollar(parseFloat(ftl?.CY_TOTAL_PAYABLE))}
            </TableCell>
          </React.Fragment>
        );

      }else{
          return (
              <React.Fragment>
                <TableCell style={{ ...boldStyle, width: '20%',marginLeft:'16px', paddingLeft:'16px' }}>
                  {ftl?.carrierName}
                </TableCell>

                <TableCell className="custom-cell" style={boldStyle}>{this.formatDollar(ftl?.PY_SUBTOTAL_PAYABLE)}</TableCell>
                <TableCell className="custom-cell" style={boldStyle}>{this.formatDollar(ftl?.PY_TAX_PAYABLE)}</TableCell>
                <TableCell className="custom-cell" style={boldStyle}>{this.formatDollar(ftl?.CY_SUBTOTAL)}</TableCell>
                <TableCell className="custom-cell" style={boldStyle}>{this.formatDollar(ftl?.CY_TAX)}</TableCell>
                <TableCell className="custom-cell" style={boldStyle}>{this.formatDollar(ftl?.CY_TOTAL)}</TableCell>
                <TableCell className="custom-cell" style={boldStyle}>{this.formatDollar(ftl?.CY_SUBTOTAL_PAYABLE)}</TableCell>
                <TableCell className="custom-cell" style={boldStyle}>{this.formatDollar(ftl?.CY_TAX_PAYABLE)}</TableCell>
                <TableCell className="custom-cell" style={boldStyle}>{this.formatDollar(ftl?.CY_TOTAL_PAYABLE)}</TableCell>
                <TableCell className="custom-cell" style={boldStyle}></TableCell>
              </React.Fragment>
          )
      }



    }

    getContextualMenuItems = () => {
        let menuItems = []
        menuItems.push(
            {
                title: SETTINGS,
                icon: <SettingsIcon/>,
                onClick: () => this.props.setCurrentPage(SHIPPING_SUMMARY_PAGES.SETTINGS, {})
            })


      menuItems.push({
        title: 'Refresh',
        icon: <RefreshIcon />,
        onClick: () => {
          this.props.setFetch(true)
        }
      })


        if (this.props.loadingData) {
            menuItems.push({
                icon: <HourglassEmptyTwoTone />,
                handler: () => null,
                disabled: true,
                title: "Loading data..."
            });
        }



        return menuItems
    }

    getMonthsForQuarter = () => {
        const { selectedQuarters } = this.props;

        if (!selectedQuarters) {
            return [
                { name: 'January', value: '01' },
                { name: 'February', value: '02' },
                { name: 'March', value: '03' },
                { name: 'April', value: '04' },
                { name: 'May', value: '05' },
                { name: 'June', value: '06' },
                { name: 'July', value: '07' },
                { name: 'August', value: '08' },
                { name: 'September', value: '09' },
                { name: 'October', value: '10' },
                { name: 'November', value: '11' },
                { name: 'December', value: '12' },
            ]
        } else {
            switch (selectedQuarters) {
                case 1:
                    return [  { name: 'January', value: '01' },
                        { name: 'February', value: '02' },
                        { name: 'March', value: '03' }]
                case 2:
                    return [
                        { name: 'April', value: '04' },
                        { name: 'May', value: '05' },
                        { name: 'June', value: '06' }]
                case 3:
                    return [
                        { name: 'July', value: '07' },
                        { name: 'August', value: '08' },
                        { name: 'September', value: '09'}]
                case 4:
                    return [
                        { name: 'October', value: '10' },
                        { name: 'November', value: '11' },
                        { name: 'December', value: '12' }]
                default:
                    return [];
            }
        }
    }

    render() {
        const currentYear = new Date().getFullYear()
        const years = Array.from(new Array(21), (val, index) => currentYear - index)
        const carrierType = ['Standard Carrier', 'LTL/FTL Carrier']
        const { currency, selectedMonth, handleMonthChange, shippingSummary, selectedCarrierType, selectedYear, selectedCurrency, selectedQuarters, handleYearChange, handleCarrierTypeChange , handleChange, handleQuarterChange } = this.props

        return (
          <React.Fragment>
            <TopToolbar
              pageName={SHIPPING_SUMMARY}
              menuItems={this.getContextualMenuItems()}
            />

            <Box className="box">
              <FormControl variant="outlined" className="form-control">
                <InputLabel id="year-label">Year</InputLabel>
                <Select
                  className="select-year"
                  labelId="year-label"
                  value={selectedYear}
                  onChange={handleYearChange}
                  label="Year"
                >
                  {years.map((year) => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl variant="outlined" className="form-control">
                <InputLabel id="month-label">Month</InputLabel>
                <Select
                  className="select-month"
                  label="Month"
                  labelId="month-label"
                  value={selectedMonth}
                  onChange={handleMonthChange}
                >
                  <MenuItem value="">None</MenuItem>
                  {this.getMonthsForQuarter().map((month) => (
                    <MenuItem key={month.value} value={month.value}>
                      {month.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl
                variant="outlined"
                className="form-control"
                style={{ marginLeft: '0', marginRight: '16px' }}
              >
                <InputLabel id="currency-label">Currency</InputLabel>
                <Select
                  className="select-currency"
                  labelId="currency-label"
                  value={selectedCurrency}
                  onChange={handleChange}
                  label="Currency"
                >
                  {currency.map((currency) => (
                    <MenuItem key={currency} value={currency}>
                      {currency}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl variant="outlined" className="form-control">
                <InputLabel id="currency-label">Carrier Type</InputLabel>
                <Select
                  className="select-carrier"
                  labelId="carrier-label"
                  value={selectedCarrierType}
                  onChange={handleCarrierTypeChange}
                  label="Carrier Type"
                >
                  {carrierType.map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <Box className="box1">
              <div className="checkbox-shipping-container">
                <FormControlLabel
                  className="select-carrier"
                  control={
                    <Checkbox
                      checked={this.props.consolidateByCarrier}
                      onChange={this.props.handleByCarrier}
                      name="consolidateByCarrier"
                      color="primary"
                    />
                  }
                  label="Consolidate By Carrier"
                />
              </div>

              <div className="chip-container">
                {['QTR 1', 'QTR 2', 'QTR 3', 'QTR 4'].map((quarter, index) => (
                  <Chip
                    key={index}
                    label={quarter}
                    onClick={() => handleQuarterChange(quarter)}
                    className={`chip ${
                      selectedQuarters === index + 1 ? 'chip-selected' : ''
                    }`}
                  />
                ))}
                <span
                  style={{
                    marginLeft: '10px',
                    paddingTop: '2px',
                  }}
                >
                  {' '}
                  Last updated: {Utils.formatDateTime(
                    this.props.lastUpdatedAt
                  )}{' '}
                </span>
              </div>
            </Box>

            <div className="table-page-summary">
              <TablePage
                type="shippingSummary"
                tableName="ShippingTable"
                tableRows={shippingSummary}
                tableColumnHeads={
                  selectedCarrierType === 'Standard Carrier'
                    ? this.getTableHeaders()
                    : this.getTableHeadersFTL()
                }
                tableRow={
                  selectedCarrierType === 'Standard Carrier'
                    ? this.tableRow
                    : this.tableRowFTL
                }
                paginationText="Summary per page"
                hasSearchField
                searchStrings={
                  selectedCarrierType === 'Standard Carrier'
                    ? this.searchStrings
                    : this.searchStringsFTL
                }
              />
            </div>
          </React.Fragment>
        );
    }
}

export default ShippingSummaryTable;
