import React from "react";
import '../../../pricingDashboard/pricingDashboardPages/PricingDashboardTableRow.css';
import Tooltip from "@material-ui/core/Tooltip";
import {CANCEL_EDIT_ENTRY} from "../../../pricingDashboard/constants";
import EditIcon from "@material-ui/icons/Edit";
import { FormHelperText, IconButton, TableCell, TextField, Button, Checkbox } from '@material-ui/core';
import ClearIcon from '@mui/icons-material/Clear';
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { CARRY_FORWARD_OPTIONS, EDIT_LEDGER, SOURCE_OPTIONS, SUM_RULE_OPTIONS } from '../constants';
import Autocomplete from "@material-ui/lab/Autocomplete";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import TwoStepDelete from "../../../global/subcomponents/twoStepDelete/TwoStepDelete";
import { Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import {CANCEL, SAVE} from "../../../global/constants";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';



class SupplierEntryTableRow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            deleteId: "",
            isDeleted: false,
            isDialogOpen: false,
            id: "",
        };
    }

    componentDidMount() {

        let newState = {
            deleteListing: false,
            supplierName: '',
            CY_SUBTOTAL_PAYABLE: '0.00',
            source: '',
            category: '',
            carryForwardBalance: 'No',
            sumRule: '',
        }
        this.props.setRowState(newState)


    }

    handleOpen = () => {
        this.setState({ isDialogOpen: true });
    };

    handleClose = () => {
        this.setState({ isDialogOpen: false });
    };


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.listingIdBeingEdited !== this.props.listingIdBeingEdited) {
            if (this.props.listingIdBeingEdited === this.props.row.id) {
                this.props.setRowState({
                    id: this.props.row.id,
                    supplierName: this.props.row.supplierName,
                    CY_SUBTOTAL_PAYABLE: this.props.row.CY_SUBTOTAL_PAYABLE,
                    source: this.props.row.source,
                    category: this.props.row.category,
                    carryForwardBalance: this.props.row.carryForwardBalance || 'No',
                    sumRule: this.props.row.sumRule,

                })
            }
        }
    }


    handleCancel = (row) => {
        if (row.id === '') {
            const newEntry = this.props.supplierSummary.filter(item => item.id !== '');

            // Update the supplierSummary state with the filtered list
            this.setState(prevState => {
                this.props.setSupplierEntries(newEntry);
            });
        }

        this.props.setListingIdBeingEdited("");
        this.props.setRowState({
            id: '',
            supplierName: '',
            CY_SUBTOTAL_PAYABLE: '',
            source: '',
            category: '',
            carryForwardBalance: '',
            sumRule: '',
        });

    }


    handleConfirm = (row) => {
        let state = this.props.getRowState()
        if (!state.supplierName || !state.CY_SUBTOTAL_PAYABLE || !state.carryForwardBalance) {
            return;
        }

        this.props.setListingIdBeingEdited("")
        const request = {
            source: state.source,
            category: state.category,
            carryForwardBalance: state.carryForwardBalance,
            sumRule: state.sumRule,
            id: row.id,
            supplierName: state.supplierName,
            CY_SUBTOTAL_PAYABLE: String(state.CY_SUBTOTAL_PAYABLE).replace(/,/g, ''),
            ledgerId: this.props.ledgerId,
            currency: this.props.currency,
            year: this.props.year,
            ledgerType: this.props.ledgerType,
            type: "supplier"
        };
        this.props.setRowState({
            supplierName: '',
            CY_SUBTOTAL_PAYABLE: '',
        })

      this.props.updateEntry(request);
    }

    handleDeleteEntry = () => {
        const data = {
            id: this.state.id,
            year: this.props.year,
            currency: this.props.currency
        }
        //this.props.deleteLedger(data)
        this.setState({ isDialogOpen: false, deleteId: "" });
    }

    getEditingCancelConfirm = (row) => {
        return <>
            <div style={{display:"flex", justifyContent:"left"}}>
                <Tooltip title={CANCEL_EDIT_ENTRY}>
                    <IconButton onClick={() => this.handleCancel(row)}>
                        <ClearIcon
                            fontSize='small'
                            color='error'
                        />
                    </IconButton>
                </Tooltip>
            </div>
        </>
    }



    formatBalance = (balance) => {
        const validBalance = parseFloat(balance);
        if (isNaN(validBalance)) {
            return '0.00';
        }
        return validBalance
          .toFixed(2)
          .toString()
          .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    };



    onBlurHandle = (name, value) => {
        const formattedValue = this.formatBalance(value);
        if (name === 'CY_SUBTOTAL_PAYABLE') {
            this.props.setRowState({ CY_SUBTOTAL_PAYABLE: formattedValue });
        } else if (name === 'balance') {
            this.props.setRowState({ balance: formattedValue });
        }
    };

    displayBalanceEditingTextField = () => {
        const state = this.props.getRowState();

        return (
          <TextField
            style={{ marginLeft: "-8px", padding: "0" }}
            className="full-width-ledger"
            variant="outlined"
            name={state.row === "Opening Balance" ? "balance" : "CY_SUBTOTAL_PAYABLE"}
            label="Amount"
            value={state.row === "Opening Balance" ? state.balance : state.CY_SUBTOTAL_PAYABLE || ''}
            onBlur={(e) => this.onBlurHandle(e.target.name, e.target.value)}
            onChange={(e) => {
                const value = e.target.value.replace(/,/g, '')
                if (state.row === "Opening Balance") {
                    this.props.setRowState({ balance: value });
                } else {
                    this.props.setRowState({ CY_SUBTOTAL_PAYABLE: value });
                }
            }}
            onPaste={(e) => {
                e.preventDefault(); // Prevent default paste action
                const pastedValue = e.clipboardData.getData('Text').replace(/,/g, ''); // Remove commas
                if (state.row === "Opening Balance") {
                    this.props.setRowState({ balance: pastedValue });
                } else {
                    this.props.setRowState({ CY_SUBTOTAL_PAYABLE: pastedValue });
                }
            }}
            error={
              state.row !== "Opening Balance" &&
              (state.CY_SUBTOTAL_PAYABLE === '' || state.CY_SUBTOTAL_PAYABLE === null || state.CY_SUBTOTAL_PAYABLE === undefined)
            }
          />
        );
    };


    displayNameEditingTextField = () => {
        let state = this.props.getRowState()
        return <TextField
            style={{marginLeft:"-8px",padding:"0"}}
            className="full-width-ledger"
            variant="outlined"
            name="supplierName"
            label="Supplier Name"
            value={state.supplierName}
            onChange={(e) => this.props.setRowState({supplierName: e.target.value})}
            error={state.supplierName === '' || state.supplierName === null || state.supplierName === undefined}
        />
    }

    displayCategoryEditingDropdown = () => {
        let state = this.props.getRowState();

        if (state.source === 'Transactions') {
            return (
              <Autocomplete

                className="full-width-ledger-category"
                multiple
                open={this.state.open}
                value={
                    Array.isArray(state.category)
                      ? state.category.map((cat) =>
                        this.props.categories.find((category) => category.name === cat) // Find category objects from names
                      ).filter(Boolean) // Ensure no undefined values
                      : [] // Fallback to an empty array if state.category is not an array
                }
                options={(this.props.categories || []).sort((a, b) => a.displayOrder - b.displayOrder)} // Sorting categories
                getOptionLabel={(option) => option.name || ''}
                autoHighlight={true}
                renderTags={(value) => {
                    if (value.length > 1) {
                        return (
                          <div style={{ width:'60px', display: 'inline-flex', flexWrap: 'nowrap', alignItems: 'center' }}>

                              <Chip
                                variant="outlined"
                                size="small"
                                style={{ borderColor: "#acd685", margin: "1px 6px 1px 0", fontSize: "12px" }}
                                label={value[0].name} // Display the first category
                              />
                              <Chip
                                variant="outlined"
                                size="small"
                                style={{ borderColor: "#acd685", margin: "1px 6px 1px 0", fontSize: "12px" }}
                                label="..." // Display "..." for additional categories
                              />

                          </div>
                        );
                    }
                    return value.map((option) => (
                      <Chip
                        variant="outlined"
                        size="small"
                        style={{ borderColor: "#acd685", margin: "1px 6px 1px 0", fontSize: "12px" }}
                        label={option.name}
                      />
                    ));
                }}
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                      <Checkbox
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.name}
                  </React.Fragment>
                )}
                onOpen={() => { this.setState({ open: true }) }}
                onClose={(event, reason) => {
                    reason === 'blur' ? this.setState({ open: false }) : this.setState({ open: true })
                }}
                name="category"
                onChange={(event, value) => {
                    this.props.setRowState({
                        category: value.map((cat) => cat?.name), // Store just the category names (strings) in the state
                    })
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Category"
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    name="category"
                  />
                )}
              />

            );
        }

        return null;
    };

    displaySourceEditingDropdown = () => {
        let state = this.props.getRowState();
        return (
          <Autocomplete
            className="full-width-ledger"
            options={SOURCE_OPTIONS}
            getOptionLabel={(option) => option}
            value={state.source || null}
            onChange={(e,value) => this.props.setRowState({ source: value })}
            renderInput={(params) =>
              <TextField
                {...params}
                name="source"
                label="Source"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
            }
          />
        );
    }

    displaySumRuleEditingDropdown = () => {
        let state = this.props.getRowState();

        // Only render the dropdown if state.source is 'transactions'
        if (state.source === 'Transactions') {
            return (
              <Autocomplete
                className="full-width-ledger"
                options={SUM_RULE_OPTIONS}
                getOptionLabel={(option) => option}
                value={state.sumRule || null}
                onChange={(e, value) => this.props.setRowState({ sumRule: value })}
                renderInput={(params) =>
                  <TextField
                    {...params}
                    name="sumRule"
                    label="Sum Rule"
                    variant="outlined"
                    required={true}
                    error={state?.sumRule === '' || state?.sumRule === null || state?.sumRule === undefined}
                    InputLabelProps={{ shrink: true }}
                  />
                }
              />
            );
        }

        // Return null or an empty fragment if the condition is not met
        return null;
    }

    displayCarryForwardBalanceEditingDropdown = () => {

        let state = this.props.getRowState();
        return (
          <Autocomplete
            className="full-width-ledger"
            options={CARRY_FORWARD_OPTIONS}
            getOptionLabel={(option) => option}
            value={state.carryForwardBalance || null}

            onChange={(e, value) => this.props.setRowState({ carryForwardBalance: value })}
            renderInput={(params) =>
              <TextField
                {...params}
                name="carryForwardBalance"
                label="Carry Forward Balance"
                variant="outlined"
                required={true}
                error={state.carryForwardBalance === '' || state.carryForwardBalance === null || state.carryForwardBalance === undefined}
                InputLabelProps={{ shrink: true }}
              />
            }
          />
        );
    }



    displayEditingRow = (row) => {
        let state = this.props.getRowState();


        if (row.supplierName === "Total") {
            return null
        }

        return (
          <React.Fragment>
            <TableCell
              style={{
                width: '70px',
                height: '77px',
                padding: '0',
                margin: '0',
              }}
            >
              {this.getEditingCancelConfirm(row)}
            </TableCell>

            <TableCell>
              <div className="rowSpecLedger">
                {this.displayNameEditingTextField()}
                {this.displaySourceEditingDropdown()}
                {this.displayCategoryEditingDropdown()}
                {this.displaySumRuleEditingDropdown()}
                {this.displayCarryForwardBalanceEditingDropdown()}
              </div>
            </TableCell>

            <TableCell>
              <div className="rowSpecLedger">
                {state.source === 'Transactions' ? (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                    }}
                  >
                    <span style={{ flexShrink: 0 }}>$</span>
                    <span
                      style={{
                        marginLeft: '55px',
                        minWidth: '80px',
                        textAlign: 'right',
                      }}
                    >
                   {state.row === "Opening Balance" ?  this.formatBalance(state.balance) :  this.formatBalance(state.CY_SUBTOTAL_PAYABLE)  || ''}
                    </span>
                  </div>
                ) : (
                  this.displayBalanceEditingTextField()
                )}
              </div>
            </TableCell>

            <TableCell>
              <Tooltip title="Save Entry" style={{ marginLeft: '-8px' }}>
                <IconButton onClick={() => this.handleConfirm(row)}>
                  <CheckCircleIcon fontSize="small" color="secondary" />
                </IconButton>
              </Tooltip>
            </TableCell>
          </React.Fragment>
        );
    }

    displayNonEditingRow = (row) => {

        if (this.props.isEditPayable && row.supplierName === "Total") {
            return null
        }


        return (
            <React.Fragment>
                {this.props.isEditPayable && row.supplierName !== "Total" && (row.carryForwardBalance ?? false) ? (
                    <TableCell style={{ width: "70px", height: "77px", padding: "0" }}>
                        <Tooltip title={"Edit Entry"}>
                            <IconButton
                                onClick={() => {
                                    this.props.setHideFilterBox(true);
                                    this.props.setListingIdBeingEdited(row.id);
                                }}
                            >
                                <EditIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </TableCell>
                ) : (
                    <TableCell  style={{width: "24px",height:"50px"}}>
                    </TableCell>
                )}


                <TableCell style={{ textAlign: "right", padding: "5px", paddingRight: "50px", width: "100%", fontWeight: row.supplierName === "Total" ? "bold" : "normal"}}>
                    <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                        {row.supplierName}
                    </div>
                </TableCell>


                <TableCell style={{  textAlign: "right", padding: "5px", paddingRight: "50px", width: "auto",
                    fontWeight: row.supplierName === "Total" ? "bold" : "normal"}}>
                    <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                        <span style={{flexShrink: 0}}>$</span>
                        <span style={{marginLeft: '55px', minWidth: '80px', textAlign: 'right'}}>
                 {row.supplierName === "Opening Balance" ? this.formatBalance(row.balance) :  this.formatBalance(row.CY_SUBTOTAL_PAYABLE)}
                </span>
                    </div>
                </TableCell>


                {this.props.isEditPayable && (row.carryForwardBalance ?? false) ? (
                    <TwoStepDelete
                        rowId={row.id}
                        handleDelete={(rowIdToDelete) => {
                            const data = {
                                ledgerId: this.props.ledgerId,
                                id: row.id,
                                year: this.props.year,
                                currency: this.props.currency,
                                type: "supplier"
                            };

                            this.props.deleteEntry(data);

                            this.setState({
                                isDeleted: true,
                                deleteId: "",
                            });
                        }}
                        type={this.state.isDeleted}
                        deleteId={this.state.deleteId}
                        setDeleteId={(newDeleteId) => this.setState({ deleteId: newDeleteId })}
                    />
                ) : (
                    <TableCell style={{ width: "200px" }}></TableCell>
                )}
            </React.Fragment>
        );
    };


    displayListingRow = (listingIdBeingEdited) => {
        if (listingIdBeingEdited === this.props.row.id) {
            return this.displayEditingRow(this.props.row)
        } else {
            return <>
                {this.displayNonEditingRow(this.props.row)}
            </>
        }
    }

    render() {
        return (
            <React.Fragment>
                <Dialog open={this.state.isDialogOpen} onClose={this.handleClose}>
                    <DialogTitle>Confirmation</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to delete this entry?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>

                        <div className='save-cancel-buttons'>
                            <Button
                                variant='contained'
                                onClick={this.handleClose}
                            >
                                {CANCEL}
                            </Button>
                            <div className='save-spacing'/>
                            <Button
                                variant='contained'
                                color='primary'
                                onClick={this.handleDeleteEntry}
                            >
                                Delete
                            </Button>
                        </div>

                    </DialogActions>
                </Dialog>
                {this.displayListingRow(this.props.listingIdBeingEdited)}
            </React.Fragment>
        )
    }
}


export default SupplierEntryTableRow;