import {
    NAVIGATE_TO_PARENT_TRANSACTION,
    REMOVE_DELETED_TRANSACTION,
    SET_PROCESSING,
    START_LOADING,
    STOP_LOADING,
    STORE_ALL_CHEQUES,
    STORE_ALL_LEDGERS,
    STORE_ALL_TRANSACTION_CATEGORIES,
    STORE_ALL_TRANSACTIONS,
    STORE_EDITING_TRANSACTION,
    STORE_FOUND_INVOICE,
    STORE_FTL_SUMMARY,
    STORE_LEDGER_ENTRY,
    STORE_MARKETPLACE_PROGRESS_SESSION,
    STORE_MARKETPLACE_PROGRESS_SUMMARY,
    STORE_MARKETPLACE_SUMMARY,
    STORE_NOTE,
    STORE_PREDEFINED_ENTRY,
    STORE_PURCHASE_SUMMARY,
    STORE_SHIPPING_SUMMARY,
    STORE_SHIPPING_SUMMARY_SETTINGS,
    STORE_STATEMENT_TAXES_SUMMARY,
    STORE_STATEMENT_TAXES_TEMPLATE,
    STORE_SUPPLIER_REFERENCES,
    STORE_TAXTYPE_WITH_STATE_PROVINCE,
    STORE_TRANSACTION_ATTACHMENT,
    STORE_TRANSACTIONS_LEDGER,
    STORE_TRIAL_BALANCE,
    STORE_UPDATED_CHEQUES,
    STORE_UPDATED_TAXINFO,
    STORE_UPDATED_TRANSACTION,
    STORE_UPDATED_TRANSACTIONS,
} from '../../constants/accounting';


const initialState = {
    rates: [],
    cheques: [],
    shippingSummary: [],
    fetchTransactions: [],
    ledgers: [],
    transactions: [],
    editingTransaction: null,
    processing: false,
    attachmentFile: [],
    transactionCategories: [],
    fetchedReferences: [],
    taxInfos: [],
    taxTypewithStateProvince: [],
    paidList: [],
    receivedList: [],
    templates: [],
    loadingData: false,
    purchaseSummary: [],
    ftlSummary: [],
    note: null,
    trialBalance: [],
    marketplaceSummary: [],
    progress: 0,
    sessionId: null,
    lastUpdatedAt: null,
}

const updateFetchTransaction = (oldTransactions, updatedTransaction) => {
    return {
        ...oldTransactions,
        ledgerEntries: updatedTransaction.entries
    };
};


const updateTransaction = (oldTransactions, updatedTransaction) => {
    let newTransactions = [...oldTransactions]
    const index = newTransactions.findIndex((transaction) => transaction.transactionId === updatedTransaction.transactionId)
    if (index !== -1) {
        newTransactions[index] = updatedTransaction
    } else {
        newTransactions.push(updatedTransaction)
    }
    return newTransactions
}

const removeTransaction = (oldTransactions, deletedTransactionId) => {
    return  oldTransactions.filter((transaction)=>
        transaction.transactionId !== deletedTransactionId && transaction.parentTransactionId !== deletedTransactionId
    )
}

export default (state = initialState, action) => {

    switch (action.type) {
        case STORE_ALL_TRANSACTIONS:
            return { ...state, transactions: action.transactions }
        case STORE_ALL_LEDGERS:
            return { ...state, ledgers: action.ledgers }
        case STORE_LEDGER_ENTRY:
            return {...state, fetchTransactions: updateFetchTransaction(state.fetchTransactions, action.ledgerEntries)}
        case STORE_PREDEFINED_ENTRY:
            return {...state, fetchTransactions: action.ledgerEntries}
        case STORE_TRANSACTIONS_LEDGER:
            return { ...state, fetchTransactions: action.transactions }
        case STORE_ALL_CHEQUES:
            return { ...state, cheques: action.cheques }
        case STORE_SHIPPING_SUMMARY_SETTINGS:
            return { ...state, rates: action.rates }
        case STORE_STATEMENT_TAXES_TEMPLATE:
            return { ...state, templates: action.templates }
        case STORE_SHIPPING_SUMMARY:
            return { ...state,
                shippingSummary: action.data.summary,
                lastUpdatedAt: action.data.lastUpdatedAt
            }
        case STORE_FTL_SUMMARY:
            return { ...state, ftlSummary: action.ftlSummary }
        case STORE_PURCHASE_SUMMARY:
            return { ...state,
                purchaseSummary: action.data.purchaseSummary,
                lastUpdatedAt: action.data.lastUpdatedAt
            }
        case STORE_STATEMENT_TAXES_SUMMARY:
            return { ...state, paidList: action.taxSummary.paidList, receivedList: action.taxSummary.receivedList }
        case STORE_FOUND_INVOICE:
            return {...state, invoice: action.invoice}
        case STORE_UPDATED_TRANSACTION:
            return {...state, transactions: updateTransaction(state.transactions, action.updatedTransaction)}
        case STORE_UPDATED_TAXINFO:
            return {...state,  taxInfos: action.updatedTaxInfo}
        case STORE_TAXTYPE_WITH_STATE_PROVINCE:
            return {...state,  taxTypewithStateProvince: action.taxTypewithStateProvince}
        case REMOVE_DELETED_TRANSACTION:
            return {...state, transactions: removeTransaction(state.transactions, action.transactionId)}
        case SET_PROCESSING:
            return {...state, processing: action.processing}
        case STORE_SUPPLIER_REFERENCES:
            return {...state, fetchedReferences: action.references}
        case STORE_TRANSACTION_ATTACHMENT:
            return {...state, attachmentFile: action.attachmentFile}
        case STORE_ALL_TRANSACTION_CATEGORIES:
            return {...state, transactionCategories: action.data.transactionCategories}
        case STORE_UPDATED_TRANSACTIONS:
            let transactionIds = action.updatedTransactions.map((transaction) => transaction.transactionId)
            let filteredTransactions = state.transactions.filter((transaction)=>(
                !transactionIds.includes(transaction.transactionId)
            ))
            return {...state, transactions: [...filteredTransactions, ...action.updatedTransactions]}
        case STORE_UPDATED_CHEQUES:
            let chequesIds = action.updatedCheques.map((cheque) => cheque.chequeId)
            let filteredCheques = state.cheques.filter((cheque)=>(
                !chequesIds.includes(cheque.chequeId)
            ))
            return {...state, cheques: [...filteredCheques, ...action.updatedCheques]}
        case STORE_EDITING_TRANSACTION:
            return {...state, editingTransaction: action.editingTransaction}
        case NAVIGATE_TO_PARENT_TRANSACTION:
            return {...state, editingTransaction: state.transactions.find((transaction)=>transaction.transactionId == action.parentTransactionId)}
        case START_LOADING:
            return { ...state, loadingData: true };
        case STOP_LOADING:
            return { ...state, loadingData: false };
        case STORE_NOTE:
            return {...state, note: action.data.notes}
        case STORE_TRIAL_BALANCE:
            return { ...state, trialBalance: action.trialBalance }
        case STORE_MARKETPLACE_SUMMARY:
            return {...state, marketplaceSummary: action.marketplaceSummary}
        case STORE_MARKETPLACE_PROGRESS_SESSION:
            return {...state, sessionId: action.sessionId}
        case STORE_MARKETPLACE_PROGRESS_SUMMARY:
            return {...state, progress: action.progress}
        default:
            return state
    }
}