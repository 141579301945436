class Utils {
    // Formats a given amount as a dollar currency string.
    static formatDollar(amount) {
        // Regex expression taken from https://stackoverflow.com/questions/2901102
        return '$' + amount.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    }

    static formatDateTime(dateString) {
        const date = new Date(dateString);
        return date.toLocaleString('en-US', {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: true,
        });
    }

    static convertAmountToWords = (amount) => {
        if (isNaN(amount) || amount === null || amount === undefined) return 'Invalid amount';

        amount = parseFloat(amount);

        const ones = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'];
        const teens = ['Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'];
        const tens = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];

        const numToWords = (num) => {
            if (num === 0) return 'Zero'
            if (num < 10) return ones[num]
            if (num < 20) return teens[num - 10]
            if (num < 100) return tens[Math.floor(num / 10)] + (num % 10 !== 0 ? ' ' + ones[num % 10] : '')
            if (num < 1000) return ones[Math.floor(num / 100)] + ' Hundred' + (num % 100 !== 0 ? ' and ' + numToWords(num % 100) : '')
            if (num < 1000000) return numToWords(Math.floor(num / 1000)) + ' Thousand' + (num % 1000 !== 0 ? ' ' + numToWords(num % 1000) : '')
            if (num < 1000000000) return numToWords(Math.floor(num / 1000000)) + ' Million' + (num % 1000000 !== 0 ? ' ' + numToWords(num % 1000000) : '')
            return numToWords(Math.floor(num / 1000000000)) + ' Billion' + (num % 1000000000 !== 0 ? ' ' + numToWords(num % 1000000000) : '')
        };

        const parts = amount.toFixed(2).split('.')
        const dollars = parseInt(parts[0], 10)
        const cents = parseInt(parts[1], 10)

        let result = ''

        if (dollars > 0) {
            result += numToWords(dollars)

            result += dollars === 1 ? ' Dollar' : ' Dollars'
        }

        if (cents > 0) {
            if (dollars > 0) {
                result += ' and '
            }
            result += numToWords(cents)

            result += cents === 1 ? ' Cent' : ' Cents'
        }

        return result.trim()
    }
}

export default Utils;
