import React from "react";
import TopToolbar from "../../global/subcomponents/topToolbar/TopToolbar";
import {
    BACK,
    CARRIER_TABLE_CELLS, EDIT_LEDGER,
    FTL_CARRIER_TABLE_CELLS,
    LEDGER_ENTRIES_CELLS, LEDGER_PAYABLE_ENTRIES_CELLS,
    LEDGER_TABLE_CELLS,
    LEDGERS_PAGES, MARKETPLACE_TABLE_CELLS,
    SAVE, STOCK_IN_HAND_TABLE_CELLS, SUPPLIER_TABLE_CELLS,
} from './constants';
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import SaveIcon from "@material-ui/icons/Save";
import {
    Box,
    Dialog, DialogActions,
    DialogContent, DialogContentText,
    DialogTitle,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Divider
} from '@mui/material';
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import { Button, TableCell, TextField } from '@material-ui/core';
import AddCircleIcon from "@material-ui/icons/AddCircle";
import MenuIcon from '@mui/icons-material/Menu';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import InputAdornment from "@material-ui/core/InputAdornment";
import RefreshIcon from '@mui/icons-material/Refresh';
import {Layout} from "../../global/Table/TablePageHelpers/LayoutObject";
import TablePage from "../../global/Table/TablePage";
import EditIcon from "@material-ui/icons/Edit";
import { format } from 'date-fns';
import Tooltip from "@material-ui/core/Tooltip";
import LedgerEntryTableRow from "./LedgerEntryTableRow";
import CloseIcon from "@material-ui/icons/Close";
import { HourglassEmptyTwoTone } from '@material-ui/icons';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import CarrierEntryTableRow from './summary/CarrierEntryTableRow';
import FTLEntryTableRow from './summary/FTLEntryTableRow';
import SupplierEntryTableRow from './summary/SupplierEntryTableRow';
import StockEntryTableRow from './summary/StockEntryTableRow';
import MarketplaceEntryTableRow from './summary/MarketplaceEntryTableRow';
import Utils from '../../Utils/Utils';


class EditLedger extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            templates: this.props.ledger.ledgerEntries || [],
            anchorEl: null,
            activeIndex: null,
            draggedIndex: null,
            isEditEntries: false,
            deleteId: "",
            isDeleted: false,
            listingIdBeingEdited: '',
            isEdit: false,
            isEditPayable: false,
            isShowPayable: false,
            hideFilterBox : false,
            isDialogOpen: false,
            currentNote: '',
            selectedIndex: null,
            isRefresh: false,
            carrierSummary: this.props.carrierSummary || [],
            supplierSummary: this.props.supplierSummary || [],
            ftlSummary: this.props.ftlSummary || [],
            stockSummary: this.props.stockSummary || [],
            marketplaceSummary: this.props.marketplaceSummary || [],

        };
    }

    setHideFilterBox = (isHide) =>{
        this.setState({hideFilterBox : isHide})
    }

    componentDidMount() {


        if(this.props.ledger.name === "Accounts Payable" ||
          this.props.ledger.name === "Accounts Receivables" ||
          this.props.ledger.name === "Cost of Sales"){
            this.setState({isShowPayable: true})
        }



        if (this.props.ledger?.ledgerEntries && this.props.ledger.ledgerEntries.length > 0) {
            this.setState({
                templates: [...this.props.ledger.ledgerEntries],
            });
        }
        const data = {
            ledgerId: this.props.ledger.ledgerId,
            year: this.props.selectedYear,
            currency: this.props.selectedCurrency,
            isFetch: this.state.isRefresh
        }
        this.props.ledgerEntries(data)
    }

    fetchLedgerEntries = () => {
        const data = {
            ledgerId: this.props.ledger.ledgerId,
            year: this.props.selectedYear,
            currency: this.props.selectedCurrency
        }
        this.props.fetchTransactionsLedger(data)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.fetchTransactions !== this.props.fetchTransactions) {
            const { ledgerEntries } = this.props.fetchTransactions;
            if (ledgerEntries && Array.isArray(ledgerEntries)) {
                // Normalize transactions

                const flattenedTemplates = ledgerEntries.map(entry => {
                    if (entry.entries) {
                        return entry.entries; // Handle nested entries
                    }
                    return entry; // Handle top-level entries
                }).flat(); // Flatten any nested arrays


                this.setState({
                    templates: flattenedTemplates,
                }, this.updateBalances);
            }else{
                // Normalize transactions
                const flattenedTemplates = this.props.fetchTransactions.map(entry => {
                    if (entry.entries) {
                        return entry.entries; // Handle nested entries
                    }
                    return entry; // Handle top-level entries
                }).flat(); // Flatten any nested arrays


                this.setState({
                    templates: flattenedTemplates,
                }, this.updateBalances);
            }
        }

        if (prevProps.stockSummary !== this.props.stockSummary) {
            this.setState({
                stockSummary : this.props.stockSummary
            })
        }

        if (prevProps.supplierSummary !== this.props.supplierSummary) {
            this.setState({
                supplierSummary : this.props.supplierSummary
            })
        }
        if (prevProps.ftlSummary !== this.props.ftlSummary) {
            this.setState({
                ftlSummary : this.props.ftlSummary
            })
        }

        if (prevProps.carrierSummary !== this.props.carrierSummary) {
            this.setState({
                carrierSummary : this.props.carrierSummary
            })
        }

        if (prevProps.marketplaceSummary !== this.props.marketplaceSummary) {
            this.setState({
                marketplaceSummary : this.props.marketplaceSummary
            })
        }
    }


    onDragStart = (index) => {
        this.setState({draggedIndex: index});
    };

    onDragOver = (index) => {
        const {draggedIndex, templates} = this.state;
        if (draggedIndex === index) return;

        const updatedTemplates = [...templates];
        const [draggedItem] = updatedTemplates.splice(draggedIndex, 1);
        updatedTemplates.splice(index, 0, draggedItem);

        this.setState({
            templates: updatedTemplates,
            draggedIndex: index,
        }, this.updateBalances);
    }

    onDragEnd = () => {
        this.setState({draggedIndex: null})
    }

    getContextualMenuItems = () => {
        const { setCurrentPage, listLedgers, selectedCurrency, selectedYear } = this.props;
        const { isEditEntries, isEditPayable, isShowPayable } = this.state;
        const menuItems = [];


        // Dynamic menu items based on isShowPayable and isEditPayable

        if (isShowPayable ) {
                if (selectedCurrency === "Consolidated CAD"){
                    menuItems.push({
                        title: 'Back',
                        icon: <KeyboardBackspaceIcon />,
                        onClick: () => {
                            setCurrentPage(LEDGERS_PAGES.TABLE, {});
                            listLedgers({ currency: selectedCurrency, year: selectedYear });
                        },
                    })
                }else{
                    menuItems.push({
                        title: 'Back',
                        icon: <KeyboardBackspaceIcon />,
                        onClick: () => {
                            setCurrentPage(LEDGERS_PAGES.TABLE, {});
                            listLedgers({ currency: selectedCurrency, year: selectedYear });
                        },
                    })
                    menuItems.push(
                        isEditPayable
                            ? {
                                title: 'Cancel Editing',
                                icon: <CloseIcon />,
                                onClick: () => {
                                    this.setHideFilterBox(false);
                                    this.setState({ isEditPayable: false });

                                    this.setState({ listingIdBeingEdited: null }, () => {
                                        if (this.state.carrierSummary && this.state.carrierSummary.length > 0) {
                                            const newEntry = this.state.carrierSummary.filter(entry => entry.id !== '' && entry.id !== null);
                                            this.setState({ carrierSummary: newEntry });
                                        }
                                    });

                                    this.setState({ listingIdBeingEdited: null }, () => {
                                        if (this.state.supplierSummary && this.state.supplierSummary.length > 0) {
                                            const newEntry = this.state.supplierSummary.filter(entry => entry.id !== '' && entry.id !== null);
                                            this.setState({ supplierSummary: newEntry });
                                        }
                                    });

                                    this.setState({ listingIdBeingEdited: null }, () => {
                                        if (this.state.ftlSummary && this.state.ftlSummary.length > 0) {
                                            const newEntry = this.state.ftlSummary.filter(entry => entry.id !== '' && entry.id !== null);
                                            this.setState({ ftlSummary: newEntry });
                                        }
                                    });

                                    this.setState({ listingIdBeingEdited: null }, () => {
                                        if (this.state.templates && this.state.templates.length > 0) {
                                            const newEntry = this.state.templates.filter(entry => entry.transactionId !== '' && entry.transactionId !== null);
                                            this.setState({ templates: newEntry });
                                        }
                                    });

                                },
                            }
                            : {
                                title: 'Edit Entry',
                                icon: <EditIcon />,
                                onClick: () => {
                                    this.setState({ isEditPayable: true });
                                },
                            }
                    );

                    {(this.props.ledger.name === "Cost of Sales" || this.props.ledger.name === "Accounts Receivables" || this.props.ledger.name === "Accounts Payable") &&
                         menuItems.push({
                            title: 'Refresh',
                            icon: <RefreshIcon />,
                            onClick: () => {
                                const data = {
                                    ledgerId: this.props.ledger.ledgerId,
                                    year: this.props.selectedYear,
                                    currency: this.props.selectedCurrency,
                                    isFetch: true
                                }
                                this.props.ledgerEntries(data)
                            },
                        })
                    }



                }


        }else{
            if (selectedCurrency === "Consolidated CAD"){
                menuItems.push({
                    title: 'Back',
                    icon: <KeyboardBackspaceIcon />,
                    onClick: () => {
                        setCurrentPage(LEDGERS_PAGES.TABLE, {});
                        listLedgers({ currency: selectedCurrency, year: selectedYear });
                    },
                })
            }else {
                if (isEditEntries) {
                    menuItems.push({
                        title: 'Back',
                        icon: <KeyboardBackspaceIcon />,
                        onClick: () => {
                            this.setState({isEditEntries: false});
                        },
                    })

                    menuItems.push({
                        title: 'Save',
                        icon: <SaveIcon />,
                        onClick: this.handleSave,
                    });
                } else {
                    menuItems.push({
                        title: 'Back',
                        icon: <KeyboardBackspaceIcon />,
                        onClick: () => {
                            setCurrentPage(LEDGERS_PAGES.TABLE, {});
                            listLedgers({ currency: selectedCurrency, year: selectedYear });
                        },
                    })

                    menuItems.push({
                        title: 'Edit Entries',
                        icon: <EditIcon />,
                        onClick: () => this.setState({ isEditEntries: true }),
                    });

                    menuItems.push({
                        title: 'Refresh',
                        icon: <RefreshIcon />,
                        onClick: () => {

                            const data = {
                                ledgerId: this.props.ledger.ledgerId,
                                year: this.props.selectedYear,
                                currency: this.props.selectedCurrency
                            }

                            const callback = (error, response) => {
                                if (response) {
                                    this.props.handleSave(response, this.props.ledger.ledgerId);
                                }
                            };

                            this.props.fetchTransactionsLedger(data,callback)

                        },
                    });
                }
            }

        }

        //loading indicator
        if (this.props.loadingData) {
            menuItems.push({
                title: "Loading data...",
                icon: <HourglassEmptyTwoTone />,
                onClick: () => null,
                disabled: true
            })
        }


        // Combine back button with menu items
        return [...menuItems];
    };


    handleSave = () => {
        this.props.handleSave(this.state.templates, this.props.ledger.ledgerId);
        this.setState({isEditEntries: false});
    }

    isSaveDisabled = () => {
        return this.state.templates.some(template =>
            !template.description || !template.debit || !template.credit || !template.balance
        );
    };

    formatDollar = (amount, forceTwoDecimals) => {

        if (amount === null || amount === undefined || amount === 0) {
            return "0.00";
        }

        let value;
        const amountStr = amount.toString(); // Convert to string to safely use match and replaceAll

        if (forceTwoDecimals) {
            value = this.parseNumber(amount).toFixed(2);
        } else {
            // If the amount has more than two decimal places, limit it to two
            if (amountStr.match(/.*\.\d{2}.*/)) {
                try {
                    value = this.parseNumber(amount).toFixed(2);
                } catch {
                    value = amountStr.replaceAll(',', '');
                }
            } else {
                value = amountStr.replaceAll(',', '');

            }
        }
        return value
    }

    formatAmount = (amount) => {

        if (amount === null || amount === undefined || amount === 0) {
            return "0.00";
        }

        let value;
        const amountStr = amount.toString(); // Convert to string to safely use match and replaceAll


        // If the amount has more than two decimal places, limit it to two
        if (amountStr.match(/.*\.\d{2}.*/)) {
            try {
                value = this.parseNumber(amount).toFixed(2);
            } catch {
                value = amountStr.replaceAll(',', '');
            }
        } else {
            value = amountStr.replaceAll(',', '');

        }

        return value.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    }


    parseNumber = (value) => {
        if (typeof value === 'number') {
            return value;
        }
        if (typeof value === 'string') {
            return parseFloat(value.replace(/,/g, ''))
        }
        return NaN;
    }

    handleTemplateDateChange = (date, index, entry = null) => {

        this.setState(prevState => {
            const updatedTemplates = [...prevState.templates];
            updatedTemplates[index] = {
                ...updatedTemplates[index],
                "date": date,

            };

            return {templates: updatedTemplates};
        });
    };


    handleTemplateChange = (e, index, entry = null) => {
        let {name, value} = e.target;

        // Disable changes for debit and credit at index 0
        if (index === 0 && entry.description === 'Opening Balance' && (name === 'debit' || name === 'credit')) {
            return; // Prevent changes to debit or credit at index 0
        }
        this.setState(prevState => {
            const updatedTemplates = [...prevState.templates];
            updatedTemplates[index] = {
                ...updatedTemplates[index],
                [name]: value,

            };

            return {templates: updatedTemplates};
        });
    };


    removeTemplateRow = (index) => {
        this.setState(prevState => {
            const updatedTemplates = prevState.templates.filter((_, curIndex) => curIndex !== index);
            return {templates: updatedTemplates};
        }, this.updateBalances);
    };

    addTemplateRow = () => {
        const {selectedYear} = this.props;
        const date = selectedYear === new Date().getFullYear().toString()
            ? new Date(Date.now()) // Use current date for the current year
            : new Date(`${selectedYear}-01-02`); // Use January 1st of the selected year

        this.setState(prevState => {
            const updatedTemplates = [...prevState.templates, {
                date: date, // Use the calculated date
                description: "",
                debit: "0.00",
                credit: "0.00",
                balance: "0.00",
                currency: this.props.selectedCurrency,
                transactionId: null,
                notes : ''
            }];
            return {templates: updatedTemplates};
        }, this.updateBalances);
    };

    updateBalances = () => {
        this.setState(prevState => {
            const updatedTemplates = prevState.templates.map((template, index) => {
                let previousBalance;

                if (index === 0) {
                    // Set the opening balance for the first entry
                    previousBalance = this.parseNumber(template.balance);
                } else {
                    previousBalance = this.parseNumber(prevState.templates[index - 1].balance);
                }

                let debit = template.debit ? this.parseNumber(template.debit) : 0;
                let credit = template.credit ? this.parseNumber(template.credit) : 0;


                let newBalance = previousBalance;


                if (index !== 0) { // Skip balance calculation for the opening balance
                    switch (this.props.ledger.type) {
                        case 'Assets':
                        case 'Expenses':
                            newBalance += (debit - credit);
                            break;
                        case 'Income':
                        case 'Equity':
                        case 'Liabilities':
                            newBalance += (credit - debit);
                            break;
                        default:
                            console.warn(`Unknown ledger type: ${template.type}`);
                    }
                } else if (index === 0 && template.description !== 'Opening Balance') {
                    switch (this.props.ledger.type) {
                        case 'Assets':
                        case 'Expenses':
                            newBalance = (debit - credit);
                            break;
                        case 'Income':
                        case 'Equity':
                        case 'Liabilities':
                            newBalance = (credit - debit);
                            break;
                        default:
                            console.warn(`Unknown ledger type: ${template.type}`);
                    }
                }

                template.balance = this.formatDollar(newBalance.toFixed(2));
                return template;
            });

            return {templates: updatedTemplates};
        });
    }


    handleBlur = (e, index) => {
        let {name, value} = e.target;
        if (name === 'debit' || name === 'credit' || name === 'balance') {
            value = this.formatDollar(value);
        }
        this.setState(prevState => {
            const updatedTemplates = [...prevState.templates];
            updatedTemplates[index] = {
                ...updatedTemplates[index],
                [name]: isNaN(parseFloat(value)) ? "0.00" : this.formatDollar(value, true),
            };
            return {templates: updatedTemplates};
        }, this.updateBalances);
    };

    getPayableTableHeaders = () => {
        let headers = []
        headers.push(Layout.columnHead(null, null,null))
        LEDGER_PAYABLE_ENTRIES_CELLS.forEach((headCell, index) => {
            if(index === 0){
                headers.push(Layout.columnHead(headCell.label, headCell.id, headCell.align,"86.5%"))
            }else{
            headers.push(Layout.columnHead(headCell.label, headCell.id, headCell.align,"auto"))
            }
        })
        headers.push(Layout.columnHead(null, null,null,"200px"))
        return headers
    }

    getTableHeaders = () => {
        let headers = []
        headers.push(Layout.columnHead(null, null,null))
        LEDGER_ENTRIES_CELLS.forEach((headCell, index) => {
            headers.push(Layout.columnHead(headCell.label, headCell.id, headCell.align))
        })
        headers.push(Layout.columnHead(null, null,null))
        return headers
    }

    getStockTableHeaders = () => {
        let headers = []
        headers.push(Layout.columnHead(null, null,null))
        STOCK_IN_HAND_TABLE_CELLS.forEach((headCell, index) => {
            headers.push(Layout.columnHead(headCell.label, headCell.null, headCell.align))
        })
        headers.push(Layout.columnHead(null, null,null))
        return headers
    }

    getCarrierTableHeaders = () => {
        let headers = []
        headers.push(Layout.columnHead(null, null,null))
        CARRIER_TABLE_CELLS.forEach((headCell, index) => {
            headers.push(Layout.columnHead(headCell.label, headCell.id, headCell.align))
        })
        headers.push(Layout.columnHead(null, null,null))
        return headers
    }

    getMarketplaceTableHeaders = () => {
        let headers = []
        headers.push(Layout.columnHead(null, null,null))
        MARKETPLACE_TABLE_CELLS.forEach((headCell, index) => {
            headers.push(Layout.columnHead(headCell.label, headCell.id, headCell.align))
        })
        headers.push(Layout.columnHead(null, null,null))
        return headers
    }

    getFTLTableHeaders = () => {
        let headers = []
        headers.push(Layout.columnHead(null, null,null))
        FTL_CARRIER_TABLE_CELLS.forEach((headCell, index) => {
            headers.push(Layout.columnHead(headCell.label, headCell.id, headCell.align))
        })
        headers.push(Layout.columnHead(null, null,null))
        return headers
    }

    getSupplierTableHeaders = () => {
        let headers = []
        headers.push(Layout.columnHead(null, null,null))
        SUPPLIER_TABLE_CELLS.forEach((headCell, index) => {
            headers.push(Layout.columnHead(headCell.label, headCell.id, headCell.align))
        })
        headers.push(Layout.columnHead(null, null,null))
        return headers
    }

    formatDate = (date) => {
        try {
            const parsedDate = new Date(date);
            if (isNaN(parsedDate)) {
                throw new Error('Invalid Date');
            }
            return format(parsedDate, 'yyyy-MM-dd');
        } catch (e) {
            return date;
        }
    };

    formatBalance = (balance) => {
        return parseFloat(balance)
            .toFixed(2)
            .toString()
            .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    }

    setLedgerEntries = (ledgerEntries) => {
        this.setState({templates: ledgerEntries})
    }

    setStockEntries = (summary) => {
        this.setState({stockSummary: summary})
    }

    getStockTableRow = (row, getRowState, setRowState) => {
        return<StockEntryTableRow
          row={row}
          isEditPayable={this.state.isEditPayable}
          stockSummary={this.state.stockSummary}
          setStockEntries={this.setStockEntries}
          getRowState={getRowState}
          setRowState={setRowState}
          setCurrentPage={this.props.setCurrentPage}
          listingIdBeingEdited={this.state.listingIdBeingEdited}
          setListingIdBeingEdited={(newId) => this.setState({listingIdBeingEdited: newId})}
          year={this.props.selectedYear}
          currency={this.props.selectedCurrency}
          updateEntry={this.props.updateEntry}
          deleteEntry={this.props.deleteEntry}
          ledgerId={this.props.ledger.ledgerId}
          setHideFilterBox={this.setHideFilterBox}
          categories={this.props.categories}
          ledgerType={this.props.ledger.type}

        />
    }

    setCarrierEntries = (summary) => {
        this.setState({carrierSummary: summary})
    }

    getCarrierTableRow = (row, getRowState, setRowState) => {
        return<CarrierEntryTableRow
          row={row}
          isEditPayable={this.state.isEditPayable}
          carrierSummary={this.state.carrierSummary}
          setCarrierEntries={this.setCarrierEntries}
          getRowState={getRowState}
          setRowState={setRowState}
          setCurrentPage={this.props.setCurrentPage}
          listingIdBeingEdited={this.state.listingIdBeingEdited}
          setListingIdBeingEdited={(newId) => this.setState({listingIdBeingEdited: newId})}
          year={this.props.selectedYear}
          currency={this.props.selectedCurrency}
          updateEntry={this.props.updateEntry}
          deleteEntry={this.props.deleteEntry}
          ledgerId={this.props.ledger.ledgerId}
          setHideFilterBox={this.setHideFilterBox}
          categories={this.props.categories}
          ledgerType={this.props.ledger.type}

        />
    }


    setSupplierEntries = (summary) => {
        this.setState({supplierSummary: summary})
    }

    getSupplierTableRow = (row, getRowState, setRowState) => {
        return<SupplierEntryTableRow
          row={row}
          isEditPayable={this.state.isEditPayable}
          supplierSummary={this.state.supplierSummary}
          setSupplierEntries={this.setSupplierEntries}
          getRowState={getRowState}
          setRowState={setRowState}
          setCurrentPage={this.props.setCurrentPage}
          listingIdBeingEdited={this.state.listingIdBeingEdited}
          setListingIdBeingEdited={(newId) => this.setState({listingIdBeingEdited: newId})}
          year={this.props.selectedYear}
          currency={this.props.selectedCurrency}
          updateEntry={this.props.updateEntry}
          deleteEntry={this.props.deleteEntry}
          ledgerId={this.props.ledger.ledgerId}
          setHideFilterBox={this.setHideFilterBox}
          categories={this.props.categories}
          ledgerType={this.props.ledger.type}

        />
    }


    setFTLEntries = (summary) => {
        this.setState({ftlSummary: summary})
    }

    getFTLTableRow = (row, getRowState, setRowState) => {
        return<FTLEntryTableRow
          row={row}
          isEditPayable={this.state.isEditPayable}
          ftlSummary={this.state.ftlSummary}
          setFTLEntries={this.setFTLEntries}
          getRowState={getRowState}
          setRowState={setRowState}
          setCurrentPage={this.props.setCurrentPage}
          listingIdBeingEdited={this.state.listingIdBeingEdited}
          setListingIdBeingEdited={(newId) => this.setState({listingIdBeingEdited: newId})}
          year={this.props.selectedYear}
          currency={this.props.selectedCurrency}
          updateEntry={this.props.updateEntry}
          deleteEntry={this.props.deleteEntry}
          ledgerId={this.props.ledger.ledgerId}
          setHideFilterBox={this.setHideFilterBox}
          categories={this.props.categories}
          ledgerType={this.props.ledger.type}

        />
    }

    setMarketplaceEntries = (summary) => {
        this.setState({marketplaceSummary: summary})
    }

    getMarketplaceTableRow = (row, getRowState, setRowState) => {
        return<MarketplaceEntryTableRow
          row={row}
          isEditPayable={this.state.isEditPayable}
          ftlSummary={this.state.marketplaceSummary}
          setMarketplaceEntries={this.setMarketplaceEntries}
          getRowState={getRowState}
          setRowState={setRowState}
          setCurrentPage={this.props.setCurrentPage}
          listingIdBeingEdited={this.state.listingIdBeingEdited}
          setListingIdBeingEdited={(newId) => this.setState({listingIdBeingEdited: newId})}
          year={this.props.selectedYear}
          currency={this.props.selectedCurrency}
          updateEntry={this.props.updateEntry}
          deleteEntry={this.props.deleteEntry}
          ledgerId={this.props.ledger.ledgerId}
          setHideFilterBox={this.setHideFilterBox}
          categories={this.props.categories}
          ledgerType={this.props.ledger.type}

        />
    }

    getPayableTableRow = (row, getRowState, setRowState,ledgerType) => {
        return<LedgerEntryTableRow
            row={row}
            isEditPayable={this.state.isEditPayable}
            allLedgers={this.state.templates}
            setLedgerEntries={this.setLedgerEntries}
            getRowState={getRowState}
            setRowState={setRowState}
            setCurrentPage={this.props.setCurrentPage}
            listingIdBeingEdited={this.state.listingIdBeingEdited}
            setListingIdBeingEdited={(newId) => this.setState({listingIdBeingEdited: newId})}
            year={this.props.selectedYear}
            currency={this.props.selectedCurrency}
            ledgerType={ledgerType}
            updateEntry={this.props.updateEntry}
            deleteEntry={this.props.deleteEntry}
            ledgerId={this.props.ledger.ledgerId}
            setHideFilterBox={this.setHideFilterBox}
            categories={this.props.categories}

        />
    }

    getTableRow = (row, getRowState, setRowState) => {
        return <React.Fragment>

            <TableCell style={{width: "120px",height:"90px", padding:"0", margin:"0"}}>

            </TableCell>

            <TableCell style={{padding:"0", margin:"0"}}>
                {this.formatDate(row.date)}
            </TableCell>


            <TableCell style={{textAlign: "right", padding: "5px", paddingRight: "50px", width: "auto"}}>
                <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                    {row.description}
                </div>
            </TableCell>


            <TableCell style={{ textAlign: 'right', padding: '5px', paddingRight: '50px', width: 'auto' }}>
                {this.formatBalance(row.debit) !== '0.00' && (
                  <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                      <span style={{ flexShrink: 0 }}>$</span>
                      <span style={{ marginLeft: '55px', minWidth: '80px', textAlign: 'right' }}>
        {this.formatBalance(row.debit)}
      </span>
                  </div>
                )}
            </TableCell>


            <TableCell style={{ textAlign: 'right', padding: '5px', paddingRight: '50px', width: 'auto' }}>
                {this.formatBalance(row.credit) !== '0.00' && (
                  <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                      <span style={{ flexShrink: 0 }}>$</span>
                      <span style={{ marginLeft: '55px', minWidth: '80px', textAlign: 'right' }}>
        {this.formatBalance(row.credit)}
      </span>
                  </div>
                )}
            </TableCell>


            <TableCell style={{textAlign: "right", padding: "5px", paddingRight: "50px", width: "auto"}}>
                <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'left'}}>
                    <span style={{flexShrink: 0}}>$</span>
                    <span style={{marginLeft: '55px', minWidth: '80px', textAlign: 'right'}}>
                    {this.formatBalance(row.balance)}
                </span>
                </div>
            </TableCell>

        </React.Fragment>
    }

    getTotal = () => {
        const totals = this.state.templates.reduce(
          (acc, entry) => {
              const credit = parseFloat(entry.credit) || 0; // Convert credit to a number
              if (entry.description === 'Cost of Sales' ||
                entry.description === 'Accounts Receivables' ||
                entry.description === 'Accounts Payable' ) {
                  acc.costOfSalesTotal += credit;
              } else {
                  acc.otherTotal += credit;
              }
              return acc;
          },
          { costOfSalesTotal: 0, otherTotal: 0 } // Initial accumulator values
        );

        const grandTotal = totals.costOfSalesTotal + totals.otherTotal; // Combine totals
        return { ...totals, grandTotal }; // Return both individual totals and grand total
    };

    handleOpenNotesDialog = (index) => {
        this.setState({
            isDialogOpen: true,
            currentNote: this.state.templates[index].note || '',
            selectedIndex: index,
        });
    };

    handleCloseNotesDialog = () => {
        this.setState({
            isDialogOpen: false,
            currentNote: '',
            selectedIndex: null,
        });
    };

    handleSaveNote = () => {
        const { selectedIndex, currentNote, templates } = this.state;
        if (selectedIndex !== null) {
            const updatedTemplates = [...templates];
            updatedTemplates[selectedIndex].note = currentNote;
            this.setState({ templates: updatedTemplates, isDialogOpen: false, currentNote: '', selectedIndex: null },()=>{
                this.props.handleSave(this.state.templates, this.props.ledger.ledgerId);
            });
        }


    };


    render() {

        const {isDialogOpen, currentNote } = this.state;
        return (
          <React.Fragment>
            <TopToolbar
              pageName={'Ledger - ' + this.props.ledger.name}
              menuItems={this.getContextualMenuItems()}
            />

            <Dialog open={isDialogOpen} onClose={this.handleCloseNotesDialog}>
              <DialogTitle>Edit Note</DialogTitle>
              <DialogContentText style={{ width: '500px' }}></DialogContentText>
              <DialogContent>
                <TextField
                  label="Note"
                  multiline
                  minRows={4}
                  variant="outlined"
                  fullWidth
                  value={currentNote}
                  onChange={(e) =>
                    this.setState({ currentNote: e.target.value })
                  }
                />
              </DialogContent>
              <DialogActions style={{ marginBottom: '16px' }}>
                <Button
                  variant="contained"
                  onClick={this.handleCloseNotesDialog}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.handleSaveNote}
                  style={{ marginLeft: '16px', marginRight: '16px' }}
                >
                  Save
                </Button>
              </DialogActions>
            </Dialog>

            <Box className="box-ledger">
              <FormControl variant="outlined" className="form-control-ledger">
                <InputLabel id="year-label">Year</InputLabel>
                <Select
                  className="select-year-ledger"
                  labelId="year-label"
                  value={this.props.selectedYear}
                  onChange={this.props.handleYearChange}
                  label="Year"
                >
                  {this.props.years.map((year) => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl variant="outlined" className="form-control">
                <InputLabel id="year-label">Currency</InputLabel>
                <Select
                  className="select-currency-ledger"
                  labelId="currency-label"
                  value={this.props.selectedCurrency}
                  onChange={this.props.handleChange}
                  label="Currency"
                >
                  {this.props.currency.map((currency) => (
                    <MenuItem key={currency} value={currency}>
                      {currency}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            {(this.props.ledger.name === 'Cost of Sales' ||
                this.props.ledger.name === 'Accounts Receivables' ||
              this.props.ledger.name === 'Accounts Payable') &&
              this.props.selectedCurrency !== 'Consolidated CAD' && (
                <div style={{ marginLeft: '32px' }}>
                  Last updated: {Utils.formatDateTime(this.props.lastUpdated)}{' '}
                </div>
              )}

            {this.state.isEditEntries ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'left',
                  marginTop: '16px',
                  marginLeft: '24px',
                  marginRight: '24px',
                }}
              >
                <div className="transaction-form-section">
                  {this.state.templates.map((template, index) => (
                    <div
                      className="transaction-form-details"
                      key={index}
                      draggable
                      onDragStart={() => this.onDragStart(index)}
                      onDragOver={() => this.onDragOver(index)}
                      onDragEnd={this.onDragEnd}
                      style={{
                        background:
                          this.state.draggedIndex === index
                            ? '#f0f0f0'
                            : 'white',
                      }}
                    >
                      <div style={{ width: '7%', display: 'flex' }}>
                        <Tooltip title={'Remove Entry'}>
                          <IconButton
                            onClick={() => this.removeTemplateRow(index)}
                          >
                            <RemoveCircleIcon fontSize="small" color="error" />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title={'Notes'}>
                          {this.state.templates[index].note ? (
                            <IconButton
                              onClick={() => this.handleOpenNotesDialog(index)}
                            >
                              <TextSnippetIcon fontSize="small" />
                            </IconButton>
                          ) : (
                            <IconButton
                              onClick={() => this.handleOpenNotesDialog(index)}
                            >
                              <TextSnippetOutlinedIcon fontSize="small" />
                            </IconButton>
                          )}
                        </Tooltip>

                        {template.transactionId === null && (
                          <div className="ledger-circle-icon">M</div>
                        )}
                      </div>

                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          name="date"
                          label="Date"
                          value={template.date}
                          onChange={(date) => {
                              if (!date || isNaN(date.getTime())) {
                                  return;
                              }

                              const options = {
                                  timeZone: 'UTC',
                                  year: 'numeric',
                                  month: '2-digit',
                                  day: '2-digit',
                                  hour: '2-digit',
                                  minute: '2-digit',
                                  second: '2-digit',
                                  hour12: false,
                              };
                              const formatter = new Intl.DateTimeFormat('en-CA', options);
                              const parts = formatter.formatToParts(date);
                              const formattedDate = `${
                                parts.find((p) => p.type === 'year').value
                              }-${parts.find((p) => p.type === 'month').value}-${
                                parts.find((p) => p.type === 'day').value
                              }T${parts.find((p) => p.type === 'hour').value}:${
                                parts.find((p) => p.type === 'minute').value
                              }:${parts.find((p) => p.type === 'second').value}.000Z`;

                              this.handleTemplateDateChange(formattedDate, index, template);
                          }}
                          format="yyyy-MM-dd"
                          variant="inline"
                          inputVariant="outlined"
                          style={{ width: '15%' }}
                          autoOk
                          required
                        />
                      </MuiPickersUtilsProvider>

                      <TextField
                        variant="outlined"
                        name="description"
                        label="Description"
                        style={{ width: '25%' }}
                        InputLabelProps={{ shrink: true }}
                        value={template.description || ''}
                        onChange={(e) =>
                          this.handleTemplateChange(
                            {
                              target: {
                                name: 'description',
                                value: e.target.value,
                              },
                            },
                            index,
                            template
                          )
                        }
                        required
                        error={!template.description}
                      />

                      <TextField
                        variant="outlined"
                        name="debit"
                        label="Debit"
                        style={{ width: '15%' }}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                          inputProps: { style: { textAlign: 'right' } },
                        }}
                        value={this.formatAmount(template.debit)}
                        onChange={(e) =>
                          this.handleTemplateChange(
                            {
                              target: {
                                name: 'debit',
                                value: this.formatDollar(e.target.value),
                              },
                            },
                            index,
                            template
                          )
                        }
                        onBlur={(e) => this.handleBlur(e, index)}
                        required
                        error={!template.debit}
                      />

                      <TextField
                        variant="outlined"
                        name="credit"
                        label="Credit"
                        style={{ width: '15%' }}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                          inputProps: { style: { textAlign: 'right' } },
                        }}
                        value={this.formatAmount(template.credit)}
                        onChange={(e) =>
                          this.handleTemplateChange(
                            {
                              target: {
                                name: 'credit',
                                value: this.formatDollar(e.target.value),
                              },
                            },
                            index,
                            template
                          )
                        }
                        onBlur={(e) => this.handleBlur(e, index)}
                        required
                        error={!template.credit}
                      />

                      <TextField
                        variant="outlined"
                        name="balance"
                        style={{ width: '15%' }}
                        label="Balance"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                          inputProps: { style: { textAlign: 'right' } },
                        }}
                        value={this.formatAmount(template.balance)}
                        onChange={(e) =>
                          this.handleTemplateChange(
                            {
                              target: {
                                name: 'balance',
                                value: this.formatDollar(e.target.value),
                              },
                            },
                            index,
                            template
                          )
                        }
                        onBlur={(e) => this.handleBlur(e, index)}
                        required
                        error={!template.balance}
                      />

                      <MenuIcon
                        style={{
                          cursor: 'grab',
                          marginLeft: '8px',
                          opacity: '0.6',
                          alignSelf: 'center',
                        }}
                      />
                    </div>
                  ))}
                  <div style={{ marginTop: '30px' }}>
                    <IconButton onClick={() => this.addTemplateRow()}>
                      <AddCircleIcon fontSize="small" color="secondary" />
                    </IconButton>
                    <IconButton onClick={() => this.fetchLedgerEntries()}>
                      <RefreshIcon fontSize="small" color="green" />
                    </IconButton>
                  </div>
                </div>
              </div>
            ) : this.props.ledger.name !== 'Accounts Payable' &&
              this.props.ledger.name !== 'Accounts Receivables' &&
              this.props.ledger.name !== 'Cost of Sales' ? (
              <div style={{ marginTop: '-7px' }}>
                <TablePage
                  tableName="LedgerEntriesTable"
                  tableColumnHeads={this.getTableHeaders()}
                  tableRows={this.state.templates}
                  tableRow={(row, index, getRowState, setRowState) =>
                    this.getTableRow(row, getRowState, setRowState)
                  }
                  getRowId={(row) => row.transactionId}
                  paginationText="Ledgers entries per page"
                />
              </div>
            ) : (
              <div style={{ marginTop: '24px' }}>
                {this.state.stockSummary &&
                  this.state.stockSummary.length > 0 && (
                    <div style={{ marginTop: '-40px', marginBottom: '24px' }}>
                      <TablePage
                        type={'stockLedger'}
                        tableName="stockLedger"
                        tableColumnHeads={this.getStockTableHeaders()}
                        tableRows={this.state.stockSummary}
                        tableRow={(row, index, getRowState, setRowState) =>
                          this.getStockTableRow(row, getRowState, setRowState)
                        }
                        getRowId={(row) => row.id}
                      />
                    </div>
                  )}

                {this.state.carrierSummary &&
                  this.state.carrierSummary.length > 0 && (
                    <div style={{ marginTop: '-40px', marginBottom: '24px' }}>
                      <TablePage
                        type={'carrierLedger'}
                        tableName="standardCarrier"
                        tableColumnHeads={this.getCarrierTableHeaders()}
                        defaultSortBy={'carrierName'}
                        tableRows={this.state.carrierSummary}
                        tableRow={(row, index, getRowState, setRowState) =>
                          this.getCarrierTableRow(row, getRowState, setRowState)
                        }
                        getRowId={(row) => row.id}
                      />

                      {this.state.isEditPayable && (
                        <div style={{ marginTop: '-18px' }}>
                          <div className="divider-ledger" />
                          <Tooltip
                            title="Add Standard Carrier"
                            style={{ marginLeft: '0px', marginTop: '8px' }}
                          >
                            <IconButton
                              onClick={() => {
                                this.setState(
                                  { listingIdBeingEdited: '' },
                                  () => {
                                    const newCarrierEntry = [
                                      ...this.state.carrierSummary,
                                      {
                                        id: '',
                                        carrierName: '',
                                        CY_SUBTOTAL_PAYABLE: '',
                                      },
                                    ];
                                    this.setState({
                                      carrierSummary: newCarrierEntry,
                                    });
                                  }
                                );
                              }}
                              disabled={this.state.carrierSummary.some(
                                (carrier) => carrier.id === ''
                              )}
                            >
                              <AddCircleIcon
                                fontSize="small"
                                color="secondary"
                              />
                            </IconButton>
                          </Tooltip>
                        </div>
                      )}
                    </div>
                  )}

                {this.state.ftlSummary && this.state.ftlSummary.length > 0 && (
                  <div style={{ marginTop: '-40px', marginBottom: '24px' }}>
                    <TablePage
                      defaultSortBy={'carrierName'}
                      type={'ftlLedger'}
                      tableName="FTLCarrier"
                      tableColumnHeads={this.getFTLTableHeaders()}
                      tableRows={this.state.ftlSummary}
                      tableRow={(row, index, getRowState, setRowState) =>
                        this.getFTLTableRow(row, getRowState, setRowState)
                      }
                      getRowId={(row) => row.id}
                    />

                    {this.state.isEditPayable && (
                      <div style={{ marginTop: '-18px' }}>
                        <div className="divider-ledger" />
                        <Tooltip
                          title="Add LTL/FTL Carrier"
                          style={{ marginLeft: '0px', marginTop: '8px' }}
                        >
                          <IconButton
                            onClick={() => {
                              this.setState(
                                { listingIdBeingEdited: '' },
                                () => {
                                  const newEntry = [
                                    ...this.state.ftlSummary,
                                    {
                                      id: '',
                                      carrierName: '',
                                      CY_SUBTOTAL_PAYABLE: '',
                                    },
                                  ];
                                  this.setState({ ftlSummary: newEntry });
                                }
                              );
                            }}
                            disabled={this.state.ftlSummary.some(
                              (carrier) => carrier.id === ''
                            )}
                          >
                            <AddCircleIcon fontSize="small" color="secondary" />
                          </IconButton>
                        </Tooltip>
                      </div>
                    )}
                  </div>
                )}

                {this.state.supplierSummary &&
                  this.state.supplierSummary.length > 0 && (
                    <div style={{ marginTop: '-40px', marginBottom: '24px' }}>
                      <TablePage
                        type={'supplierLedger'}
                        tableName="Supplier"
                        tableColumnHeads={this.getSupplierTableHeaders()}
                        tableRows={this.state.supplierSummary}
                        tableRow={(row, index, getRowState, setRowState) =>
                          this.getSupplierTableRow(
                            row,
                            getRowState,
                            setRowState
                          )
                        }
                        getRowId={(row) => row.id}
                        defaultSortBy={'supplierName'}
                      />

                      {this.state.isEditPayable && (
                        <div style={{ marginTop: '-18px' }}>
                          <div className="divider-ledger" />
                          <Tooltip
                            title="Add Supplier"
                            style={{ marginLeft: '0px', marginTop: '8px' }}
                          >
                            <IconButton
                              onClick={() => {
                                this.setState(
                                  { listingIdBeingEdited: '' },
                                  () => {
                                    const newEntry = [
                                      ...this.state.supplierSummary,
                                      {
                                        id: '',
                                        supplierName: '',
                                        CY_SUBTOTAL_PAYABLE: '',
                                      },
                                    ];
                                    this.setState({
                                      supplierSummary: newEntry,
                                    });
                                  }
                                );
                              }}
                              disabled={this.state.supplierSummary.some(
                                (supplier) => supplier.id === ''
                              )}
                            >
                              <AddCircleIcon
                                fontSize="small"
                                color="secondary"
                              />
                            </IconButton>
                          </Tooltip>
                        </div>
                      )}
                    </div>
                  )}

                  {this.state.marketplaceSummary && this.state.marketplaceSummary.length > 0 && (
                    <div style={{ marginTop: '-40px', marginBottom: '24px' }}>
                        <TablePage
                          defaultSortBy={'marketplace'}
                          type={'marketplaceSummary'}
                          tableName="MarketplaceSummary"
                          tableColumnHeads={this.getMarketplaceTableHeaders()}
                          tableRows={this.state.marketplaceSummary}
                          tableRow={(row, index, getRowState, setRowState) =>
                            this.getMarketplaceTableRow(row, getRowState, setRowState)
                          }
                          getRowId={(row) => row.id}
                        />

                        {this.state.isEditPayable && (
                          <div style={{ marginTop: '-18px' }}>
                              <div className="divider-ledger" />
                              <Tooltip
                                title="Add Marketplace"
                                style={{ marginLeft: '0px', marginTop: '8px' }}
                              >
                                  <IconButton
                                    onClick={() => {
                                        this.setState(
                                          { listingIdBeingEdited: '' },
                                          () => {
                                              const newEntry = [
                                                  ...this.state.marketplaceSummary,
                                                  {
                                                      id: '',
                                                      marketplace: '',
                                                      amount: '',
                                                  },
                                              ];
                                              this.setState({ marketplaceSummary: newEntry });
                                          }
                                        );
                                    }}
                                    disabled={this.state.marketplaceSummary.some(
                                      (data) => data.id === ''
                                    )}
                                  >
                                      <AddCircleIcon fontSize="small" color="secondary" />
                                  </IconButton>
                              </Tooltip>
                          </div>
                        )}
                    </div>
                  )}

                {/*{this.props.fetchTransactions?.ledgerEntries &&*/}
                {/*  this.props.fetchTransactions.ledgerEntries.length > 0 && (*/}
                    <div style={{ marginTop: '-40px', marginBottom: '24px' }}>
                      <TablePage
                        type="LedgerEntriesTable"
                        tableName="LedgerEntriesTable"
                        tableColumnHeads={this.getPayableTableHeaders()}
                        tableRows={this.state.templates}
                        tableRow={(row, index, getRowState, setRowState) =>
                          this.getPayableTableRow(row, getRowState, setRowState)
                        }
                        getRowId={(row) => row.transactionId}
                      />

                      {this.state.isEditPayable && (
                        <Tooltip
                          title="Add Entry"
                          style={{ marginLeft: '0px', marginTop: '8px' }}
                        >
                          <IconButton
                            onClick={() => {
                              this.setState(
                                { listingIdBeingEdited: '' },
                                () => {
                                  const newLedgerEntry = [
                                    ...this.state.templates,
                                    {
                                      transactionId: '',
                                      description: '',
                                      amount: '',
                                    },
                                  ];
                                  this.setState({ templates: newLedgerEntry });
                                }
                              );
                            }}
                            disabled={this.state.templates.some(
                              (template) => template.transactionId === ''
                            )}
                          >
                            <AddCircleIcon fontSize="small" color="secondary" />
                          </IconButton>
                        </Tooltip>
                      )}

                      {this.state.templates &&
                        Array.isArray(this.state.templates) &&
                        this.state.templates.length > 0 &&
                        this.state.templates.some(
                          (entry) =>
                            entry.description !== 'Cost of Sales' &&
                            entry.description !== 'Accounts Receivables' &&
                            entry.description !== 'Accounts Payable'
                        ) && (
                          <div style={{ paddingTop: '14px', height: '50px' }}>
                            <TableCell
                              style={{ width: '24px', height: '50px' }}
                            ></TableCell>

                            <TableCell
                              style={{
                                textAlign: 'right',
                                padding: '5px',
                                paddingRight: '50px',
                                width: '100%',
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'flex-start',
                                  alignItems: 'center',
                                  fontWeight: 'bold',
                                }}
                              >
                                Total
                              </div>
                            </TableCell>

                            <TableCell
                              style={{
                                textAlign: 'right',
                                padding: '5px',
                                paddingRight: '50px',
                                width: 'auto',
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'flex-start',
                                  alignItems: 'center',
                                }}
                              >
                                <span style={{ flexShrink: 0 }}>$</span>
                                <span
                                  style={{
                                    marginLeft: '55px',
                                    minWidth: '80px',
                                    textAlign: 'right',
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {this.formatBalance(
                                    this.getTotal().otherTotal
                                  )}
                                </span>
                              </div>
                            </TableCell>

                            <TableCell style={{ width: '200px' }}></TableCell>
                          </div>
                        )}

                      {this.state.templates &&
                        this.state.templates.length > 0 &&
                        this.state.templates.some(
                          (entry) =>
                            entry.description === 'Cost of Sales' ||
                            entry.description === 'Accounts Receivables' ||
                            entry.description === 'Accounts Payable'
                        ) && (
                          <div style={{ paddingTop: '24px', height: '50px' }}>
                            <TableCell
                              style={{ width: '24px', height: '50px' }}
                            ></TableCell>

                            <TableCell
                              style={{
                                textAlign: 'right',
                                padding: '5px',
                                paddingRight: '50px',
                                width: '100%',
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'flex-start',
                                  alignItems: 'center',
                                  fontWeight: 'bold',
                                }}
                              >
                                {this.props.ledger.name + ' Total'}
                              </div>
                            </TableCell>

                            <TableCell
                              style={{
                                textAlign: 'right',
                                padding: '5px',
                                paddingRight: '50px',
                                width: 'auto',
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'flex-start',
                                  alignItems: 'center',
                                }}
                              >
                                <span style={{ flexShrink: 0 }}>$</span>
                                <span
                                  style={{
                                    marginLeft: '55px',
                                    minWidth: '80px',
                                    textAlign: 'right',
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {this.formatBalance(
                                    this.getTotal().grandTotal
                                  )}
                                </span>
                              </div>
                            </TableCell>

                            <TableCell style={{ width: '200px' }}></TableCell>
                          </div>
                        )}
                    </div>
                 {/*)}*/}
              </div>
            )}
          </React.Fragment>
        );
    }
}

export default EditLedger;

