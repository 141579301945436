import React from "react";
import {Button, Paper, ClickAwayListener, TextField} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import PropTypes from "prop-types";
import withShipment from "../../../../withShipment";
import {setShippingTemplate, setSkuLocation} from "../../../../redux/actions/settings";
import {SET_TEMPLATE_EXCEEDS_MAX_ERROR, SET_TEMPLATE_NO_TEMPLATE_SELECTED_ERROR} from "../constants";
import {ERROR} from "../../ManageUsers/constants";


class SetShippingTemplatePopper extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedTemplate: null,
        }
    }

    handleClose = () => {
        this.props.handleClosePopper()
    }


    handleSave = () => {
        if (this.props.skus.length > 5) {
            this.props.openFeedBackBar(ERROR, SET_TEMPLATE_EXCEEDS_MAX_ERROR)
        }
        else if (!this.state.selectedTemplate){
            this.props.openFeedBackBar(ERROR, SET_TEMPLATE_NO_TEMPLATE_SELECTED_ERROR)
        }
        else {
            const data = {
                skus: this.props.skus,
                selectedTemplate: this.state.selectedTemplate,
            }
            this.props.setShippingTemplate(data)
            this.props.handleClosePopper()
            this.props.handlePopperClose()
        }
    }

    render() {
        return (
            <>
                <ClickAwayListener onClickAway={this.handleClose}>
                    <Paper className="import-popper-container">
                        <div className="import-popper-item">
                            <Autocomplete
                                options={this.props.templates}
                                getOptionLabel={(option) => option.shippingTemplateName ? option.shippingTemplateName : ""}
                                value={this.state.selectedTemplate}
                                onChange={(e, value) => this.setState({selectedTemplate: value})}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        name="template"
                                        label="Select a Template"
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                }
                                style={{width: "250px"}}
                            />
                        </div>
                        <div className="supplier-payment-popper-buttons">
                            <Button variant='contained' onClick={this.handleClose} style={{width: '100px'}}>Close</Button>
                            <Button variant='contained' onClick={this.handleSave} color="primary" style={{width: '100px'}}>Save</Button>
                        </div>
                    </Paper>
                </ClickAwayListener>
            </>
        )
    }
}

const actionCreators = {
    setShippingTemplate,
}


SetShippingTemplatePopper.propTypes = {
    templates: PropTypes.array
}

SetShippingTemplatePopper.defaultProps = {
    templates: [],
}

export default withShipment({
    actionCreators
}, SetShippingTemplatePopper);