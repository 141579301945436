import React from "react";


// material components
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Button, TextField } from "@material-ui/core";

// custom components
import withShipment from "../../../withShipment";
import RenderPurchaseOrderPDFPreview from "./RenderPurchaseOrderPDFPreview";
import CreateCustomerPurchaseOrderTable from "./CreateCustomerPurchaseOrderTable";

// constants
import { CREATE_PURCHASE_ORDER, MAX_NOTES_LINES, NOTES, PURCHASE_ORDER } from "./constants";

// redux
import {
    cachePDFpreview,
    createNewPurchaseOrder,
    fetchAllSuppliers, getPDFpreview, createSendNewPurchaseOrder
} from "../../../redux/actions/purchaseOrders";
import { PDFpreviewReadySelector } from "../../../redux/selectors/purchaseOrders";

// styling
import './CreateCustomerPurchaseOrder.css';
import PropTypes from "prop-types";
import {listCustomers} from "../../../redux/actions/customer";

class EditCustomerPurchaseOrder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedCustomerIndex: 0,
            numRows: 0,
            availableProducts: [],
            availableCustomerNames: [],
            key: 0,
            showNotes: false,
            notes: "",
            productsAndPackages:[]
        };

        this.props.handleLocationChange((this.props.purchaseOrder.toShipAddressId && this.props.locations?.find(
                (location) => location.shipAddressId === this.props.purchaseOrder.toShipAddressId))
            ?? this.props.selectedLocation, false)

        this.baseState = {
            selectedCustomerIndex: 0,
            numRows: 0,
            availableProducts: [],
            availableCustomerNames: [],
            showNotes: false
        };
    }

    initDate = () => {
        const today = new Date();
        const tomorrow = new Date(today);
        tomorrow.setDate(tomorrow.getDate() + 1);
        return tomorrow
    }

    componentDidMount() {
        this.onHistoricalChange(this.props.purchaseOrder.isHistorical)
        this.onShipByChange(this.props.purchaseOrder.isSameAsBillingAddress)
        this.onCustomerChange(this.props.purchaseOrder?.customer?.[0]?.name)
        this.props.updateField("customerId", this.props.purchaseOrder.customerId)
        let notes = this.props.purchaseOrder.notes ?? this.props.notes
        this.props.updateField("notes", notes);

        let index = this.props.customers.findIndex((customer) => customer.name === this.props.selectedCustomer);

        let availableProds = this.props.customers[index]?.products?.map((product) => {
            return product.shortcode;
        });

        let a = this.props.purchaseOrder.orderItems?.map((entry, i) => {
            let isQtyReceived = true;
            if (entry.quantityShipped === "0" || entry.quantityShipped === 0) {
                isQtyReceived = false
            }
            this.addEntry();
            this.addPreviousShortcodeChange(entry.shortcode, i, index, parseInt(entry.quantityOrdered), isQtyReceived, entry.price, entry.discountPercentage, entry.skidsOrdered)
        })

        let customerNames = this.props.customers.map((val) => {
            if (val.name) {
                return val.name;
            }
        });
        this.props.updateField("samePreview", false)

        this.handleDateChange(new Date(this.props.purchaseOrder.expectedDate))

        this.handlePOChange(this.props.purchaseOrder.poNumber)

        this.handleHistoricalChange(this.props.purchaseOrder.isHistorical)

        this.handleShipByChange(this.props.purchaseOrder.isSameAsBillingAddress)


        this.handleCreateDateChange(new Date(this.props.purchaseOrder.createDate))

        this.setState({
            availableProducts: availableProds,
            selectedCustomerIndex: index,
            availableCustomerNames: customerNames,
            notes: notes
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.selectedCustomer !== this.props.selectedCustomer) {
            // update pre-populated fields when customer is selected
            // go through all entries and update values
            if (this.props.selectedCustomer === '')
                return;
            let index = this.props.customers.findIndex((customer) => customer.name === this.props.selectedCustomer);

            let availableProds = this.props.customers[index]?.products?.map((product) => {
                return product.shortcode;
            });

            let customerId = this.props.customers[index].customerId;

            this.props.updateField("customerId", customerId)
            this.setState({
                ...this.baseState,
                //selectedCustomer: this.state.selectedCustomer,
                selectedCustomerIndex: index,
                availableProducts: availableProds,
                //customerId: customerId,
                availableCustomerNames: this.state.availableCustomerNames,
                key: prevState.key + 1,
                //notes: this.state.notes,
                //samePreview: false
            });
        }
        if (this.state.selectedCustomerIndex && this.state.selectedCustomerIndex !== prevState.selectedCustomerIndex && this.props.customers) {
            let customer =this.props.customers[this.state.selectedCustomerIndex]
            if (customer) {
                this.setState({
                    productsAndPackages: [...customer?.internalPackagingInfo ?? [], ...customer?.internalProductInfo ?? []]
                })
            }
        }
    }

    addEntry = () => {
        let curEntries = this.props.entries;
        this.props.hasPricingRole ?
            curEntries.push({
                shortcode: '',
                partNum: '',
                description: '',
                discount: this.props.discountPercentage,
                price: '',
                quantity: 0,
                skid: '',
                deleteEntry: true
            })
            :
            curEntries.push({
                shortcode: '',
                partNum: '',
                description: '',
                discount: this.props.discountPercentage,
                quantity: 0,
                skid:'',
                deleteEntry: true
            })
        this.props.updateField("entries", curEntries)
        this.props.updateField("samePreview", false)
        this.setState({
            numRows: this.state.numRows + 1,
            key: this.state.key + 1,
        });

    }

    removeEntry = (index) => {
        let curEntries = this.props.entries;
        curEntries.splice(index, 1);
        this.props.updateField("entries", curEntries)
        this.props.updateField("samePreview", false)
        this.setState({
            numRows: this.state.numRows - 1,
        });
    }

    addPreviousShortcodeChange = (shortcode, index, customerIndex, qty, isQtyRecieved, price, discount, skids) => {
        let curEntries = this.props.entries;

        let internalProduct = this.props.customers[customerIndex]?.internalProductInfo.find((product) => {
            return product.shortcode === shortcode
        })
        if (!internalProduct) {
            internalProduct = this.props.customers[this.state.selectedCustomerIndex].internalPackagingInfo.find((product) => {
                return product.shortcode === shortcode
            })
        }

        let customerProduct = this.props.customers[customerIndex]?.products?.find((product) => {
            return product.shortcode === shortcode
        })

        curEntries[index] = this.props.hasPricingRole ? {
                ...curEntries[index],
                shortcode: shortcode ? shortcode : '',
                partNum: customerProduct ? customerProduct?.customerPartNumber : '',
                description: internalProduct ? internalProduct?.textDescription : '',
                discount: discount,
                price: price,
                quantity: qty,
                deleteEntry: !isQtyRecieved,
                skid:skids
            }
            :
            {
                ...curEntries[index],
                shortcode: shortcode ? shortcode : '',
                partNum: customerProduct ? customerProduct?.customerPartNumber : '',
                description: internalProduct ? internalProduct?.textDescription : '',
                discount: discount,
                quantity: qty,
                deleteEntry: !isQtyRecieved,
                skid:skids
            }

        this.props.updateField("entries", curEntries)
    }

    onShortcodeChange = (shortcode, index) => {
        // update the entries
        let curEntries = this.props.entries;

        let internalProduct = this.props.customers[this.state.selectedCustomerIndex]?.internalProductInfo.find((product) => {
            return product.shortcode === shortcode
        })
        if (!internalProduct) {
            internalProduct = this.props.customers[this.state.selectedCustomerIndex].internalPackagingInfo.find((product) => {
                return product.shortcode === shortcode
            })
        }

        let customerProduct = this.props.customers[this.state.selectedCustomerIndex]?.products?.find((product) => {
            return product.shortcode === shortcode
        })
        curEntries[index] = this.props.hasPricingRole ? {
                ...curEntries[index],
                shortcode: shortcode ? shortcode : '',
                partNum: customerProduct ? customerProduct?.customerPartNumber : '',
                description: internalProduct ? internalProduct?.textDescription : '',
                discount: this.props.discountPercentage,
                price: customerProduct ? customerProduct.price : '',
                skid:0,
                quantity: 0,
            }
            :
            {
                ...curEntries[index],
                shortcode: shortcode ? shortcode : '',
                partNum: customerProduct ? customerProduct?.customerPartNumber : '',
                description: internalProduct ? internalProduct?.textDescription : '',
                discount: this.props.discountPercentage,
                skid:0,
                quantity: 0,
            }

        this.props.updateField("entries", curEntries)
    }



    onCustomerChange = (value) => {
        this.props.updateField("selectedCustomer", value)
    }

    checkForNullDiscount = (entries) => {
        for (const entry of entries) {
            if (entry.discount === "") {
                this.props.setPreviewable(false)
                return
            }
        }
        this.props.setPreviewable(true&&this.props.discountPercentage!=="")
    }

    onChange = (e, index) => {
        let curEntries = this.props.entries;
        let orderEntry = {}
        for (const entry of this.props.purchaseOrder.orderItems) {
            if (entry.shortcode === curEntries[index].shortcode) {
                orderEntry = entry;
                break;
            }
        }
        if (e.target.value >= 0) {
            curEntries[index][e.target.name] = e.target.value;
            const customer = this.props.customers[this.state.selectedCustomerIndex]
            const productsAndPackages = [
                ...(customer?.internalPackagingInfo ?? []),
                ...(customer?.internalProductInfo ?? [])
            ]

            if (e.target.name === "skid") {
                let ratio = productsAndPackages.find((entry)=> entry?.shortcode === curEntries[index]["shortcode"])?.paleteQuantity
                if (ratio)
                    curEntries[index]["quantity"] = e.target.value * ratio
            }
            if (e.target.name === "quantity") {
                let ratio = productsAndPackages.find((entry)=> entry?.shortcode === curEntries[index]["shortcode"])?.paleteQuantity
                if (ratio)
                    curEntries[index]["skid"] = Number((e.target.value / ratio).toFixed(2))
            }
            this.props.updateErrQuan(curEntries, index);
            this.props.updateField("entries", curEntries);
        }
        this.checkForNullDiscount(curEntries)
    }

    formatPrice = (price, index) => {
        let curEntries = this.props.entries;
        if (Number(price) || Number(price) === 0) {
            curEntries[index]["price"] = Number(price).toFixed(2).toString();
            this.props.updateField("entries", curEntries);
        }
    }

    onNotesChange = () => {
        this.props.updateField("notes", this.state.notes)
    }

    handleNotesChange = (e) => {
        let newLines = e.target.value.split("\n").length
        if (newLines <= MAX_NOTES_LINES) {
            this.setState({
                notes: e.target.value
            })
        }
    }

    resetNotesField = () => {
        this.setState({
            notes: this.props.notes
        })
    }

    handleDateChange = (date) => {
        this.props.updateField("expectedDate", date)
    }
    handleCreateDateChange = (date) => {
        this.props.updateField("createDate", date)
    }



    handlePOChange = (value) => {
        this.props.updateField("poNumber", value)
    }

    handleHistoricalChange = (value) => {
        this.props.updateField("isHistorical", value)
    }

    onHistoricalChange = (value) => {
        this.props.updateField("isHistorical", value)
    }
    onShipByChange = (value) => {
        this.props.updateField("isSameAsBillingAddress", value)
    }

    handleShipByChange = (value) => {
        this.props.updateField("isSameAsBillingAddress", value)
    }

    handleShipToAddressChange = (name,value) => {
        this.props.updateShipToAddress(name, value)
    }

    showNotes = () => {
        this.setState({
            showNotes: true
        })
    }

    handleNotesClose = () => {
        this.setState({
            showNotes: false
        })
    }

    getAvailableLocations = () => {
        if (this.props.selectedCustomer === '')
            return;
        let index = this.props.customers.findIndex((customer) => customer.name === this.props.selectedCustomer);

        let availableProds = this.props.customers[index]?.products?.map((product) => {
            return product.shortcode;
        });

        let customerId = this.props.customers[index].customerId;

        this.props.updateField("customerId", customerId)
        this.setState({
            ...this.baseState,
            selectedCustomerIndex: index,
            availableProducts: availableProds,
            availableCustomerNames: this.state.availableCustomerNames,
            key: this.state.key + 1,
        });
    }


    render() {
        return (
            <>
                {this.props.previewing &&
                    <RenderPurchaseOrderPDFPreview
                        //allowSave={true}
                        data={this.props.pdfData}
                        //handlePreviewOpen={this.handlePreviewOpen}
                        //handlePreviewClose={this.handlePreviewClose}
                        //open={this.state.previewOpen}
                        handleSubmit={this.handleSubmit}
                        creating={true}
                        handlePdfLoadSuccess={this.props.handlePdfLoadSuccess}
                        handlePdfLoadError={this.props.handlePdfLoadError}
                        pdfLoadSuccess={this.props.pdfLoadSuccess}
                        scale={this.props.scale}
                    /> ||
                    <>
                        <Dialog open={this.state.showNotes} onClose={this.handleNotesClose} fullWidth={true}>
                            <DialogTitle>
                                {NOTES} (Max {MAX_NOTES_LINES} Lines)
                            </DialogTitle>
                            <DialogContent style={{ textAlign: "center" }}>
                                <TextField
                                    variant="outlined"
                                    name="notes"
                                    value={this.state.notes}
                                    onChange={this.handleNotesChange}
                                    style={{ width: '100%' }}
                                    multiline
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => {
                                    this.resetNotesField();
                                    this.handleNotesClose()
                                }}>
                                    Exit
                                </Button>
                                <Button onClick={() => {
                                    this.onNotesChange();
                                    this.handleNotesClose()
                                }
                                } color="primary">
                                    Confirm
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <CreateCustomerPurchaseOrderTable
                            selectedCustomer={this.props.selectedCustomer}
                            availableProducts={this.state.availableProducts}
                            availableCustomerNames={this.state.availableCustomerNames}
                            //pdfData={this.props.PDFpreviewReady}
                            handleSubmit={this.handleSubmit}
                            handleSendSubmit={this.handleSendSubmit}
                            //openPreview={this.state.previewOpen}
                            entries={this.props.entries}
                            numRows={this.state.numRows}
                            addEntry={this.addEntry}
                            removeEntry={this.removeEntry}
                            onShortcodeChange={this.onShortcodeChange}
                            onCustomerChange={this.onCustomerChange}
                            onHistoricalChange={this.onHistoricalChange}
                            onShipByChange={this.onShipByChange}
                            shipToAddress={this.props.shipToAddress}
                            onNotesChange={this.onNotesChange}
                            onChange={this.onChange}
                            tableKey={this.state.key}
                            handleDateChange={this.handleDateChange}
                            expectedDate={this.props.expectedDate}
                            handleCreateDateChange={this.handleCreateDateChange}
                            handlePOChange={this.handlePOChange}
                            handleHistoricalChange={this.handleHistoricalChange}
                            handleShipByChange={this.handleShipByChange}
                            handleShipToAddressChange={this.handleShipToAddressChange}
                            createDate={this.props.createDate}
                            isHistorical={this.props.isHistorical}
                            isSameAsBillingAddress={this.props.isSameAsBillingAddress}
                            poNumber={this.props.poNumber}
                            showNotes={this.showNotes}
                            //previewPDF={this.previewPDF}
                            //previewClose={this.handlePreviewClose}
                            handleCancel={this.props.handleCancel}
                            //changeHeader={this.props.changeHeader}
                            purchaseOrder={this.props.purchaseOrder}
                            orderItems={this.props.purchaseOrder.orderItems}
                            fetchAvailableShortcodes={this.getAvailableLocations}
                            selectedLocation={this.props.selectedLocation}
                            locations={this.props.locations}
                            handleLocationChange={this.props.handleLocationChange}
                            taxPercentage={this.props.taxPercentage}
                            handleTaxChange={this.props.handleTaxChange}
                            discountPercentage={this.props.discountPercentage}
                            handleDiscountChange={this.props.handleDiscountChange}
                            editingPurchaseOrder={true}
                            updateErrQuan={this.props.updateErrQuan}
                            errEditQty={this.props.errEditQty}
                            hasPricingRole={this.props.hasPricingRole}
                            formatPrice={this.formatPrice}
                            selectedCustomerInfo={this.props.selectedCustomerInfo}

                        />

                    </>
                }
            </>
        )
    }
}

const mapStateToProps = (state) => ({
})

const actionCreators = {
    fetchAllSuppliers,
    createNewPurchaseOrder,
    createSendNewPurchaseOrder,
    listCustomers
}


EditCustomerPurchaseOrder.propTypes = {
    customers: PropTypes.array
}

EditCustomerPurchaseOrder.defaultProps = {
    customers: []
}


export default withShipment({
    mapStateToProps,
    actionCreators
}, EditCustomerPurchaseOrder);