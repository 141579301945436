import {
    STORE_ALL_SUPPLIER_INVOICES,
    STORE_SUPPLIER_INVOICE_PDF,
    STORE_SUPPLIER_INVOICE_TEMPLATE_PDF,
    STORE_SUPPLIER_INVOICE_TEMPLATES,
    STORE_SUPPLIERS_WITHOUT_TEMPLATE,
    STORE_CARRIER_INVOICE_TEMPLATES,
    STORE_CARRIERS_WITHOUT_TEMPLATE,
    STORE_CARRIER_INVOICE_TEMPLATE_PDF,
    STORE_ALL_CARRIER_INVOICES,
    STORE_CARRIER_INVOICE_PDF,
    STORE_UPDATED_CARRIER_INVOICES,
    STORE_REPROCESSED_CARRIER_INVOICES,
    STORE_UPDATED_SUPPLIER_INVOICE,
    STORE_DATE_FORMATS,
    SET_PROCESSING,
    STORE_SUPPLIER_INVOICE_PO,
    SET_INVOICE_SMART_SEARCH_ROWS,
    SET_INVOICE_ITEM_SMART_SEARCH_DETAILS,
    REMOVE_DELETED_CARRIER_INVOICES,
    STORE_UPDATED_INVOICE_SMART_SEARCH_ROWS,
    STORE_UPDATED_INVOICE_ITEM_SMART_SEARCH_DETAILS,
    UPDATE_SUPPLIER_INVOICE_CHANGED,
    STORE_SUPPLIER_INVOICE_OPERATION_RESPONSE,
    STORE_CARRIER_INVOICE_OPERATION_RESPONSE,
    CLEAR_CARRIER_INVOICE_OPERATION_RESPONSE,
    REMOVE_DELETED_SUPPLIER_INVOICES,
    STORE_FTL_CARRIER_INVOICES,
    STORE_PROCESSED_FTL_CARRIER_INVOICE,
    REMOVE_DELETED_FTL_CARRIER_INVOICE,
    UPDATE_FTL_CARRIER_INVOICE_STATUS,
    STORE_REPROCESSED_FTL_CARRIER_INVOICE,
    UPDATE_CARRIER_INVOICE_STATUS,
    STORE_UPDATED_CARRIER_INVOICE_STATUS,
    STORE_SUPPLIER_INVOICE, UPDATE_CARRIER_INVOICE_LOADING_STATUS
} from "../../constants/invoices";

const initialState = {
    supplierInvoices: [],
    supplierInvoiceChanged: false,
    supplierInvoicePDF: new Uint8Array(0),
    operationResponse: "",
    supplierInvoiceTemplates: [],
    suppliersWithoutTemplate: [],
    supplierInvoiceTemplatePDF: new Uint8Array(0),

    carrierInvoiceTemplates: [],
    carriersWithoutTemplate: [],
    carrierInvoiceTemplatePDF: new Uint8Array(0),

    carrierInvoices: [],
    carrierInvoicePDF: new Uint8Array(0),

    dateFormats: [],

    processing: false,
    carrierInvoiceLoadingStatus: false,
    purchaseOrder: {},
    invoiceSmartSearchRows: [],
    invoiceItemSmartSearchDetails: [],
    carrierInvoiceOperationResponse: "",
    ftlCarrierInvoices: [],
}

const updateInvoices = (oldInvoices, updatedInvoice) => {
    let newList = [];
    let exists = oldInvoices.findIndex(item => item.invoiceNumber === updatedInvoice.invoiceNumber);
    let supplierInvoiceChanged = false;
    if (exists === -1) {
        return {invoices:[oldInvoices,updatedInvoice], supplierInvoiceChanged:supplierInvoiceChanged};
    } else {
        oldInvoices.map((item, index) => {
            if (index === exists) {
                supplierInvoiceChanged = true;
                newList[index] = updatedInvoice;
            } else {
                newList[index] = item;
            }
        })
        return {invoices:newList, supplierInvoiceChanged:supplierInvoiceChanged};
    }
}

export default (state = initialState, action) => {
    switch (action.type) {
        case STORE_ALL_SUPPLIER_INVOICES:
            return { ...state, supplierInvoices: action.invoices }
        case STORE_SUPPLIER_INVOICE:
            const invoiceDate = new Date(action.invoice.date);
            const fromDate = new Date(action.fromDate)
            const toDate = new Date(action.toDate)
            if (invoiceDate && invoiceDate <= toDate && invoiceDate >= fromDate) {
                const updatedInvoices = state.supplierInvoices.filter((invoice) =>
                    invoice.supplierInvoiceId !== action.invoice.supplierInvoiceId
                )
                return { ...state , supplierInvoices: [...updatedInvoices, action.invoice]};
            }
            return { ...state};
        case STORE_SUPPLIER_INVOICE_PDF:
            return { ...state, supplierInvoicePDF: action.data }
        case STORE_SUPPLIER_INVOICE_PO:
            return { ...state, purchaseOrder: action.data }
        case STORE_SUPPLIER_INVOICE_TEMPLATES:
            return { ...state, supplierInvoiceTemplates: action.templates }
        case STORE_SUPPLIERS_WITHOUT_TEMPLATE:
            return { ...state, suppliersWithoutTemplate: action.suppliers }
        case STORE_SUPPLIER_INVOICE_TEMPLATE_PDF:
            return { ...state, supplierInvoiceTemplatePDF: action.template }
        case STORE_CARRIER_INVOICE_TEMPLATES:
            return { ...state, carrierInvoiceTemplates: action.templates }
        case STORE_CARRIERS_WITHOUT_TEMPLATE:
            return { ...state, carriersWithoutTemplate: action.carriers }
        case STORE_CARRIER_INVOICE_TEMPLATE_PDF:
            return { ...state, carrierInvoiceTemplatePDF: action.pdf }
        case REMOVE_DELETED_SUPPLIER_INVOICES:
            const updatedSupplierInvoices = state.supplierInvoices.filter(invoice => invoice.supplierInvoiceId !== action.supplierInvoiceId);
            return {...state, supplierInvoices: updatedSupplierInvoices}
        case STORE_ALL_CARRIER_INVOICES:
            return { ...state, carrierInvoices: action.invoices }
        case UPDATE_CARRIER_INVOICE_LOADING_STATUS:
            return {...state, carrierInvoiceLoadingStatus: action.loading}
        case SET_INVOICE_SMART_SEARCH_ROWS:
            return {...state, invoiceSmartSearchRows: action.invoices}
        case SET_INVOICE_ITEM_SMART_SEARCH_DETAILS:
            return { ...state, invoiceItemSmartSearchDetails: action.invoiceItems}
        case STORE_UPDATED_INVOICE_SMART_SEARCH_ROWS:
            const smartSearchRowsCopy = [...state.invoiceSmartSearchRows]
            const smartSearchIndex = smartSearchRowsCopy.findIndex((invoice) => invoice.carrierInvoiceId === action.invoice.carrierInvoiceId)
            const carrierInvoiceRowsCopy = [...state.carrierInvoices]
            const carrierInvoiceIndex = carrierInvoiceRowsCopy.findIndex((invoice) => invoice.carrierInvoiceId === action.invoice.carrierInvoiceId)

            if (smartSearchIndex !== -1) {
                smartSearchRowsCopy[smartSearchIndex] = action.invoice
            }

            if (carrierInvoiceIndex !== -1) {
                carrierInvoiceRowsCopy[carrierInvoiceIndex] = action.invoice
            }
            return {...state, carrierInvoices: carrierInvoiceRowsCopy, invoiceSmartSearchRows: smartSearchRowsCopy}
        case STORE_UPDATED_INVOICE_ITEM_SMART_SEARCH_DETAILS:
            const smartSearchItemDetailsCopy = [...state.invoiceItemSmartSearchDetails]
            const index = smartSearchItemDetailsCopy.findIndex((item) => item.carrierInvoiceItemId === action.invoiceItem.carrierInvoiceItemId)
            if (index !== -1) {
                smartSearchItemDetailsCopy[index] = action.invoiceItem
            }
            return {...state, invoiceItemSmartSearchDetails: smartSearchItemDetailsCopy}
        case STORE_CARRIER_INVOICE_PDF:
            return { ...state, carrierInvoicePDF: action.pdf }
        case STORE_UPDATED_CARRIER_INVOICE_STATUS:
            let newInvoices = [...state.carrierInvoices] // Create a copy of carrierInvoices array
            for (let invoiceIdIndex in action.data["carrierInvoiceIds"]) {
                let invoice = newInvoices.find(invoice => action.data["carrierInvoiceIds"][invoiceIdIndex] === invoice.carrierInvoiceId)
                invoice.status = action.data.status
            }
            return { ...state, carrierInvoices: newInvoices }
        case STORE_UPDATED_CARRIER_INVOICES:
            let updatedCarrierInvoiceIds = action.data.map((invoice)=>{return invoice.carrierInvoiceId})
            let filteredCarrierInvoices = state.carrierInvoices.filter((invoice) => !updatedCarrierInvoiceIds.includes(invoice.carrierInvoiceId))
            return {...state, carrierInvoices: [...filteredCarrierInvoices, ...action.data]}
        case STORE_CARRIER_INVOICE_OPERATION_RESPONSE:
            return { ... state, carrierInvoiceOperationResponse: action.carrierInvoiceOperationResponse}
        case CLEAR_CARRIER_INVOICE_OPERATION_RESPONSE:
            return { ... state, carrierInvoiceOperationResponse: ""}
        case REMOVE_DELETED_CARRIER_INVOICES:
            const updatedCarrierInvoices = state.carrierInvoices.filter(invoice => invoice.carrierInvoiceId !== action.data.carrierInvoiceId);
            return {...state, carrierInvoices: updatedCarrierInvoices}
        case STORE_REPROCESSED_CARRIER_INVOICES:
            const carrierInvoicesCopy = [...state.carrierInvoices]
            for (let i = 0; i < action.data.invoices.length; i++) {
                const updatedInvoice = action.data.invoices[i]
                const index = carrierInvoicesCopy.findIndex(
                    (invoice) => invoice.carrierInvoiceId === updatedInvoice.carrierInvoiceId
                )
                if (index !== -1) {
                    carrierInvoicesCopy[index] = updatedInvoice
                }
            }
            return { ...state, carrierInvoices: carrierInvoicesCopy }
        case STORE_UPDATED_SUPPLIER_INVOICE:
            let updateResult = updateInvoices(state.supplierInvoices, action.updatedInvoice)
            return {
                ...state,
                supplierInvoices: updateResult.invoices,
                supplierInvoiceChanged: updateResult.supplierInvoiceChanged
            }
        case STORE_SUPPLIER_INVOICE_OPERATION_RESPONSE:
            return {
                ...state,
                operationResponse: action.response
            }
        case STORE_DATE_FORMATS:
            return {...state, dateFormats: action.formats}
        case SET_PROCESSING:
            return {...state, processing: action.processing}
        case UPDATE_SUPPLIER_INVOICE_CHANGED:
            return {...state, supplierInvoiceChanged: false}
        case STORE_FTL_CARRIER_INVOICES:
            return {...state, ftlCarrierInvoices: action.invoices}
        case STORE_PROCESSED_FTL_CARRIER_INVOICE:
            let allInvoices = state.ftlCarrierInvoices.filter((invoice) =>
                !(invoice.invoiceId === action.invoice.invoiceId && invoice.carrierId === action.invoice.carrierId)
            );
            return {...state, ftlCarrierInvoices: [...allInvoices, action.invoice]};
        case REMOVE_DELETED_FTL_CARRIER_INVOICE:
            const updatedInvoices = state.ftlCarrierInvoices.filter((invoice)=> invoice.invoiceId !== action.invoiceId)
            return {...state, ftlCarrierInvoices: updatedInvoices}
        case UPDATE_FTL_CARRIER_INVOICE_STATUS:
            let invoices = state.ftlCarrierInvoices.map((invoice)=>{
                if (action.invoices.includes(invoice.invoiceId)) {
                    // Create a new object with the same properties as invoice, but with an updated status
                    return {...invoice, status: action.status};
                }
                return invoice
            })
            return {...state, ftlCarrierInvoices: invoices}
        case STORE_REPROCESSED_FTL_CARRIER_INVOICE:
            let invoiceNumbers = action.invoices.map((invoice)=>invoice.invoiceId)
            let filteredInvoices = state.ftlCarrierInvoices.filter((invoice)=> !invoiceNumbers.includes(invoice.invoiceId))
            return {...state, ftlCarrierInvoices: [...filteredInvoices, ...action.invoices]};
        default:
            return state
    }
}