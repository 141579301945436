import React from "react";
import ErrorFeedbackBar from "../../global/ErrorFeedbackBar";
import { TRIAL_BALANCE, TRIAL_BALANCE_PAGES } from './constants';
import FeedbackBar from "../../feedbackBar/FeedbackBar";
import withShipment from "../../../withShipment";
import {statusSelector} from "../../../redux/selectors/global";
import {clearStatus} from "../../../redux/actions/global";
import {userInfoSelector} from "../../../redux/selectors/auth";
import TrialBalanceTable from './TrialBalanceTable';
import {
    fetchTrialBalance,
    listAllTrialBalance,
    saveTrialBalance,
} from '../../../redux/actions/accounting';
import { trialBalanceSelector } from '../../../redux/selectors/accounting';
import { tableSettingSelector } from '../../../redux/selectors/account';
import { getTableSetting, saveTableSetting } from '../../../redux/actions/account';

class TrialBalance extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: TRIAL_BALANCE_PAGES.TABLE,
            feedbackBarOpen: false,
            error: false,
            trialBalance: null,
            trialBalanceId: null,
            years: ['2023','2024','2025'],
            selectedYear: "2024",
            currency: ['CAD', 'USD', "Consolidated CAD"],
            selectedCurrency: 'CAD',
            isEdit : false,
            trialBalanceFilterSetting: null,
        }
    }


    componentDidMount() {
        this.props.getTableSetting(this.props.user.userId)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.status.statusText && prevProps.status.statusText !== this.props.status.statusText){
            this.openFeedbackBar()
        }

        if (prevProps.trialBalance !== this.props.trialBalance) {

            this.setState({
                trialBalance: this.props.trialBalance?.sections || [],
                trialBalanceId : this.props.trialBalance?.trialBalanceId || null
            })
        }

        if (prevProps.tableSetting !== this.props.tableSetting) {
            this.setState({ trialBalanceFilterSetting: this.props.tableSetting?.trialBalanceFilterSetting },()=>{
                this.setState({
                    selectedCurrency: this.state.trialBalanceFilterSetting.currency,
                    selectedYear: this.state.trialBalanceFilterSetting.year,

                },()=>{
                    this.listTrialBalance()
                })
            })
        }

    }

    listTrialBalance = () =>{
        this.props.listAllTrialBalance({currency: this.state.selectedCurrency, year: this.state.selectedYear})
    }

    onFiltersInputChange = (name,event) => {
        let filtersMap = this.props.tableSetting?.trialBalanceFilterSetting
        filtersMap[name] = event.target.value;
        this.saveUpdatedFilters(filtersMap)
    }


    saveUpdatedFilters = (filters) => {
        this.props.saveTableSetting({
            userId: this.props.user.userId,
            tableSetting: null,
            changeFilters: false,
            changeTrialBalanceFilters: true,
            trialBalanceFilters: filters
        })
    }

    handleSave = (data) => {
        this.setCurrentPage(TRIAL_BALANCE.TABLE, {})
    }

    setIsEdit = (bool) =>{
        this.setState({ isEdit: bool })
    }


    setCurrentPage = (newPage) => {
        this.setState({
            currentPage: newPage
        })
    }

    handleCurrencyChange = (event) => {
            this.setState({ selectedCurrency: event.target.value },()=>{
                this.onFiltersInputChange("currency",event)
                this.setIsEdit(false)
            })
    }

    handleYearChange = (event) => {
            this.setState({ selectedYear: event.target.value },()=>{
                this.onFiltersInputChange("year",event)
            })
    }

    displayTable = () => {
        if (this.state.trialBalance) {
            return (
              <TrialBalanceTable
                fetchTrialBalance={this.props.fetchTrialBalance}
                saveTrialBalance={this.props.saveTrialBalance}
                sections={this.state.trialBalance}
                trialBalanceId={this.state.trialBalanceId}
                setCurrentPage={this.setCurrentPage}
                openFeedbackBar={this.openFeedbackBar}
                selectedYear={this.state.selectedYear}
                handleYearChange={this.handleYearChange}
                currency={this.state.currency}
                selectedCurrency={this.state.selectedCurrency}
                handleCurrencyChange={this.handleCurrencyChange}
                years={this.state.years}
                isEdit={this.state.isEdit}
                setIsEdit={this.setIsEdit}
              />
            )
        }else{
            return null
        }
    }

    displayCurrentPage = () => {
        switch (this.state.currentPage) {
            case TRIAL_BALANCE_PAGES.TABLE:
                return this.displayTable()
            default:
                return <div>We encountered an error. Please refresh the page.</div>
        }
    }

    openFeedbackBar = () => {
        this.setState({feedbackBarOpen: true})
    }

    closeFeedbackBar = () => {
        this.setState({ feedbackBarOpen: false }, () => {
            this.props.clearStatus()
        })
    }


    render() {
        return (
            <React.Fragment>
                <ErrorFeedbackBar/>
                <FeedbackBar
                    open={this.state.feedbackBarOpen}
                    handleClose={this.closeFeedbackBar}
                    severity={this.props.status?.success ? 'success' : 'error'}
                    message={this.props.status?.statusText}
                />
                {this.displayCurrentPage()}
            </React.Fragment>
        )
    }
}


const mapStateToProps = (state) => ({
    status: statusSelector(state),
    user: userInfoSelector(state),
    trialBalance: trialBalanceSelector(state),
    tableSetting: tableSettingSelector(state),
})


const actionCreators = {
    clearStatus,
    listAllTrialBalance,
    fetchTrialBalance,
    saveTrialBalance,
    saveTableSetting,
    getTableSetting,
}

export default withShipment({
    actionCreators,
    mapStateToProps
}, TrialBalance);