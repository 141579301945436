export const TRIAL_BALANCE = "Trial Balance"
export const SAVE = "Save"


export const TRIAL_BALANCE_PAGES = {
    TABLE: "table",
}


// tooltip names
export const BACK = "Back to Menu"
