import React from "react";
import TopToolbar from "../../global/subcomponents/topToolbar/TopToolbar";
import {
    SETTINGS, STATEMENT_TAXES, STATEMENT_TAXES_PAGES, STATEMENT_TAXES_SUMMARY_FIELDS,
} from "./constants";
import { Layout } from "../../global/Table/TablePageHelpers/LayoutObject";
import { TableCell, Chip, Select, MenuItem, FormControl, InputLabel, Box } from "@mui/material";
import TablePage from "../../global/Table/TablePage";
import '../shippingSummary/ShippingSummary.css';
import SettingsIcon from "@material-ui/icons/Settings";
import {HourglassEmptyTwoTone} from "@material-ui/icons";
import RefreshIcon from '@material-ui/icons/Refresh';

class StatementTaxesTable extends React.Component {
    constructor(props) {
        super(props)
    }

    getTableHeaders = () => {
        let headers = [];

        STATEMENT_TAXES_SUMMARY_FIELDS.forEach((headCell, index) => {
            if (index === 0) {
                headers.push(Layout.columnHead(headCell.label, headCell.id, "left")); // Adjust alignment to left for index 0
            } else {
                headers.push(Layout.columnHead(headCell.label, headCell.id, "center")); // Default alignment for other headers
            }
        });
        return headers;
    }

    tableRowPaid = (summary, index) => {
        if (summary) {
            const lastIndex = this.props.paidList.length - 1;
            return (
                <React.Fragment>
                    <TableCell
                        className="table-tax-cell-left"
                        style={{
                            width: '30%',
                            fontWeight: index === lastIndex || index === lastIndex - 2 ? 'bold' : 'normal',
                            borderBottom: index === lastIndex ? 'none' : '1px solid #ddd',
                        }}
                    >
                        {summary?.category}
                    </TableCell>


                    {summary?.totalSubtotal ? (
                      summary?.totalSubtotal !== 0 ? (
                        <TableCell
                          className="table-tax-cell-left"
                          style={{
                              width: '10%',
                              borderBottom: index === lastIndex ? 'none' : '1px solid #ddd',
                          }}
                        >
                            <div
                              style={{
                                  fontWeight: index === lastIndex || index === lastIndex - 2 ? 'bold' : 'normal',
                                  display: 'flex',
                                  justifyContent: 'flex-end',
                                  alignItems: 'center',
                                  gap: '3px',
                                  marginRight: '15px',
                              }}
                            >
                                <span style={{ flexShrink: 0, marginRight: 'auto' }}>$</span>
                                <span style={{ marginLeft: 0 }}>
                                {parseFloat(summary?.totalSubtotal)
                                  .toFixed(2)
                                  .toString()
                                  .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}
                            </span>
                            </div>
                        </TableCell>
                      ) : (
                        <TableCell
                          className="table-tax-cell-left"
                          style={{
                              width: '10%',
                              borderBottom: index === lastIndex ? 'none' : '1px solid #ddd',
                          }}
                        />
                      )
                    ) : <TableCell
                      className="table-tax-cell-left"
                      style={{
                          width: '10%',
                          borderBottom: index === lastIndex ? 'none' : '1px solid #ddd',
                      }}
                    >
                        <div
                          style={{
                              fontWeight: index === lastIndex ? 'bold' : 'normal',
                              display: 'flex',
                              justifyContent: 'flex-end',
                              alignItems: 'center',
                              gap: '3px',
                              marginRight: '15px',
                          }}
                        >
                            <span style={{flexShrink: 0, marginRight: 'auto'}}></span>
                            <span style={{marginLeft: 0}}>&nbsp;</span>
                        </div>
                    </TableCell>
                    }


                    {summary?.total ? (
                        summary?.total !== 0 ? (
                        <TableCell
                            className="table-tax-cell-left"
                            style={{
                                width: '10%',
                                borderBottom: index === lastIndex ? 'none' : '1px solid #ddd',
                            }}
                        >
                            <div
                                style={{
                                    fontWeight: index === lastIndex || index === lastIndex - 2 ? 'bold' : 'normal',
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    alignItems: 'center',
                                    gap: '3px',
                                    marginRight: '15px',
                                }}
                            >
                                <span style={{ flexShrink: 0, marginRight: 'auto' }}>$</span>
                                <span style={{ marginLeft: 0 }}>
                                {parseFloat(summary?.total)
                                    .toFixed(2)
                                    .toString()
                                    .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}
                            </span>
                            </div>
                        </TableCell>
                        ) : (
                            <TableCell
                                className="table-tax-cell-left"
                                style={{
                                    width: '10%',
                                    borderBottom: index === lastIndex ? 'none' : '1px solid #ddd',
                                }}
                            />
                        )
                    ) : <TableCell
                        className="table-tax-cell-left"
                        style={{
                            width: '10%',
                            borderBottom: index === lastIndex ? 'none' : '1px solid #ddd',
                        }}
                    >
                        <div
                            style={{
                                fontWeight: index === lastIndex ? 'bold' : 'normal',
                                display: 'flex',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                                gap: '3px',
                                marginRight: '15px',
                            }}
                        >
                            <span style={{flexShrink: 0, marginRight: 'auto'}}></span>
                            <span style={{marginLeft: 0}}>&nbsp;</span>
                        </div>
                    </TableCell>
                    }

                    {summary?.totalTax ? (
                        summary?.totalTax !== 0 ? (
                        <TableCell
                            className="table-tax-cell-left"
                            style={{
                                width: '10%',
                                borderBottom: index === lastIndex ? 'none' : '1px solid #ddd',
                            }}
                        >
                            <div
                                style={{
                                    fontWeight: index === lastIndex || index === lastIndex - 2 ? 'bold' : 'normal',
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    alignItems: 'center',
                                    gap: '3px',
                                    marginRight: '15px',
                                }}
                            >
                                <span style={{ flexShrink: 0, marginRight: 'auto' }}>$</span>
                                <span style={{ marginLeft: 0 }}>
                                    {summary?.totalTax && typeof summary.totalTax === 'string' && summary.totalTax.startsWith('(')
                                        ? summary.totalTax
                                        : parseFloat(summary?.totalTax)
                                            .toFixed(2)
                                            .toString()
                                            .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
                                    }
                            </span>
                            </div>
                        </TableCell>
                    ) : (
                        <TableCell
                            className="table-tax-cell-left"
                            style={{
                                width: '10%',
                                borderBottom: index === lastIndex ? 'none' : '1px solid #ddd',
                            }}
                        />
                    )
                        ) : <TableCell
                        className="table-tax-cell-left"
                        style={{
                        width: '10%',
                        borderBottom: index === lastIndex ? 'none' : '1px solid #ddd',
                    }}
                >
                    <div
                        style={{
                            fontWeight: index === lastIndex ? 'bold' : 'normal',
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            gap: '3px',
                            marginRight: '15px',
                        }}
                    >
                        <span style={{flexShrink: 0, marginRight: 'auto'}}></span>
                        <span style={{marginLeft: 0}}>&nbsp;</span>
                    </div>
                </TableCell>
        }
                </React.Fragment>
            );
        }
        return null;
    };




    tableRowReceived = (summary, index) => {
        if (summary) {
            const lastIndex = this.props.receivedList.length - 1;
            return (
                <React.Fragment>

                    <TableCell
                                className="table-tax-cell-left"
                                style={{
                                    width: '30%',
                                    fontWeight: index === lastIndex ? 'bold' : 'normal',
                                    borderBottom: index === lastIndex ? 'none' : '1px solid #ddd',
                                }}
                            >
                                {summary?.category}
                    </TableCell>

                    {summary?.totalSubtotal ? (
                      summary?.totalSubtotal !== 0 ? (
                        <TableCell
                          className="table-tax-cell-left"
                          style={{
                              width: '10%',
                              borderBottom: index === lastIndex ? 'none' : '1px solid #ddd',
                          }}
                        >
                            <div
                              style={{
                                  fontWeight: index === lastIndex ? 'bold' : 'normal',
                                  display: 'flex',
                                  justifyContent: 'flex-end',
                                  alignItems: 'center',
                                  gap: '3px',
                                  marginRight: '15px',
                              }}
                            >
                                <span style={{ flexShrink: 0, marginRight: 'auto' }}>$</span>
                                <span style={{ marginLeft: 0 }}>
                                {parseFloat(summary?.totalSubtotal)
                                  .toFixed(2)
                                  .toString()
                                  .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}
                                 </span>
                            </div>
                        </TableCell>
                      ) : (
                        <TableCell
                          className="table-tax-cell-left"
                          style={{
                              width: '10%',
                              borderBottom: index === lastIndex ? 'none' : '1px solid #ddd',
                          }}
                        />
                      )
                    ) : <TableCell
                      className="table-tax-cell-left"
                      style={{
                          width: '10%',
                          borderBottom: index === lastIndex ? 'none' : '1px solid #ddd',
                      }}
                    >
                        <div
                          style={{
                              fontWeight: index === lastIndex ? 'bold' : 'normal',
                              display: 'flex',
                              justifyContent: 'flex-end',
                              alignItems: 'center',
                              gap: '3px',
                              marginRight: '15px',
                          }}
                        >
                            <span style={{flexShrink: 0, marginRight: 'auto'}}></span>
                            <span style={{marginLeft: 0}}>&nbsp;</span>
                        </div>
                    </TableCell>
                    }




                    {summary?.total ? (
                        summary?.total !== 0 ? (
                        <TableCell
                            className="table-tax-cell-left"
                            style={{
                                width: '10%',
                                borderBottom: index === lastIndex ? 'none' : '1px solid #ddd',
                            }}
                        >
                            <div
                                style={{
                                    fontWeight: index === lastIndex ? 'bold' : 'normal',
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    alignItems: 'center',
                                    gap: '3px',
                                    marginRight: '15px',
                                }}
                            >
                                <span style={{ flexShrink: 0, marginRight: 'auto' }}>$</span>
                                <span style={{ marginLeft: 0 }}>
                                {parseFloat(summary?.total)
                                    .toFixed(2)
                                    .toString()
                                    .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}
                                 </span>
                            </div>
                        </TableCell>
                        ) : (
                            <TableCell
                                className="table-tax-cell-left"
                                style={{
                                    width: '10%',
                                    borderBottom: index === lastIndex ? 'none' : '1px solid #ddd',
                                }}
                            />
                        )
                    ) : <TableCell
                        className="table-tax-cell-left"
                        style={{
                            width: '10%',
                            borderBottom: index === lastIndex ? 'none' : '1px solid #ddd',
                        }}
                    >
                        <div
                            style={{
                                fontWeight: index === lastIndex ? 'bold' : 'normal',
                                display: 'flex',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                                gap: '3px',
                                marginRight: '15px',
                            }}
                        >
                            <span style={{flexShrink: 0, marginRight: 'auto'}}></span>
                            <span style={{marginLeft: 0}}>&nbsp;</span>
                        </div>
                    </TableCell>
                    }

                    {summary?.totalTax ? (
                        summary?.totalTax !== 0 ? (
                            <TableCell
                                className="table-tax-cell-left"
                                style={{
                                    width: '10%',
                                    borderBottom: index === lastIndex ? 'none' : '1px solid #ddd',
                                }}
                            >
                                <div
                                    style={{
                                        fontWeight: index === lastIndex ? 'bold' : 'normal',
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        alignItems: 'center',
                                        gap: '3px',
                                        marginRight: '15px',
                                    }}
                                >
                                <span style={{ flexShrink: 0, marginRight: 'auto' }}>$</span>
                                <span style={{ marginLeft: 0 }}>
                                {parseFloat(summary?.totalTax)
                                    .toFixed(2)
                                    .toString()
                                    .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}
                            </span>
                            </div>
                        </TableCell>
                    ) : (
                        <TableCell
                            className="table-tax-cell-left"
                            style={{
                                width: '10%',
                                borderBottom: index === lastIndex ? 'none' : '1px solid #ddd',
                            }}
                        />
                        )
                    ) : <TableCell
                        className="table-tax-cell-left"
                        style={{
                            width: '10%',
                            borderBottom: index === lastIndex ? 'none' : '1px solid #ddd',
                        }}
                    >
                        <div
                            style={{
                                fontWeight: index === lastIndex ? 'bold' : 'normal',
                                display: 'flex',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                                gap: '3px',
                                marginRight: '15px',
                            }}
                        >
                            <span style={{flexShrink: 0, marginRight: 'auto'}}></span>
                            <span style={{marginLeft: 0}}> &nbsp; </span>
                        </div>
                    </TableCell>
                    }
                </React.Fragment>
            );
        }
        return null;
    };


    searchStrings = (summary) => {
        return [summary.category]
    }


    getContextualMenuItems = () => {
        let menuItems = []
        menuItems.push(
            {
                title: SETTINGS,
                icon: <SettingsIcon/>,
                onClick: () => this.props.setCurrentPage(STATEMENT_TAXES_PAGES.SETTINGS, {})
            })

        menuItems.push({
            title: 'Refresh',
            icon: <RefreshIcon />,
            onClick: () => {
                this.props.setFetch(true)
            }
        })

        if (this.props.loadingData) {
            menuItems.push({
                icon: <HourglassEmptyTwoTone />,
                handler: () => null,
                disabled: true,
                title: "Loading data..."
            });
        }

        return menuItems
    }

    getMonthsForQuarter = () => {
        const {selectedQuarters} = this.props;

        if (!selectedQuarters) {
            return [
                {name: 'January', value: '01'},
                {name: 'February', value: '02'},
                {name: 'March', value: '03'},
                {name: 'April', value: '04'},
                {name: 'May', value: '05'},
                {name: 'June', value: '06'},
                {name: 'July', value: '07' },
                { name: 'August', value: '08' },
                { name: 'September', value: '09' },
                { name: 'October', value: '10' },
                { name: 'November', value: '11' },
                { name: 'December', value: '12' },
            ]
        } else {
            switch (selectedQuarters) {
                case 1:
                    return [  { name: 'January', value: '01' },
                        { name: 'February', value: '02' },
                        { name: 'March', value: '03' }]
                case 2:
                    return [
                        { name: 'April', value: '04' },
                        { name: 'May', value: '05' },
                        { name: 'June', value: '06' }]
                case 3:
                    return [
                        { name: 'July', value: '07' },
                        { name: 'August', value: '08' },
                        { name: 'September', value: '09'}]
                case 4:
                    return [
                        { name: 'October', value: '10' },
                        { name: 'November', value: '11' },
                        { name: 'December', value: '12' }]
                default:
                    return [];
            }
        }
    }

    render() {
        const currency = ['CAD', 'USD']
        const currentYear = new Date().getFullYear()
        const years = Array.from(new Array(21), (val, index) => currentYear - index)
        const {  selectedYear, selectedQuarters, selectedCurrency, handleCurrencyChange, selectedMonth, handleMonthChange, handleYearChange, handleQuarterChange, receivedList, paidList } = this.props

        return (
            <React.Fragment>
                <TopToolbar
                    pageName={STATEMENT_TAXES}
                    menuItems={this.getContextualMenuItems()}
                />


                <Box className="box-statement">


                    <FormControl variant="outlined" className="form-control">
                        <InputLabel id="year-label">Year</InputLabel>
                        <Select
                            className="select-year"
                            labelId="year-label"
                            value={selectedYear}
                            onChange={handleYearChange}
                            label="Year"
                        >
                            {years.map((year) => (
                                <MenuItem key={year} value={year}>
                                    {year}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl variant="outlined" className="form-control">
                        <InputLabel id="month-label">Month</InputLabel>
                        <Select
                            className="select-month"
                            label="Month"
                            labelId="month-label"
                            value={selectedMonth}
                            onChange={handleMonthChange}>
                            <MenuItem value="">None</MenuItem>
                            {this.getMonthsForQuarter().map((month) => (
                                <MenuItem key={month.value} value={month.value}>
                                    {month.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl variant="outlined" className="form-control">
                        <InputLabel id="currency-label">Currency</InputLabel>
                        <Select
                            className="select"
                            labelId="currency-label"
                            value={selectedCurrency}
                            onChange={handleCurrencyChange}
                            label="Currency"
                        >
                            {currency.map((currency) => (
                                <MenuItem key={currency} value={currency}>
                                    {currency}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <div className="chip-container">
                        {["QTR 1", "QTR 2", "QTR 3", "QTR 4"].map((quarter, index) => (
                            <Chip
                                key={index}
                                label={quarter}
                                onClick={() => handleQuarterChange(quarter)}
                                className={`chip ${selectedQuarters === index + 1 ? 'chip-selected' : ''}`}
                            />
                        ))}
                    </div>
                </Box>

                <div className="rowSpecSuppliers" style={{marginTop:'60px'}}>

                    <div style={{width: '50%', marginTop:'-100px'}}>
                        <h5 style={{textAlign: 'center'}}> Total Paid </h5>

                        <div style={{
                            borderRight: '1px solid rgba(224, 224, 224, 1)'
                        }}>
                        <TablePage
                            type="statementTaxes"
                            tableName="SummaryTable"
                            tableRows={paidList}
                            tableColumnHeads={this.getTableHeaders()}
                            tableRow={this.tableRowPaid}
                            searchStrings={this.searchStrings}
                        />
                        </div>
                    </div>

                    <div style={{width: '50%', marginTop: '-100px'}}>
                        <h5 style={{textAlign: 'center'}}> Total Received </h5>

                        <div style={{
                            marginLeft: '-1px',
                            borderLeft: '1px solid rgba(224, 224, 224, 1)'
                        }}>
                            <TablePage
                                type="statementTaxes"
                                tableName="SummaryTable"
                                tableRows={receivedList}
                                tableColumnHeads={this.getTableHeaders()}
                                tableRow={this.tableRowReceived}
                                searchStrings={this.searchStrings}
                            />
                        </div>
                    </div>
                </div>


            </React.Fragment>
        )
    }
}

export default StatementTaxesTable;
