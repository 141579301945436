export const HEADER_CELLS = [
    { id: 'tag',        label: 'Tag'},
    { id: 'orderId',    label: 'Order ID'},
    { id: 'status',     label: 'Status'},
    { id: 'notes',      label: 'Notes'},
    { id: 'name',       label: 'Name'},
    { id: 'address',    label: 'Address'},
    { id: 'city',       label: 'City'},
    { id: 'state',      label: 'State'},
    { id: 'sku',        label: 'SKU'},
    { id: 'shipFrom',   label: 'Ship From'},
    { id: 'rate',       label: 'Rate'},
    { id: 'carrier',    label: 'Carrier'},
    { id: 'prime',      label: 'Prime'},
    { id: 'orderDate',  label: 'Order Date'},
    { id: 'shipBy',     label: 'Ship By'},
    { id: 'deliverBy',  label: 'Deliver By'},
    { id: 'marketplace',label: 'Marketplace'},
    { id: 'shipMethod', label: 'Ship Method'},

]

export const MANUAL_RESOLVE = "Resolve"