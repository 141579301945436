import React from "react";
import {
    Button, Checkbox,
    FormControlLabel,
    IconButton,
    Step,
    StepButton,
    Stepper,
    TextField,
    Tooltip,
    Switch,
    Paper,
    Typography, Divider, Toolbar
} from "@material-ui/core";
import {
    STEPPER_HEADINGS,
    STEPPER_HEADING_DESCRIPTIONS,
    STEP_CUSTOMER_INFO_INDEX,
    STEP_CUSTOMER_PRODUCTS_INDEX,
    COUNTRY_OPTIONS,
    PRICE_ADJUSTMENT_ACTION,
    PRICE_ADJUSTMENT_OPTIONS,
    AMOUNT,
    INCREMENT,
    DECREMENT, PERCENTAGE,
} from "./constants";

import "./ManageCustomerStepper.css"
import {
    BACK,
    CANCEL,
    NEXT,
} from "../../automationrules/constants";
import { SAVE } from "../../global/constants";
import CustomToolbar from "../../global/CustomToolbar";
import InputAdornment from "@material-ui/core/InputAdornment";


//icons
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import PhoneIcon from '@material-ui/icons/Phone';
import PropTypes from "prop-types";
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import AddCircleIcon from "@material-ui/icons/AddCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";

import Autocomplete from "@material-ui/lab/Autocomplete";
import DeleteStepper from "../../global/DeleteStepper";
import {ArrowBack, ArrowForward} from "@material-ui/icons";
import {CURRENCY_TYPE_OPTIONS} from "./constants";
import ClearIcon from "@material-ui/icons/Clear";
import {CANCEL_EDIT_ENTRY} from "../../damages/constants";
import {APPLY_EDIT_ENTRY} from "../ManageBarcodes/constants";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";


class ManageCustomerStepper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeStep: 0,
            name: this.getValue('name'),
            addressLine1: this.getValue('addressLine1'),
            addressLine2: this.getValue('addressLine2'),
            city: this.getValue('city'),
            phone: this.getValue('phone'),
            province: this.getValue('province'),
            postalCode: this.getValue('postalCode'),
            country: this.getValue('country'),
            customerShortcode: this.getValue('customerShortcode'),
            customerId: this.getValue('customerId'),
            currencyCode: this.getValue("currencyCode"),
            products: this.getValue('products'),
            invoiceOrderEmailTemplate: this.getValue('invoiceOrderEmailTemplate'),
            missingError: false,
            isDelete: {},
            shortcodeError: {},
            allItems: [],
            subject: this.getValue('subject'),
            from: this.getValue('from'),
            body: this.getValue('body'),
            label: this.getValue('label'),
            ccEmails: this.getValue("ccEmails"),
            bccEmails: this.getValue("bccEmails"),
            templates: this.getValue("templates"),
            amountOrPercentage: null,
            amount: "",
            increaseOrDecrease: "",
        }
    }

    componentDidMount() {
        this.setState({ allItems: this.props.packages.concat(this.props.products) })
    }


    getValue = (key) => {
        // todo do something if key is products
        if (this.props.addingCustomer && (key === 'templates' || key === 'products'))
            return []
        if (this.props.addingCustomer)
            return ''
        if (!this.props.addingCustomer && key === 'products') {
            return this.props.editingCustomer?.customerProducts?.sort((a, b) => { return a.shortcode.toLowerCase().localeCompare(b.shortcode.toLowerCase()) })
        }
        if (!this.props.addingCustomer && key === 'templates') {
            return this.props.editingCustomer[key] || [];
        }
        return this.props.editingCustomer[key]
    }

    setStep = (index) => {
        this.setState({
            activeStep: index,

        });
    };

    handleTextChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleCheckboxChange = (index, event) => {
        const { checked } = event.target;
        const updatedTemplates = [...this.state.templates];
        updatedTemplates[index]["isAttachPackingSlip"] = checked;
        this.setState({ templates: updatedTemplates });
    };



    handleTexttChange = (index, event) => {
        const { name, value } = event.target;
        const updatedTemplates = [...this.state.templates];
        updatedTemplates[index][name] = value;
        this.setState({ templates: updatedTemplates });
    };

    handleTemplateChange = (index, event, value) => {
        const updatedTemplates = [...this.state.templates];
        updatedTemplates[index].templateName = value ? value.templateName : '';
        updatedTemplates[index].isAttachPackingSlip = updatedTemplates[index].templateName === "Packing Slip" ? false : updatedTemplates[index].isAttachPackingSlip
        this.setState({ templates: updatedTemplates });
    };

    addEmails = (index, type) => {
        const updatedTemplates = [...this.state.templates];
        updatedTemplates[index][type].push('');
        this.setState({ templates: updatedTemplates });
    };

    editEmails = (index, type, emailIndex, value) => {
        const updatedTemplates = [...this.state.templates];
        updatedTemplates[index][type][emailIndex] = value;
        this.setState({ templates: updatedTemplates });
    };

    deleteEmail = (index, type, emailIndex) => {
        const updatedTemplates = [...this.state.templates];
        updatedTemplates[index][type].splice(emailIndex, 1);
        this.setState({ templates: updatedTemplates });
    };

    deleteTemplate = (index) => {
        const updatedTemplates = this.state.templates.filter((_, i) => i !== index);
        this.setState({ templates: updatedTemplates });
    };

    addTemplate = () => {
        this.setState(prevState => ({
            templates: [...prevState.templates, { templateName: '', email: '', isAttachPackingSlip: false, ccEmails: [], bccEmails: [] }]
        }));
    };


    handleBack = () => {
        // if they are on the first step
        if (this.state.activeStep === 0) {
            this.props.handleClose()
        }
        else {
            this.setState({
                activeStep: this.state.activeStep - 1,
            });
        }

    };

    getStepContent = (stepIndex) => {
        switch (stepIndex) {
            case STEP_CUSTOMER_INFO_INDEX:
                return this.firstStepContent()
            case STEP_CUSTOMER_PRODUCTS_INDEX:
                return this.secondStepContent()
        }
    }


    addProductEntry = () => {
        let products = this.state.products ? this.state.products : []
        products.push({ shortcode: "", price: "", customerPartNumber: ""})
        this.setState({
            products: products
        }
        )

    }

    removeProductEntry = (index) => {
        const { products } = this.state
        products.splice(index, 1)
        this.setState({
            products: products
        })
    }



    firstStepContent = () => {
        return (
            <>
                <div className='stepper-page-one-grid'>
                    <div>
                        <div className='rowSpecCustomers'>
                            <TextField
                                className='customer-input-text-field'
                                variant='outlined'
                                label="Name"
                                InputLabelProps={{shrink: true}}
                                name='name'
                                value={this.state.name}
                                onChange={this.handleTextChange}
                                required
                                error={this.state.name === ''}
                            />
                            <TextField
                                className='customer-input-text-field'
                                variant='outlined'
                                label="Phone"
                                InputLabelProps={{shrink: true}}
                                name='phone'
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <PhoneIcon/>
                                        </InputAdornment>
                                    ),
                                }}
                                value={this.state.phone}
                                onChange={this.handleTextChange}
                                required
                                error={this.state.phone === ''}
                            />
                        </div>


                        <div className='rowSpecCustomers'>
                            <TextField
                                variant='outlined'
                                label="Address Line 1"
                                className='single-line-field'
                                InputLabelProps={{shrink: true}}
                                name='addressLine1'
                                value={this.state.addressLine1}
                                onChange={this.handleTextChange}
                                required
                                error={this.state.addressLine1 === ''}
                            />
                        </div>

                        <div className='rowSpecCustomers'>
                            <TextField
                                variant='outlined'
                                label="Address Line 2"
                                className='single-line-field'
                                InputLabelProps={{shrink: true}}
                                name='addressLine2'
                                value={this.state.addressLine2}
                                onChange={this.handleTextChange}
                            />
                        </div>
                        <div className='rowSpecCustomers'>
                            <TextField
                                className='customer-input-text-field'
                                variant='outlined'
                                label="City"
                                InputLabelProps={{shrink: true}}
                                name='city'
                                value={this.state.city}
                                onChange={this.handleTextChange}
                                required
                                error={this.state.city === ''}
                            />
                            <TextField
                                className='customer-input-text-field'
                                variant='outlined'
                                label="State or Province"
                                InputLabelProps={{shrink: true}}
                                name='province'
                                value={this.state.province}
                                onChange={this.handleTextChange}
                                required
                                error={this.state.province === ''}
                            />
                            <TextField
                                className='customer-input-text-field'
                                variant='outlined'
                                label="Zip or Postal Code"
                                InputLabelProps={{shrink: true}}
                                name='postalCode'
                                value={this.state.postalCode}
                                onChange={this.handleTextChange}
                                required
                                error={this.state.postalCode === ''}
                            />
                        </div>
                        <div className='rowSpecCustomers'>
                            <Autocomplete
                                required
                                options={COUNTRY_OPTIONS}
                                getOptionLabel={(option) => option}
                                value={this.state.country}
                                onChange={(e, value) => this.handleTextChange({
                                    target: {
                                        name: "country",
                                        value: value !== null ? value : ''
                                    }
                                })}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Country"
                                        variant="outlined"
                                        error={this.state.country === ''}
                                        InputLabelProps={{shrink: true}}
                                    />
                                )}
                                className='customer-input-text-field'
                            />

                        </div>
                        <div className='rowSpecCustomers'>
                            <TextField
                                className='customer-input-text-field'
                                variant='outlined'
                                label="Customer Shortcode"
                                InputLabelProps={{shrink: true}}
                                name='customerShortcode'
                                value={this.state.customerShortcode}
                                onChange={this.handleTextChange}
                                required
                                error={this.state.customerShortcode === ''}
                            />

                            <Autocomplete
                                required
                                options={CURRENCY_TYPE_OPTIONS}
                                getOptionLabel={(option) => option}
                                value={this.state.currencyCode}
                                onChange={(e, value) => this.handleTextChange({
                                    target: {
                                        name: "currencyCode",
                                        value: value !== null ? value : ''
                                    }
                                })}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Customer Currency"
                                        variant="outlined"
                                        error={this.state.currencyCode === ''}
                                        InputLabelProps={{shrink: true}}
                                    />
                                )}
                                className='customer-input-text-field'
                            />
                        </div>

                        <Divider className='row-divider'/>

                        {this.state.templates && this.state.templates.length > 0 ? (
                            this.state.templates.map((template, index) => (
                                <div>
                                     <div key={template.id} className='rowSpecCustomers'>
                                <Autocomplete
                                    required
                                    options={this.props.emailTemplates.filter(template =>
                                        template.templateName.startsWith('Customer') ||
                                        template.templateName.startsWith('Packing')
                                    )}
                                    getOptionLabel={option => option.templateName}
                                    value={this.props.emailTemplates.find(t => t.templateName === template.templateName) || null}
                                    onChange={(event, value) => this.handleTemplateChange(index, event, value)}
                                    style={{width: 225}}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            className='customer-input-text-field'
                                            label="Email Template"
                                            InputLabelProps={{shrink: true}}
                                            variant="outlined"
                                            margin="normal"
                                            error={template.templateName === ''}
                                        />
                                    )}
                                />



                                <IconButton onClick={() => this.deleteTemplate(index)}>
                                    <DeleteIcon/>
                                </IconButton>

                            </div>

                                    {template.templateName === "Customer Invoice" &&
                                        <div className='rowSpecCustomers' style={{marginLeft: '24px'}}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={this.state.templates[index].isAttachPackingSlip}
                                                        onChange={(event, value) => {
                                                            this.handleCheckboxChange(index, event)
                                                        }
                                                        }
                                                    />
                                                }
                                                label="Attach packing slip when invoice is sent"
                                            />

                                        </div>
                                    }

                                    <div className='rowSpecCustomers'>
                                        <TextField
                                            className='customer-input-text-field-large'
                                            variant='outlined'
                                            label='Email'
                                            InputLabelProps={{shrink: true}}
                                            name='email'
                                            value={template.email}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <MailOutlineIcon/>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            onChange={(e) => this.handleTexttChange(index, e)}
                                            required
                                            error={template.email === ''}
                                        />
                                    </div>



                                    <div key={template.id}>

                                        {template.ccEmails.map((ccEmail, ccIndex) => (
                                        <div className='rowSpecCustomers' key={`cc-${ccIndex}`}>
                                            <TextField
                                                className='customer-input-text-field-large'
                                                variant='outlined'
                                                label='CC Email'
                                                InputLabelProps={{shrink: true}}
                                                name='ccEmail'
                                                value={ccEmail}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <MailOutlineIcon/>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                onChange={(e) => this.editEmails(index, 'ccEmails', ccIndex, e.target.value)}
                                            required
                                            error={ccEmail === ''}
                                        />
                                        <IconButton onClick={() => this.deleteEmail(index, 'ccEmails', ccIndex)}>
                                            <DeleteIcon/>
                                        </IconButton>
                                    </div>
                                ))}

                                {template.bccEmails.map((bccEmail, bccIndex) => (
                                    <div className='rowSpecCustomers' key={`bcc-${bccIndex}`}>
                                        <TextField
                                            className='customer-input-text-field-large'
                                            variant='outlined'
                                            label='BCC Email'
                                            InputLabelProps={{shrink: true}}
                                            name='bccEmail'
                                            value={bccEmail}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <MailOutlineIcon/>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            onChange={(e) => this.editEmails(index, 'bccEmails', bccIndex, e.target.value)}
                                            required
                                            error={bccEmail === ''}
                                        />
                                        <IconButton onClick={() => this.deleteEmail(index, 'bccEmails', bccIndex)}>
                                            <DeleteIcon/>
                                        </IconButton>
                                    </div>
                                ))}

                                <div className='rowSpecCustomers' style={{marginLeft:'24px'}}>
                                    <Tooltip title="Add a CC email">
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                            <IconButton onClick={() => this.addEmails(index, 'ccEmails')}>
                                                <AddCircleIcon fontSize="small" color='secondary'/>
                                            </IconButton>
                                            <span>Add CC</span>
                                        </div>
                                    </Tooltip>
                                    <Tooltip title="Add a BCC email">
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                            <IconButton onClick={() => this.addEmails(index, 'bccEmails')}>
                                                <AddCircleIcon fontSize="small" color='secondary'/>
                                            </IconButton>
                                            <span>Add BCC</span>
                                        </div>
                                    </Tooltip>
                                </div>


                            </div>
                                    <Divider className='row-divider'/>
                        </div>))
                        ) : null}

                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <IconButton onClick={this.addTemplate}>
                                <AddCircleIcon fontSize="small" color='secondary'/>
                            </IconButton>
                            <span>Add Email Template</span>
                        </div>

                    </div>
                </div>
            </>
        )
    }



    checkDuplicateShortcode = () => {
        return this.state.products.some(product =>
            !product.shortcode ||
            !product.price ||
            !product.customerPartNumber
        )
    }

    handleChange = (name, value) => {
        this.setState(
            {
                [name]: value
            },
            this.recalculatePrices // Trigger recalculation after state updates
        );
    };

    recalculatePrices = () => {
        const { increaseOrDecrease, amountOrPercentage, amount, products } = this.state;

        if (!increaseOrDecrease || !amountOrPercentage || !amount) {
            return; // Do nothing if required values are not set
        }

        const numericValue = parseFloat(amount); // Ensure amount is a number
        if (isNaN(numericValue)) {
            console.error("Amount must be numeric");
            return; // Exit if the value is not numeric
        }

        const productsCopy = products.map((product) => {
            const price = parseFloat(product.price); // Convert price to number
            if (isNaN(price)) {
                console.error(`Invalid price for product: ${product}`);
                return product; // Skip products with invalid price
            }

            let newPrice = price;
            if (amountOrPercentage === AMOUNT) {
                if (increaseOrDecrease === INCREMENT) {
                    newPrice = price + numericValue;
                } else if (increaseOrDecrease === DECREMENT) {
                    newPrice = price - numericValue;
                }
            }
            else if (amountOrPercentage === PERCENTAGE) {
                if (increaseOrDecrease === INCREMENT) {
                    newPrice = price * (100 + numericValue) / 100
                } else if (increaseOrDecrease === DECREMENT) {
                    newPrice = price * (100 - numericValue) / 100
                }
            }
            newPrice = Number(newPrice).toFixed(2).toString()
            // Return updated product
            return {
                ...product, // Keep other product properties intact
                newPrice
            };
        });

        this.setState({ products: productsCopy });
    };



    getTextFieldLabel = (value) => {
        if (!value) {
            return "Amount / Percentage"
        }
        return value
    }

    applyNewPrices = () => {
        let productsCopy = [...this.state.products]
        productsCopy.map((product) => {
            if (product.newPrice) {
                product.price = product.newPrice
            }
        })
        this.setState({
            products: productsCopy,
            amountOrPercentage: null,
            amount: "",
            increaseOrDecrease: "",
        })
    }

    emptyNewPriceSettings = () => {
        let productsCopy = [...this.state.products]
        productsCopy.map((product) => {
            if (product.newPrice) {
                delete product["newPrice"]
            }
        })
        this.setState({
            products: productsCopy,
            amountOrPercentage: null,
            amount: "",
            increaseOrDecrease: "",
        })
    }

    secondStepContent = () => {
        return (
            <>
                <p style={{textAlign: "left", paddingLeft: "100px"}}>Adjust prices in bulk, by specifying parameters below: </p>
                <div className='customer-stepper-page-two-first-grid'>
                    <div style={{ justifySelf: "end", display: "flex" }}>
                        <Tooltip title={CANCEL_EDIT_ENTRY}>
                            <IconButton onClick={() => this.emptyNewPriceSettings()}>
                                <ClearIcon
                                    fontSize='small'
                                    color='error'
                                />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <div>
                        <Autocomplete
                            options={PRICE_ADJUSTMENT_ACTION}
                            getOptionLabel={(option) => option}
                            value={this.state.increaseOrDecrease}
                            className='customer-input-text-field'
                            onChange={(e, value) => this.handleChange("increaseOrDecrease", value)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    name="increaseOrDecrease"
                                    label="Adjust Price"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            )}
                        />
                    </div>
                    <div>
                        <Autocomplete
                            options={PRICE_ADJUSTMENT_OPTIONS}
                            getOptionLabel={(option) => option}
                            value={this.state.amountOrPercentage}
                            className='customer-input-text-field'
                            onChange={(e, value) => this.handleChange("amountOrPercentage", value)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    name="adjustmentType"
                                    label="Adjustment Type"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            )}
                        />
                    </div>
                    <div>
                        <TextField
                            variant="outlined"
                            name="number"
                            // label="Amount / Percentage"
                            label={this.getTextFieldLabel(this.state.amountOrPercentage)}
                            className='customer-input-text-field'
                            value={this.state.amount}
                            onChange={(e) => this.handleChange("amount", e.target.value)}
                            InputLabelProps={{ shrink: true }}
                        />
                    </div>
                    <div className={"customer-delete-row-step-one"} style={{padding:"15px", marginLeft: "1px"}}>
                        <Tooltip title={APPLY_EDIT_ENTRY}>
                            <IconButton onClick={() => this.applyNewPrices()}>
                                <CheckCircleIcon
                                    fontSize="small"
                                    color='secondary'
                                />
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
                <Divider className='section-divider' />
                <div className='customer-stepper-page-two-grid' key={'this'}>
                    {this.state.products?.map((product, index) => {
                        return (
                            <React.Fragment>
                                <div></div>
                                <div key={'shortcode autocomplete' + index}>
                                    <Autocomplete
                                        options={this.state.allItems?.sort((a, b) => { return a.shortcode.toLowerCase().localeCompare(b.shortcode.toLowerCase()) })}
                                        getOptionLabel={(product) => product.shortcode}
                                        autoHighlight={true}
                                        name="shortcode"
                                        className='customer-input-text-field'
                                        value={product}
                                        onChange={(event, value) => {
                                            this.handleProductShortcodeChange(value, index)
                                        }
                                        }
                                        blurOnSelect={true}
                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                error={product.shortcode === ''}
                                                helperText={this.state.shortcodeError[index] ? "Duplicated shortcode" : null}
                                                label="Shortcode"
                                                InputLabelProps={{ shrink: true }}
                                                variant="outlined"
                                                name="shortcode"
                                                required={product}
                                            />
                                        }
                                    />

                                </div>
                                <div key={'price field ' + index} style={{display: "flex"}}>
                                    <TextField
                                        className='customer-input-text-field'
                                        variant='outlined'
                                        label="Price"
                                        InputLabelProps={{ shrink: true }}
                                        name='price'
                                        value={product.price}
                                        style={{ width: "125px", margin: "10px" }}
                                        // disabled={!("price" in product)}
                                        inputProps={{ style: { textAlign: "right" } }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <AttachMoneyIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                        onChange={(e) => this.handleProductPriceChange(e, index)}
                                        onBlur={(e) => this.formatPrice(e.target.value, index)}
                                        error={product.price === ''}
                                        required
                                    />
                                    <TextField
                                        className='customer-input-text-field'
                                        variant='outlined'
                                        label="New Price"
                                        error={!product.price}
                                        InputLabelProps={{ shrink: true }}
                                        name='price'
                                        value={product.newPrice || product.price}
                                        disabled={true}
                                        style={{ width: "125px" }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <AttachMoneyIcon />
                                                </InputAdornment>
                                            ),
                                            inputProps: { style: { textAlign: 'right' } }
                                        }}
                                        onChange={(e) => this.handleProductPriceChange(e, index)}
                                    />
                                </div>
                                <div key={'customerpartnumber field' + index}>
                                    <TextField
                                        className='customer-input-text-field'
                                        variant='outlined'
                                        label="Customer Part Number"
                                        InputLabelProps={{ shrink: true }}
                                        name='customerPartNumber'
                                        value={product.customerPartNumber}
                                        onChange={(e) => this.handleCustomerPartNumberChange(e, index)}
                                        error={product.customerPartNumber === ''}
                                        required
                                    />
                                </div>
                                <div className={!this.state.isDelete[index] ? "customer-delete-row-step-one" : ''}>
                                    <DeleteStepper
                                        handleDeleteFalse={this.handleDeleteFalse}
                                        handleDeleteTrue={this.handleDeleteTrue}
                                        handleDelete={() => this.removeProductEntry(index)}
                                        index={index}
                                        isDelete={this.state.isDelete[index]}
                                    />
                                </div>
                            </React.Fragment>
                        )
                    })}

                    {/*default adding row*/}
                    <div></div>
                    <div ></div>
                    <div style={{ width: "100%", display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                        <IconButton onClick={this.addProductEntry}>
                            <AddCircleIcon
                                fontSize="small"
                                color='secondary'
                            />
                        </IconButton>
                        Add customer products
                    </div>
                    <div></div>
                    <div />
                    <div />

                </div>
            </>
        )
    }



    handleDeleteTrue = (index) => {
        let newIsDelete = this.state.isDelete
        newIsDelete[index] = true
        this.setState({ isDelete: newIsDelete })
    }

    handleDeleteFalse = (index) => {
        let newIsDelete = this.state.isDelete
        newIsDelete[index] = false
        this.setState({ isDelete: newIsDelete })
    }

    handleProductShortcodeChange = (value, index) => {
        const { products } = this.state

        let shortcodes = products.map(product => product.shortcode)
        if (shortcodes.includes(value?.shortcode)) {
            let newShortcodeError = this.state.shortcodeError
            newShortcodeError[index] = true
            this.setState({
                shortcodeError: newShortcodeError
            })
        } else if (this.state.shortcodeError[index]) {
            let newShortcodeError = this.state.shortcodeError
            newShortcodeError[index] = false
            this.setState({
                shortcodeError: newShortcodeError
            })
        }

        products[index].shortcode = value && value.shortcode ? value.shortcode : ""
        products[index].customerPartNumber = ""
        products[index].price = ""

        this.setState({
            products: products
        })
    }

    handleProductPriceChange = (e, index) => {
        const { products, amountOrPercentage, increaseOrDecrease, amount } = this.state

        products[index].price = e.target.value
        let price = parseFloat(e.target.value)
        // if the user is attempting to update prices at once
        if (!isNaN(price) && amountOrPercentage && increaseOrDecrease && amount) {
            let newPrice = e.target.value;
            if (amountOrPercentage === AMOUNT) {
                if (increaseOrDecrease === INCREMENT) {
                    newPrice = price + parseFloat(amount);
                } else if (increaseOrDecrease === DECREMENT) {
                    newPrice = price - parseFloat(amount);
                }
            } else if (amountOrPercentage === PERCENTAGE) {
                if (increaseOrDecrease === INCREMENT) {
                    newPrice = price * (100 + parseFloat(amount)) / 100
                } else if (increaseOrDecrease === DECREMENT) {
                    newPrice = price * (100 - parseFloat(amount)) / 100
                }
            }
            newPrice = Number(newPrice).toFixed(2).toString()
            products[index].newPrice = newPrice
        }
        this.setState({
            products: products
        })
    }

    formatPrice = (price, index) => {
        const { products } = this.state
        if (Number(price) || Number(price) === 0) {
            products[index].price = Number(price).toFixed(2).toString();
            this.setState({
                products: products
            })
        }
    }

    handleCustomerPartNumberChange = (e, index) => {
        const { products } = this.state

        products[index].customerPartNumber = e.target.value

        this.setState({
            products: products
        })
    }

    checkNext = () => {
        // Check if any template exists and if templateName or email is missing in any template
        const isTemplateInvalid = this.state.templates.some(template =>
            (template.templateName !== '' || template.email !== '') &&
            (template.templateName === '' || template.email === '')
        );

        return (this.state.name === '' || this.state.phone === '' || this.state.addressLine1 === '' || this.state.city === '' ||
            this.state.province === '' || this.state.postalCode === '' || this.state.country === '' || this.state.email === '' ||
            this.state.customerShortcode === '' || this.state.currencyCode === '' ||
            this.state.activeStep === 2 ||
            (this.state.activeStep === 1 && this.checkDuplicateShortcode()) ||
            isTemplateInvalid // Add template validation check here
        );
    }


    // checkNext = () => {
    //     return (this.state.name === '' || this.state.phone === '' || this.state.addressLine1 === '' || this.state.city === '' ||
    //         this.state.province === '' || this.state.postalCode === '' || this.state.country === '' || this.state.email === '' ||
    //         this.state.customerShortcode === '' || this.state.currencyCode === '' ||
    //         this.state.activeStep === 2  ||
    //         (this.state.activeStep === 1 && this.checkDuplicateShortcode()))
    // }


    handleNext = () => {
        if (this.state.activeStep === STEPPER_HEADINGS.length - 1) {
            // todo error checking
            this.props.handleSave(this.props.addingCustomer, this.state)
        }
        else {
            this.setState({
                activeStep: this.state.activeStep + 1,
            }
            );
        }
    }

    handleAdjustPriceDialogClick () {
        let productsCopy = [...this.state.products]
        productsCopy.map((product)=> delete product["newPrice"])
        this.setState({
            amountOrPercentage: null,
            amount: "",
            increaseOrDecrease: "",
        })
    }


    render() {

        let headerButtons
        if (this.props.addingCustomer) {
            headerButtons = [{
                icon: <ArrowBack />,
                handler: this.props.handleClose,
                title: this.props.backTitle
            },
                {
                    icon: <ArrowForward />,
                    handler: () => this.handleNext(STEPPER_HEADINGS.length),
                    title: NEXT
                }
            ]
            if (this.state.activeStep === STEPPER_HEADINGS.length - 1 || this.checkNext()) {
                headerButtons[1].disabled = true
            }
        }
        else {
            headerButtons = [{
                icon: <ArrowBack />,
                handler: this.handleBack,
                title: this.state.activeStep !== 0 ? BACK : this.props.backTitle
            },
            {
                icon: <SaveIcon />,
                handler: () => this.props.handleSave(this.props.addingCustomer, this.state),
                title: 'Save'
            },
                {
                    icon: <ArrowForward />,
                    handler: () => this.handleNext(STEPPER_HEADINGS.length),
                    title: NEXT
                }
            ]
            if (this.checkNext()) {
                headerButtons[1].disabled = true
            }
            if (this.state.activeStep === STEPPER_HEADINGS.length - 1 || this.checkNext()) {
                headerButtons[2].disabled = true
            }
        }
        return (
            <>
                <CustomToolbar
                    creatingOrder={this.props.creatingOrder}
                    title={this.props.title}
                    createTitle={this.props.createTitle}
                    backTitle={this.props.backTitle}
                    handleClose={this.handleBack}
                    headerButtons={headerButtons}
                />
                <Stepper activeStep={this.state.activeStep} alternativeLabel>
                    {STEPPER_HEADINGS.map((label, index) => (
                        <Step className='step' key={label}>
                            <StepButton
                                onClick={() => {
                                    this.setStep(index);
                                }}
                                optional={
                                    <Typography variant='caption'>
                                        {STEPPER_HEADING_DESCRIPTIONS[index]}
                                    </Typography>
                                }
                            >
                                {label}
                            </StepButton>
                        </Step>
                    ))}
                </Stepper>

                <div className='customer-stepper-align'>

                    {this.getStepContent(this.state.activeStep)}
                    <div>
                        <Button
                            className={'customer-save-invoice-query-button'}
                            variant='contained'
                            onClick={this.handleBack}
                        >
                            {this.state.activeStep === 0 ? CANCEL : BACK}
                        </Button>

                        <Button
                            className={'customer-save-invoice-query-button'}
                            variant='contained'
                            color='primary'
                            onClick={() => this.handleNext(STEPPER_HEADINGS.length)}
                            disabled={this.checkNext()}
                        >
                            {this.state.activeStep === STEPPER_HEADINGS.length - 1 ? SAVE : NEXT}
                        </Button>
                    </div>

                </div>
            </>
        );
    }
}


// todo prop types

ManageCustomerStepper.propTypes = {
    addingCustomer: PropTypes.bool,
    products: PropTypes.array,
    packages: PropTypes.array
}

ManageCustomerStepper.defaultProps = {
    addingCustomer: true,
    products: [],
    packages: []

}
export default ManageCustomerStepper