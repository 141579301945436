import { createSelector } from 'reselect';

export const shippingSettingSelector = createSelector(state => state.accounting.rates, shippingSettingSelector => shippingSettingSelector)

export const shippingSummarySelector = createSelector(state => state.accounting.shippingSummary, shippingSummarySelector => shippingSummarySelector)
export const chequeSelector = createSelector(state => state.accounting.cheques, chequeSelector => chequeSelector)
export const transactionSelector = createSelector(state => state.accounting.transactions, transactionSelector => transactionSelector)
export const processingSelector = createSelector(state => state.accounting.processing, processingSelector => processingSelector)
export const attachmentSelector = createSelector(state => state.accounting.attachmentFile, attachmentSelector => attachmentSelector)
export const fetchedReferencesSelector = createSelector(state => state.accounting.fetchedReferences, fetchedReferencesSelector => fetchedReferencesSelector)
export const transactionCategoriesSelector = createSelector(state => state.accounting.transactionCategories, transactionCategoriesSelector => transactionCategoriesSelector);
export const foundInvoiceSelector = createSelector(state => state.accounting.invoice, foundInvoiceSelector => foundInvoiceSelector);
export const taxInfosSelector = createSelector(state => state.accounting.taxInfos, taxInfosSelector => taxInfosSelector);
export const taxTypewithStateProvinceSelector = createSelector(state => state.accounting.taxTypewithStateProvince, taxTypewithStateProvinceSelector => taxTypewithStateProvinceSelector);
export const editingTransactionSelector = createSelector(state => state.accounting.editingTransaction, editingTransactionSelector => editingTransactionSelector)
export const statementTaxesTemplateSelector = createSelector(state => state.accounting.templates, statementTaxesTemplateSelector => statementTaxesTemplateSelector)
export const receivedListSelector = createSelector(state => state.accounting.receivedList, receivedListSelector => receivedListSelector)
export const paidListSelector = createSelector(state => state.accounting.paidList, paidListSelector => paidListSelector)
export const ledgerSelector = createSelector(state => state.accounting.ledgers, ledgerSelector => ledgerSelector)
export const transactionLedgerSelector = createSelector(state => state.accounting.fetchTransactions, transactionLedgerSelector => transactionLedgerSelector)
export const loadingSelector = createSelector(state => state.accounting.loadingData, loadingSelector => loadingSelector)
export const purchaseSummarySelector = createSelector(state => state.accounting.purchaseSummary, purchaseSummarySelector => purchaseSummarySelector)
export const ftlSummarySelector = createSelector(state => state.accounting.ftlSummary, ftlSummarySelector => ftlSummarySelector)
export const noteSelector = createSelector(state => state.accounting.note, noteSelector => noteSelector)
export const trialBalanceSelector = createSelector(state => state.accounting.trialBalance, trialBalanceSelector => trialBalanceSelector)
export const marketplaceSummarySelector = createSelector(state => state.accounting.marketplaceSummary, marketplaceSummarySelector => marketplaceSummarySelector)
export const marketplaceSummaryProgressSelector = createSelector(state => state.accounting.progress, marketplaceSummaryProgressSelector => marketplaceSummaryProgressSelector)
export const marketplaceSummarySessionIdSelector = createSelector(state => state.accounting.sessionId, marketplaceSummarySessionIdSelector => marketplaceSummarySessionIdSelector)
export const lastUpdatedDateSummarySelector = createSelector(state => state.accounting.lastUpdatedAt, lastUpdatedDateSummarySelector => lastUpdatedDateSummarySelector)
