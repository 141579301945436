export const MARKETPLACE_SUMMARY = "Marketplace Summary"
export const IMPORT_MARKETPLACE_SUMMARY = "Import Marketplace Summary"


export const MARKETPLACE_SUMMARY_FIELDS = [
    {id: 'name', label: 'Description', align: "left"},
    {id: 'amazon_ca', label: 'Amazon.ca', align: "center"},
    {id: 'amazon_com', label: 'Amazon.com', align: "center"},
    {id: 'walmart_ca', label: 'Walmart.ca', align: "center"},
    {id: 'walmart_com', label: 'Walmart.com', align: "center"},
    {id: 'ebay_ca', label: 'Ebay.ca', align: "center"},
    {id: 'exponet_ca', label: 'Exponet.ca', align: "center"},
    {id: 'exponet_us', label: 'Exponet.us', align: "center"},
    {id: 'customer_ca', label: 'Customers', align: "center"}
]

export const MARKETPLACE_SUMMARY_PAGES = {
    TABLE: "table",
}

// tooltip names
export const BACK = "Back to Menu"
