import React from "react";

// material components
import {
    Select,
    MenuItem,
    Box,
    TextField,
    Tooltip,
    IconButton,
} from "@material-ui/core";

// material icons
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
// import constants
import {BETWEEN, FILTER_TYPE_NUMERIC_OPTIONS, FILTERS, IS_EMPTY} from "./constants";
import {COPY_FILTER, DELETE_FILTER, DRAG} from "../constants";

// import styling
import "./FilterNumericWithPredefined.css";
import PropTypes from "prop-types";
import FilterBooleanSelect from "./FilterBooleanSelect";
import DehazeIcon from "@material-ui/icons/Dehaze";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import InputAdornment from "@material-ui/core/InputAdornment";
import {listCarriers, listSuppliers} from "../../../redux/actions/settings";
import {carriersSelector, suppliersSelector} from "../../../redux/selectors/settings";
import withShipment from "../../../withShipment";
import {userInfoSelector} from "../../../redux/selectors/auth";
import {fetchFullTruckloadCarriers} from "../../../redux/actions/fullTruckloadCarriers";
import {fullTruckloadCarriersSelector} from "../../../redux/selectors/fullTruckloadCarrier";

/**
 * Numeric Textfield with predefined dropdown and an inputTextField.
 */
class FilterNumericWithPredefined extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            filterId: "",
            name: "",
            value1: "", // value for the first drop down
            value2: "", // value of the numeric textfield
            value3: "", //value of the second numeric textfield
            preDefinedValue: "-",
            preDefinedValue2: "-",
            preDefinedValue3: "-",
            disableManualValueField: false,
            preDefinedValues: this.getPredefinedValues(),
            preDefinedValues2: this.getPredefinedValues2(),
            preDefinedValues3: this.getPredefinedValues3(),
        };
    }

    componentDidMount = () => {
        this.setState({
            // on component mount, the parent component stores this child's state.
            // We restore it here
            ...this.props.filter,
        },
            ()=>{
                if(this.props.isEdit) {
                    this.setState({
                        disableManualValueField: this.state.preDefinedValue !== "null" && this.state.preDefinedValue !== "-" && this.state.preDefinedValue !== ""
                    }, () => {
                        this.props.updateFilterWithParent({...this.state});
                    })
                }
            });
        this.props.listSuppliers()
        this.props.fetchFullTruckloadCarriers()
        this.props.listCarriers({ id: this.props.userInfo.company })
    };

    getPredefinedValues = () => {
        let preDefinedValues = FILTERS.find(filter => filter.name === this.props.filter.name)?.predefinedValues
        if (!preDefinedValues) {
            preDefinedValues = []
        }
        return preDefinedValues
    }
    getPredefinedValues2 = () => {
        let preDefinedValues2 = FILTERS.find(filter => filter.name === this.props.filter.name)?.predefinedValues2
        if (!preDefinedValues2) {
            preDefinedValues2 = []
        }
        return preDefinedValues2
    }
    getPredefinedValues3 = () => {
        let predefinedValues3 = FILTERS.find(filter => filter.name === this.props.filter.name)?.predefinedValues3
        if (!predefinedValues3) {
            predefinedValues3 = []
        }
        return predefinedValues3
    }

    handlePreDefinedValueChange = (e) => {
        this.setState({
            preDefinedValue: e.target.value,
            disableManualValueField: e.target.value !== '-',
            value2: e.target.value !== '-'? '': this.state.value2,
            value3: e.target.value !== '-'? '': this.state.value3,
            preDefinedValue2: '',
            preDefinedValue3: ''
        }, () => {
            this.props.updateFilterWithParent({ ...this.state })})
    }
    handlePreDefinedValueChange2 = (e) => {
        this.setState({
            preDefinedValue2: e.target.value,
            preDefinedValue3: "",
        }, () => {
            this.props.updateFilterWithParent({ ...this.state })})
    }
    handlePreDefinedValueChange3 = (e) => {
        this.setState({
            preDefinedValue3: e.target.value,
        }, () => {
            this.props.updateFilterWithParent({ ...this.state })})
    }

    handleValue1Change = (event) => {
        const value1 = event.target.value;
        const stateUpdate = {
            value1,
        };

        if (event.target.value === IS_EMPTY) {
            stateUpdate.preDefinedValue = '-';
            stateUpdate.disableManualValueField = stateUpdate.preDefinedValue != '-';
            stateUpdate.value2 = '';
            stateUpdate.value3 = '';
            stateUpdate.preDefinedValue2 = '-';
            stateUpdate.preDefinedValue3 = '-';
        }
        if (event.target.value === BETWEEN) {
            stateUpdate.preDefinedValue = '-';
            stateUpdate.preDefinedValue2 = '-';
            stateUpdate.preDefinedValue3 = '-';
            stateUpdate.disableManualValueField = stateUpdate.preDefinedValue != '-';
        }

        this.setState(stateUpdate, () => {
            this.props.updateFilterWithParent({ ...this.state });
        });
    };

    handleValue2Change = (event) => {
        const value2 = event.target.value;
        this.setState(
            {
                value2,
            },
            () => {
                this.props.updateFilterWithParent({ ...this.state });
            }
        );
    };

    handleValue3Change = (event) => {
        const value3 = event.target.value;
        this.setState(
            {
                value3,
            },
            () => {
                this.props.updateFilterWithParent({ ...this.state });
            }
        );
    };

    handleValue2ChangeProfit = (event) => {
        if ((this.state.name === "Profit Threshold" || this.state.name === "Amount") && !event.target.value.includes(".") && !event.target.value.includes("-") && isNaN(event.target.value) || event.target.value === "") {
            this.setState({
                    value2: "0.00"
                },
                () => {
                    this.props.updateFilterWithParent({ ...this.state });
                })
            return;
        }
        let value2;
        try {
            value2 = (this.state.name !== "Profit Threshold" && this.state.name !== "Amount") ? event.target.value : event.target.value.charAt(0) !== "-" ? parseFloat(event.target.value).toFixed(2) : "-" + parseFloat(event.target.value.slice(1)).toFixed(2);
        }
        catch {
            this.setState({
                    value2: "0.00"
                },
                () => {
                    this.props.updateFilterWithParent({ ...this.state });
                })
            return;
        }
        this.setState(
            {
                value2,
            },
            () => {
                this.props.updateFilterWithParent({ ...this.state });
            }
        );
    };

    isPredefinedValueExist = () => {
        return this.state.preDefinedValue !== "-" && this.state.preDefinedValue !== '' && this.state.preDefinedValue !== null && this.state.preDefinedValue !== undefined;
    }

    handleValue3ChangeProfit = (event) =>{
        if ((this.state.name === "Profit Threshold" || this.state.name === "Amount") && !event.target.value.includes(".") && !event.target.value.includes("-") && isNaN(event.target.value) || event.target.value === "") {
            this.setState({
                    value3: "0.00"
                },
                () => {
                    this.props.updateFilterWithParent({ ...this.state });
                })
            return;
        }
        let value3;
        try {
            value3 = (this.state.name !== "Profit Threshold" && this.state.name !== "Amount") ? event.target.value : event.target.value.charAt(0) !== "-" ? parseFloat(event.target.value).toFixed(2) : "-" + parseFloat(event.target.value.slice(1)).toFixed(2);
        }
        catch {
            this.setState({
                    value3: "0.00"
                },
                () => {
                    this.props.updateFilterWithParent({ ...this.state });
                })
            return;
        }
        this.setState(
            {
                value3,
            },
            () => {
                this.props.updateFilterWithParent({ ...this.state });
            }
        );
    }

    renderCarrierMenuItems() {
        return (this.props.carriers ?? []).sort((a, b) => a.name.localeCompare(b.name)).map((carrier, index) => (
            <MenuItem value={carrier.carrierId} key={carrier.carrierId}>
                {`${carrier.name}, ${carrier.accountNumber} (${carrier.alias})`}
            </MenuItem>
        ))
    }

    renderFTLCarrierMenuItems() {
        return (this.props.ftlCarriers ?? []).sort((a, b) => a.name.localeCompare(b.name)).map((carrier, index) => (
            <MenuItem value={carrier.carrierId} key={carrier.carrierId}>
                {carrier.name}
            </MenuItem>
        ))
    }

    renderSupplierMenuItems() {
        return this.props.suppliers?.map((supplier, index) => (
            <MenuItem value={supplier.name} key={supplier.name}>
                {supplier.name}
            </MenuItem>
        ))
    }

    renderChequeMenuItems() {
        return  (
            <MenuItem value={"Transaction reference Id"} key={"Transaction reference Id"}>
                {"Transaction reference Id"}
            </MenuItem>
        )
    }

    render() {
        return (
            <Box
                className='filterNumericField'
                display='flex'
                flexDirection='column'
                justifyContent='left'
                alignItems='center'
            >
                <Box
                    display='flex'
                    flexDirection='row'
                    textAlign='left'
                    alignItems='center'
                    width='100%'
                >
                    <Box className='filterNumericFieldName'>{this.state.name}</Box>

                    <Select
                        className='filterBooleanPredefinedSelectOptions'
                        variant='outlined'
                        label={this.state.name}
                        value={this.state.value1}
                        onChange={this.handleValue1Change}
                    >
                        {FILTER_TYPE_NUMERIC_OPTIONS.map((item) => (
                            <MenuItem value={item}>{item}</MenuItem>
                        ))}
                    </Select>
                    <div>
                        {this.state.value1 === IS_EMPTY ? <></> :
                            this.state.value1 === BETWEEN ?
                                <div style={{ display: 'flex' }}>
                                    <TextField
                                        className='filterNumericFieldInputPredefined'
                                        variant='outlined'
                                        label={this.state.name}
                                        value={this.state.value2}
                                        disabled={this.state.disableManualValueField}
                                        onChange={this.handleValue2Change}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        InputProps={{startAdornment: (<InputAdornment position="start">$</InputAdornment>)}}
                                        onBlur={this.handleValue2ChangeProfit}
                                        type="number"
                                    />
                                    <TextField
                                        className='filterNumericFieldInputPredefined'
                                        variant='outlined'
                                        label={this.state.name}
                                        value={this.state.value3}
                                        disabled={this.state.disableManualValueField}
                                        onChange={this.handleValue3Change}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        InputProps={{startAdornment: (<InputAdornment position="start">$</InputAdornment>)}}
                                        onBlur={this.handleValue3ChangeProfit}
                                        type="number"
                                    />
                                </div>:
                                this.state.name === "Profit Threshold" /*|| this.state.name === "Amount"*/?
                                    <TextField
                                        className='filterNumericFieldInputPredefined'
                                        variant='outlined'
                                        label={this.state.name}
                                        value={this.state.value2}
                                        disabled={this.state.disableManualValueField}
                                        onChange={this.handleValue2Change}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        InputProps={{startAdornment: (<InputAdornment position="start">$</InputAdornment>)}}
                                        onBlur={this.handleValue2ChangeProfit}
                                    />
                                    :
                                    this.state.name === "Amount"?
                                    <TextField
                                        className='filterNumericFieldInput'
                                        variant='outlined'
                                        type='number'
                                        label={this.state.name}
                                        value={this.state.value2}
                                        onChange={this.handleValue2Change}
                                        disabled={this.state.disableManualValueField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        InputProps={{startAdornment: (<InputAdornment position="start">$</InputAdornment>)}}
                                        onBlur={this.handleValue2ChangeProfit}
                                    />:
                                    <TextField
                                        className='filterNumericFieldInputPredefined'
                                        variant='outlined'
                                        type='number'
                                        label={this.state.name}
                                        value={this.state.value2}
                                        onChange={this.handleValue2Change}
                                        disabled={this.state.disableManualValueField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                        }
                    </div>

                    <div className='filterBooleanEmptyDiv'>
                        <Tooltip title={COPY_FILTER}>
                            <IconButton
                                aria-label={COPY_FILTER}
                                onClick={() => this.props.copyFilter(this.state.filterId)}>
                                <FileCopyIcon fontSize='small'/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={DELETE_FILTER}>
                            <IconButton
                                aria-label={DELETE_FILTER}
                                onClick={() => this.props.deleteFilter(this.state.filterId)}
                            >
                                <DeleteOutlineIcon variant='outlined' fontSize='small' />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={DRAG}>
                            <IconButton
                                aria-label='drag'
                            >
                                <DehazeIcon variant='outlined' fontSize='small' />
                            </IconButton>
                        </Tooltip>
                    </div>
                </Box>
                <Box
                    display='flex'
                    flexDirection='row'
                    textAlign='left'
                    alignItems='center'
                    width='100%'
                >
                    <Box className='filterNumericFieldName'></Box>

                    <div className='filterBooleanPredefinedSelectOptionsAmount'></div>
                    <div style={{marginLeft: '10px', marginRight: '42.5px'}}>
                        OR
                    </div>

                            {!this.state.preDefinedValues || this.state.preDefinedValues.length <= 0 || this.state.value1 === BETWEEN || this.state.value1 === IS_EMPTY  ? <></> :

                                <Select
                                        variant="outlined"
                                        name="preDefinedValue"
                                        onChange={this.handlePreDefinedValueChange}
                                        value={this.state.preDefinedValue === "null" ? '-' : this.state.preDefinedValue}
                                        className='filterBooleanPredefinedSelectOptions2'
                                    >
                                        {this.state.preDefinedValues.map((value, index) => (
                                            <MenuItem value={value} key={index}>{value === '-'? 'Select a predefined value': value}</MenuItem>
                                        ))
                                        }
                                    </Select>

                            }
                </Box>
                {
                    this.isPredefinedValueExist()?
                        <Box
                            display='flex'
                            flexDirection='row'
                            textAlign='left'
                            alignItems='center'
                            width='100%'
                        >
                            <Box className='filterNumericFieldName'>{"Where"}</Box>
                            <Select
                                variant="outlined"
                                name="preDefinedValue2"
                                onChange={this.handlePreDefinedValueChange2}
                                value={this.state.preDefinedValue2 === "null" ? '-' : this.state.preDefinedValue2}
                                className='filterBooleanPredefinedSelectOptions'
                            >
                                {this.state.preDefinedValues2.map((value, index) => (
                                    <MenuItem value={value} key={index}>{value === '-'? 'Select a predefined value': value}</MenuItem>
                                ))
                                }
                            </Select>
                            <div style={{marginLeft: '10px'}}>
                                equals to
                            </div>
                            <Select
                                variant="outlined"
                                name="preDefinedValue3"
                                onChange={this.handlePreDefinedValueChange3}
                                value={this.state.preDefinedValue3 === "null" ? '-' : this.state.preDefinedValue3}
                                className='filterBooleanPredefinedSelectOptions2'
                            >
                                {this.state.preDefinedValue2 === 'Standard Carrier' ? this.renderCarrierMenuItems() :
                                    this.state.preDefinedValue2 === 'LTL/FTL Carrier'? this.renderFTLCarrierMenuItems() :
                                        this.state.preDefinedValue2 === 'Supplier' ? this.renderSupplierMenuItems():
                                            this.state.preDefinedValue2 === 'Cheque Number' ? this.renderChequeMenuItems(): []
                                }
                            </Select>
                        </Box>
                    :<></>

                }
            </Box>
        );
    }
}

const mapStateToProps = (state) => ({
    userInfo: userInfoSelector(state),
    carriers: carriersSelector(state),
    suppliers: suppliersSelector(state),
    ftlCarriers: fullTruckloadCarriersSelector(state)
})

const actionCreators = {
    listCarriers,
    listSuppliers,
    fetchFullTruckloadCarriers
}

FilterNumericWithPredefined.propTypes = {
    userInfo: PropTypes.object,
    carriers: PropTypes.array,
    filter: PropTypes.object,
    updateFilterWithParent: PropTypes.func,
    deleteFilter: PropTypes.func,
    isEdit: PropTypes.bool,
    copyFilter: PropTypes.func,
    ftlCarriers: PropTypes.array,
}

FilterNumericWithPredefined.defaultProps = {
    userInfo: {},
    carriers: [],
    filter: {},
    updateFilterWithParent: ()=>{},
    deleteFilter: ()=>{},
    isEdit: false,
    copyFilter: ()=>{},
    suppliers:[],
    ftlCarriers: []
}
export default withShipment({
    mapStateToProps,
    actionCreators
}, FilterNumericWithPredefined);
